import React, { useEffect, useState } from "react";
import Typography from "@mui/material/Typography";
import ConsultCardIcon from "../../reUse/ConsultCardIcon";

import SafetyLargeIcon from "../../reUse/SafetyLargeIcon";
import { Button } from "@carbon/react";
import { useNavigate } from "react-router-dom";
import Lottie from "react-lottie";
import animationData from "./rep.json";
export default function AsessmentCardDash({
  modalHeader,
  modalparagraph,
  modalConfirm,
  modalAction,
  assTaken,
  Plan,
  setassDisable,
  setSelectedLanguage,selectedLanguage,
 setShowLanguageOptions,showLanguageOptions
}) {
  const navigate = useNavigate();
  const invited = localStorage.getItem("invited");
  const pid = Plan;
  const dyta = localStorage.getItem("assesData");
  const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData: animationData,
    rendererSettings: {
      preserveAspectRatio: "xMidYMid slice",
    },
  };
  // 
  return (
    <div className="cardQuarterDashboard">
      <div
        className={"consultCard " + (assTaken > 0 ? "" : "highlightCard")}
        style={{ opacity: "1" }}
      >
        <div className="headerPlan">
          <svg
            width="30"
            height="30"
            viewBox="0 0 30 30"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <g clip-path="url(#clip0_1822_2)">
              <path
                d="M10 6.25H7.5C6.83696 6.25 6.20107 6.51339 5.73223 6.98223C5.26339 7.45107 5 8.08696 5 8.75V23.75C5 24.413 5.26339 25.0489 5.73223 25.5178C6.20107 25.9866 6.83696 26.25 7.5 26.25H14.6212"
                stroke="#2461FF"
                stroke-width="1.5"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
              <path
                d="M22.5 17.5V22.5H27.5"
                stroke="#2461FF"
                stroke-width="1.5"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
              <path
                d="M22.5 13.75V8.75C22.5 8.08696 22.2366 7.45107 21.7678 6.98223C21.2989 6.51339 20.663 6.25 20 6.25H17.5"
                stroke="#2461FF"
                stroke-width="1.5"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
              <path
                d="M10 6.25C10 5.58696 10.2634 4.95107 10.7322 4.48223C11.2011 4.01339 11.837 3.75 12.5 3.75H15C15.663 3.75 16.2989 4.01339 16.7678 4.48223C17.2366 4.95107 17.5 5.58696 17.5 6.25C17.5 6.91304 17.2366 7.54893 16.7678 8.01777C16.2989 8.48661 15.663 8.75 15 8.75H12.5C11.837 8.75 11.2011 8.48661 10.7322 8.01777C10.2634 7.54893 10 6.91304 10 6.25Z"
                stroke="#2461FF"
                stroke-width="1.5"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
              <path
                d="M17.5 22.5C17.5 23.8261 18.0268 25.0979 18.9645 26.0355C19.9021 26.9732 21.1739 27.5 22.5 27.5C23.8261 27.5 25.0979 26.9732 26.0355 26.0355C26.9732 25.0979 27.5 23.8261 27.5 22.5C27.5 21.1739 26.9732 19.9021 26.0355 18.9645C25.0979 18.0268 23.8261 17.5 22.5 17.5C21.1739 17.5 19.9021 18.0268 18.9645 18.9645C18.0268 19.9021 17.5 21.1739 17.5 22.5Z"
                stroke="#2461FF"
                stroke-width="1.5"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
              <path
                d="M10 13.75H15"
                stroke="#2461FF"
                stroke-width="1.5"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
              <path
                d="M10 18.75H13.75"
                stroke="#2461FF"
                stroke-width="1.5"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
            </g>
            <defs>
              <clipPath id="clip0_1822_2">
                <rect width="30" height="30" fill="white" />
              </clipPath>
            </defs>
          </svg>

          <Typography variant="small" color="neutral.b800">
            Assessment
          </Typography>
        </div>
        {pid === null ? (
          <div className="contentCard">
            <SafetyLargeIcon />
            <div>
              <Typography variant="small" color="neutral.b200">
                Please select a plan to take the assessment
              </Typography>
            </div>
          </div>
        ) : dyta !== null ? (
          <div className="contentCard2">
            <p>
              To resume your previous session, click 'Continue Assessment.' If
              you wish to start a new assessment, click 'Start New Assessment.'
            </p>
            <Lottie options={defaultOptions} height={320} width={320} />
          </div>
        ) : (
          <div className="contentCard2">
            <p>
              {setassDisable ? (
                <Typography
                  variant="small"
                  color="neutral.b800"
                  className="cfd"
                >
                  It seems you have used up all your assessments for your
                  current plan. To avail more assessments, please visit the
                  plans & billing page to continue or contact your
                  administrator.Thank you for using the trial plan. If you need more assessments, reports, etc… please visit <a style={{cursor:'pointer',color:'#0065ff'}} onClick={()=>navigate("/portal/plans")}>Plans & Billing</a>
                </Typography>
              ) : (
                <Typography
                  variant="small"
                  color="neutral.b800"
                  className="cfd"
                >
                  A Few Key Points: <br />
                  <br />
                  <li>
                    You're about to embark on a journey, a journey where we’ll
                    reignite your passion for your business. We will take you
                    back to the time when you first began contemplating your
                    business—the WHY and the PURPOSE behind it. Our
                    comprehensive assessment tool will guide you through key
                    questions concerning your entire business process.
                  </li>
                  <br />
                  <li>
                    Honesty is 'THE KEY' here. Answer the questions based on the
                    current state of your business, not how you wish it to be.
                    The accuracy of our report relies on your truthful responses
                    to these questions.
                  </li>
                  <br />
                  <li>
                    Yes, there are 142 questions, which will require time and
                    effort. If your business is important to you, investing this time
                    in thoughtful planning is essential. This assessment serves
                    as your roadmap to success, built on your candid responses.
                    If a question does not apply to you, please select ‘No.’
                  </li>
                  <br />
                  <li>
                    Don’t worry if you need to take a break. Just make sure you
                    finish the current section you are in and click the “Next”
                    button to save your answers. You need to answer all
                    questions in that section to save your work.
                  </li>
                  <br />
                  <li>
                    Prepare to explore uncharted territory. You might come
                    across questions you never thought about, and that's
                    perfectly fine. So, take a deep breath, get your favourite
                    beverage, consult your team or phone a friend, and let's get
                    started.
                  </li>
                  <br />
                  We’re rooting for you! -Panorama Team
                </Typography>
              )}
            </p>
          </div>
        )}
        {pid == null ? (
          <Button
            onClick={() => navigate("/portal/plans")}
            className="btnss"
            style={{ maxWidth: "100%", width: "100%" }}
            kind="primary"
            size="lg"
          >
            View Available Plans
          </Button>
        ) : dyta !== null ? (
          <div className="fghz">
            <Button
              onClick={() => {
                modalHeader("New Assessment");
                modalparagraph(
                  "Are you sure you want to start a new assessment? This will delete your progress."
                );
                modalAction("new");
                modalConfirm(true);
                window.scrollTo(0)
              }}
              disabled={setassDisable}
              className="btnss fg"
              // style={{ maxWidth: "48%", width: "48%",marginRight:'24px' }}
              kind="primary"
              size="lg"
            >
              Start New Assessment
            </Button>
            <Button
              onClick={() => {
                modalHeader("Edit Assessment");
                modalparagraph(
                  "Welcome back! Continue from where you left off."
                );
                modalAction("edit");
                modalConfirm(true);
                window.scrollTo(0)
              }}
              className="btnss "
              // style={{ maxWidth: "48%", width: "48%" }}
              kind="primary"
              size="lg"
              disabled={setassDisable}
            >
              Continue Assessment
            </Button>
          </div>
        ) : assTaken > 0 ? (
          <Button
          onClick={() => {
            modalHeader("Ready to take an assessment?");
            modalparagraph(
              <>
              <p>
                Are you sure you want to start a new assessment? We recommend taking
                assessments each quarter, and this action will use one assessment from
                your plan.
              </p>
              <br />

    
                <div>
                  <p style={{paddingBottom:"10px"}}>Select your preferred language:</p>
                  <label style={{fontSize:"16px"}}>
                    <input
                      type="radio"
                      name="language"
                      value="none"
                      onClick={() => setSelectedLanguage('none')}
                    />
                    English
                  </label>
                
                  <label style={{fontSize:"16px"}}>
                    <input
                      type="radio"
                      name="language"
                      value="spanish"
                      onClick={() => setSelectedLanguage('spanish')}
                    />
                  Español
                  </label>
                  <label style={{fontSize:"16px"}}>
                    <input
                      type="radio"
                      name="language"
                      value="bangla"
                      onClick={() => setSelectedLanguage('bangla')}
                    />
                    বাংলা
                  </label>
                </div>
     
            </>
            );
            modalAction("first");
            modalConfirm(true);
            window.scrollTo(0);
          }}
          className="btnss"
          style={{ maxWidth: "100%", width: "100%" }}
          kind="primary"
          size="lg"
          disabled={setassDisable}
        >
          Take Assessment
        </Button>
        
        ) : (
          <Button
            onClick={() => {
              modalHeader("Ready to take an assessment?");
              modalparagraph(
                <>
                <p>
                This action will use one assessment from your plan. Good luck!
                </p>
                <br />
  
      
                  <div>
                    <p style={{paddingBottom:"10px"}}>Select your preferred language:</p>
                    <label style={{fontSize:"16px"}}>
                      <input
                        type="radio"
                        name="language"
                        value="none"
                        onClick={() => setSelectedLanguage('none')}
                      />
                      English
                    </label>
                  
                    <label style={{fontSize:"16px"}}>
                      <input
                        type="radio"
                        name="language"
                        value="spanish"
                        onClick={() => setSelectedLanguage('spanish')}
                      />
                    Español
                    </label>
                    <label style={{fontSize:"16px"}}>
                      <input
                        type="radio"
                        name="language"
                        value="bangla"
                        onClick={() => setSelectedLanguage('bangla')}
                      />
                      বাংলা
                    </label>
                  </div>
       
              </>
              );
              modalAction("first");
              modalConfirm(true);
              window.scrollTo(0)
            }}
            className="btnss"
            style={{ maxWidth: "100%", width: "100%" }}
            kind="primary"
            size="lg"
            disabled={setassDisable}
          >
            Take Assessment
          </Button>
        )}
      </div>
    </div>
  );
}
