const spanishData = [
    {
      Block: "CUSTOMER",
      SubBlock: "Who, Where & What are they?",
      Question: "Do you know who your customers are?",
      BBlock: "ক্রেতা",
      BSubBlock: "তারা কারা, কোথায় এবং কি কাজ করছে?",
      BQuestion: "আপনি কি জানেন আপনার গ্রাহক কারা?",
      SBlock: "CLIENTE",
      SSubBlock: "¿Quiénes, dónde y qué son?",
      SQuestion: "¿Sabes quiénes son tus clientes?",
    },
    {
      Block: "CUSTOMER",
      SubBlock: "Who, Where & What are they?",
      Question: "Do you know where they are? (The geographic characteristics.)",
      BBlock: "ক্রেতা",
      BSubBlock: "তারা কারা, কোথায় এবং কি কাজ করছে?",
      BQuestion: "আপনি কি জানেন তারা কোথায় আছেন?(ভৌগলিক বৈশিষ্ট্য।)",
      SBlock: "CLIENTE",
      SSubBlock: "¿Quiénes, dónde y qué son?",
      SQuestion: "¿Sabes dónde están? Las características geográficas.",
    },
    {
      Block: "CUSTOMER",
      SubBlock: "Who, Where & What are they?",
      Question:
        "Do you know what they are?  Their demographic characteristics (B2B = Job title, industry, size, types of business; B2C = age, sex, income, profession, etc.)",
      BBlock: "ক্রেতা",
      BSubBlock: "তারা কারা, কোথায় এবং কি কাজ করছে?",
      BQuestion:
        "আপনি কি জানেন তারা কারা? তাদের জনসংখ্যা সংক্রান্ত  বৈশিষ্ট্য (B2B = পদবী, শিল্প, আকার, ব্যবসায়ের ধরন; B2C = বয়স, লিঙ্গ, আয়, পেশা ইত্যাদি)",
      SBlock: "CLIENTE",
      SSubBlock: "¿Quiénes, dónde y qué son?",
      SQuestion:
        "¿Sabes cuáles son? Sus características demográficas (B2B = puesto de trabajo, industria, tamaño, tipos de negocio; B2C = edad, sexo, ingresos, profesión, etc.)",
    },
    {
      Block: "CUSTOMER",
      SubBlock: "Who, Where & What are they?",
      Question:
        "Do you know (Their psychographic characteristics:How do they think, what do they need, what do they appreciate, hobbies, likes/dislikes, etc.)",
      BBlock: "ক্রেতা",
      BSubBlock: "তারা কারা, কোথায় এবং কি কাজ করছে?",
      BQuestion:
        "আপনি কি জানেন (তাদের সাইকোগ্রাফিক বৈশিষ্ট্য: তারা কী ভাবেন, তাদের কী দরকার, তারা কী উপভোগ করেন, শখ, পছন্দ/অপছন্দ ইত্যাদি)",
      SBlock: "CLIENTE",
      SSubBlock: "¿Quiénes, dónde y qué son?",
      SQuestion:
        "¿Conoces sus características psicográficas? (Cómo piensan, qué necesitan, qué aprecian, pasatiempos, gustos/disgustos, etc.)",
    },
    {
      Block: "CUSTOMER",
      SubBlock: "Value Proposition",
      Question:
        "Do you know what you can do to help them realize potential they aren't yet thinking about?",
      BBlock: "ক্রেতা",
      BSubBlock: "মূল্য প্রস্তাব",
      BQuestion:
        "আপনি কি জানেন আপনি কীভাবে তাদের এমন সম্ভাবনা বুঝতে সাহায্য করতে পারেন যা তারা এখনও ভাবছেন না?",
      SBlock: "CLIENTE",
      SSubBlock: "Propuesta de valor",
      SQuestion:
        "¿Sabe qué puede hacer para ayudarlos a darse cuenta del potencial en el que aún no están pensando?",
    },
    {
      Block: "CUSTOMER",
      SubBlock: "Understanding Pain Points",
      Question: "Do you understand their pain points?",
      BBlock: "ক্রেতা",
      BSubBlock: "সমস্যা/পেইন পয়েন্ট বোঝা",
      BQuestion: "আপনি কি তাদের ব্যথার বিন্দুগুলি/পেইন পয়েন্ট বুঝতে পারেন?",
      SBlock: "CLIENTE",
      SSubBlock: "Comprender los puntos débiles",
      SQuestion: "¿Entiendes sus puntos débiles?",
    },
    {
      Block: "CUSTOMER",
      SubBlock: "Understanding Pain Points",
      Question: "Do you know what bothers them so much that they lose sleep?",
      BBlock: "ক্রেতা",
      BSubBlock: "সমস্যা/পেইন পয়েন্ট বোঝা",
      BQuestion:
        "আপনি কি জানেন কী তাদের এত বিরক্ত করে যে তারা ঘুম হারিয়ে ফেলেন্?",
      SBlock: "CLIENTE",
      SSubBlock: "Comprender los puntos débiles",
      SQuestion: "¿Sabes qué les molesta tanto que les quita el sueño?",
    },
    {
      Block: "CUSTOMER",
      SubBlock: "Understanding Pain Points",
      Question:
        "Do you know what is most frustrating for your customer in your industry?",
      BBlock: "ক্রেতা",
      BSubBlock: "সমস্যা/পেইন পয়েন্ট বোঝা",
      BQuestion:
        "আপনি কি জানেন আপনার শিল্পে আপনার গ্রাহকদের জন্য সবচেয়ে হতাশাজনক কি?",
      SBlock: "CLIENTE",
      SSubBlock: "Comprender los puntos débiles",
      SQuestion:
        "¿Sabe qué es lo más frustrante para su cliente en su industria?",
    },
    {
      Block: "CUSTOMER",
      SubBlock: "Understanding Pain Points",
      Question:
        "Do you know what your customers are most worried about going wrong?",
      BBlock: "ক্রেতা",
      BSubBlock: "সমস্যা/পেইন পয়েন্ট বোঝা",
      BQuestion:
        "আপনি কি জানেন আপনার গ্রাহকরা কী নিয়ে সবচেয়ে বেশি চিন্তিত ভুল হওয়ার?",
      SBlock: "CLIENTE",
      SSubBlock: "Comprender los puntos débiles",
      SQuestion:
        "¿Sabe qué es lo que más les preocupa a sus clientes que salga mal?",
    },
    {
      Block: "CUSTOMER",
      SubBlock: "Understanding Pain Points",
      Question:
        "Do you conduct regular customer feedback regarding your product and services?",
      BBlock: "ক্রেতা",
      BSubBlock: "সমস্যা/পেইন পয়েন্ট বোঝা",
      BQuestion:
        "আপনি কি নিয়মিত আপনার পণ্য এবং পরিষেবার বিষয়ে গ্রাহকের প্রতিক্রিয়া পরিচালনা করেন?",
      SBlock: "CLIENTE",
      SSubBlock: "Comprender los puntos débiles",
      SQuestion:
        "¿Realiza comentarios regulares de los clientes sobre sus productos y servicios?",
    },
    {
      Block: "MARKETING",
      SubBlock: "Branding",
      Question: "Do you have customer appreciation initiatives?",
      BBlock: "মার্কেটিং",
      BSubBlock: "ব্র্যান্ডিং",
      BQuestion: "আপনার কি গ্রাহকের প্রশংসা করার উদ্যোগ আছে?",
      SBlock: "MARKETING",
      SSubBlock: "Branding",
      SQuestion: "¿Tiene iniciativas de reconocimiento al cliente?",
    },
    {
      Block: "MARKETING",
      SubBlock: "Branding",
      Question:
        "Do you know if your customer relates your name or logo with your value proposition?",
      BBlock: "মার্কেটিং",
      BSubBlock: "ব্র্যান্ডিং",
      BQuestion:
        "আপনি কি জানেন আপনার গ্রাহক আপনার নাম বা লোগোকে আপনার মূল্য প্রস্তাবের সাথে সম্পর্কিত করে?",
      SBlock: "MARKETING",
      SSubBlock: "Branding",
      SQuestion:
        "¿Sabes si tu cliente relaciona tu nombre o logo con tu propuesta de valor?",
    },
    {
      Block: "MARKETING",
      SubBlock: "Branding",
      Question:
        "Do you participate in trade shows, association memberships, conferences, etc.?",
      BBlock: "মার্কেটিং",
      BSubBlock: "ব্র্যান্ডিং",
      BQuestion:
        "আপনি কি ট্রেড শো, অ্যাসোসিয়েশন সদস্যতা, সম্মেলন ইত্যাদিতে অংশগ্রহণ করেন?",
      SBlock: "MARKETING",
      SSubBlock: "Branding",
      SQuestion:
        "¿Participa en ferias comerciales, membresías en asociaciones, conferencias, etc.?",
    },
    {
      Block: "MARKETING",
      SubBlock: "Ideal Customer",
      Question: "Do you know who your ideal customers are?",
      BBlock: "মার্কেটিং",
      BSubBlock: "আদর্শ গ্রাহক",
      BQuestion: "আপনি কি জানেন আপনার আদর্শ গ্রাহক কারা?",
      SBlock: "MARKETING",
      SSubBlock: "Cliente ideal",
      SQuestion: "¿Sabes quiénes son tus clientes ideales?",
    },
    {
      Block: "MARKETING",
      SubBlock: "Ideal Customer",
      Question: "Do you ask your customer for a referral or introduction?",
      BBlock: "মার্কেটিং",
      BSubBlock: "আদর্শ গ্রাহক",
      BQuestion: "আপনি কি আপনার গ্রাহকের কাছ থেকে রেফারেল বা পরিচয় চান?",
      SBlock: "MARKETING",
      SSubBlock: "Cliente ideal",
      SQuestion: "¿Le pide a su cliente una referencia o presentación?",
    },
    {
      Block: "MARKETING",
      SubBlock: "Ideal Customer",
      Question: "Does your customer usually send you a referral or introduction?",
      BBlock: "মার্কেটিং",
      BSubBlock: "আদর্শ গ্রাহক",
      BQuestion: "আপনার গ্রাহক কি সাধারণত আপনাকে রেফারেল বা পরিচয় পাঠান?",
      SBlock: "MARKETING",
      SSubBlock: "Cliente ideal",
      SQuestion: "¿Su cliente suele enviarle una referencia o una presentación?",
    },
    {
      Block: "MARKETING",
      SubBlock: "Ideal Customer",
      Question: "Does your customer help you learn and expand your knowledge?",
      BBlock: "মার্কেটিং",
      BSubBlock: "আদর্শ গ্রাহক",
      BQuestion:
        "আপনার গ্রাহক কি আপনাকে শিখতে এবং আপনার জ্ঞান বাড়াতে সাহায্য করেন?",
      SBlock: "MARKETING",
      SSubBlock: "Cliente ideal",
      SQuestion: "¿Su cliente le ayuda a aprender y ampliar sus conocimientos?",
    },
    {
      Block: "MARKETING",
      SubBlock: "Ideal Customer",
      Question: "Do you have repeat customers?",
      BBlock: "মার্কেটিং",
      BSubBlock: "আদর্শ গ্রাহক",
      BQuestion: "আপনার কি পুনরাবৃত্ত গ্রাহক আছে?",
      SBlock: "MARKETING",
      SSubBlock: "Cliente ideal",
      SQuestion: "¿Tiene clientes habituales?",
    },
    {
      Block: "CASH FLOW",
      SubBlock: "Results",
      Question: "Is your business growing?",
  
      BBlock: "নগদ প্রবাহ",
      BSubBlock: "ফলাফল",
      BQuestion: "আপনার ব্যবসা কি বাড়ছে?",
      SBlock: "FLUJO DE FONDOS",
      SSubBlock: "Resultados",
      SQuestion: "¿Tu negocio está creciendo?",
    },
    {
      Block: "CUSTOMER",
      SubBlock: "Who, Where & What are they?",
      Question: "Is your customer mix changing?",
      BBlock: "ক্রেতা",
      BSubBlock: "তারা কারা, কোথায় এবং কি কাজ করছে?",
      BQuestion: "আপনার গ্রাহক মিশ্রণ কি পরিবর্তন হচ্ছে?",
      SBlock: "CLIENTE",
      SSubBlock: "¿Quiénes, dónde y qué son?",
      SQuestion: "¿Está cambiando su combinación de clientes?",
    },
    {
      Block: "CUSTOMER",
      SubBlock: "Value Proposition",
      Question:
        "Do you have a unique value proposition to solve your customers’ challenges?",
      BBlock: "ক্রেতা",
      BSubBlock: "মূল্য প্রস্তাব",
      BQuestion:
        "আপনার গ্রাহকদের চ্যালেঞ্জ সমাধানের জন্য আপনার কি একটি অনন্য মূল্য প্রস্তাব আছে?",
      SBlock: "CLIENTE",
      SSubBlock: "Propuesta de valor",
      SQuestion:
        "¿Tiene una propuesta de valor única para resolver los desafíos de sus clientes?",
    },
    {
      Block: "CUSTOMER",
      SubBlock: "Value Proposition",
      Question: "Does your value proposition consist of 3 to 5 items?",
      BBlock: "ক্রেতা",
      BSubBlock: "মূল্য প্রস্তাব",
      BQuestion: "আপনার মূল্য প্রস্তাবটি কি ৩ থেকে ৫ আইটেম নিয়ে গঠিত?",
      SBlock: "CLIENTE",
      SSubBlock: "Propuesta de valor",
      SQuestion: "¿Tu propuesta de valor consta de 3 a 5 ítems?",
    },
    {
      Block: "CUSTOMER",
      SubBlock: "Sales Process",
      Question: "Do you have a focused and effective sales function/team?",
      BBlock: "ক্রেতা",
      BSubBlock: "বিক্রয় প্রক্রিয়া",
      BQuestion: "আপনার কি একটি ফোকাসড এবং কার্যকর বিক্রয় ফাংশন/টিম আছে?",
      SBlock: "CLIENTE",
      SSubBlock: "Proceso de ventas",
      SQuestion: "¿Tiene una función/equipo de ventas enfocado y efectivo?",
    },
    {
      Block: "CUSTOMER",
      SubBlock: "Sales Process",
      Question: "Do you have a clearly defined sales process?",
      BBlock: "ক্রেতা",
      BSubBlock: "বিক্রয় প্রক্রিয়া",
      BQuestion: "আপনার কি একটি স্পষ্টভাবে সংজ্ঞায়িত বিক্রয় প্রক্রিয়া আছে?",
      SBlock: "CLIENTE",
      SSubBlock: "Proceso de ventas",
      SQuestion: "¿Tiene un proceso de ventas claramente definido?",
    },
    {
      Block: "OPERATIONAL & TECHNOLOGY",
      SubBlock: "Education & Training",
      Question:
        "Do you have ongoing training and development for your sales team?",
      BBlock: "অপারেশনাল ও টেকনোলজি",
      BSubBlock: "শিক্ষা ও প্রশিক্ষণ",
      BQuestion: "আপনার বিক্রয় দলের জন্য চলমান প্রশিক্ষণ এবং উন্নয়ন আছে?",
      SBlock: "OPERACIÓN Y TECNOLOGÍA",
      SSubBlock: "Educación y formación",
      SQuestion:
        "¿Tiene capacitación y desarrollo continuo para su equipo de ventas?",
    },
    {
      Block: "CUSTOMER",
      SubBlock: "Sales Process",
      Question:
        "Do you have a sales strategy for increasing your lead activities?",
      BBlock: "ক্রেতা",
      BSubBlock: "বিক্রয় প্রক্রিয়া",
      BQuestion: "আপনার লিড কার্যক্রম বাড়ানোর জন্য একটি বিক্রয় কৌশল আছে?",
      SBlock: "CLIENTE",
      SSubBlock: "Proceso de ventas",
      SQuestion:
        "¿Tiene una estrategia de ventas para aumentar sus actividades de clientes potenciales?",
    },
    {
      Block: "CUSTOMER",
      SubBlock: "Sales Process",
      Question: "Do you have a process to monitor your sales activities?",
      BBlock: "ক্রেতা",
      BSubBlock: "বিক্রয় প্রক্রিয়া",
      BQuestion: "আপনার বিক্রয় কার্যক্রম পর্যবেক্ষণ করার একটি প্রক্রিয়া আছে?",
      SBlock: "CLIENTE",
      SSubBlock: "Proceso de ventas",
      SQuestion: "¿Tiene un proceso para monitorear sus actividades de ventas?",
    },
    {
      Block: "OPERATIONAL & TECHNOLOGY",
      SubBlock: "Technology & Streamline",
      Question:
        "Do you use technology such as CRM to monitor your sales activities?",
      BBlock: "অপারেশনাল ও টেকনোলজি",
      BSubBlock: "প্রযুক্তি এবং স্ট্রীমলাইন",
      BQuestion:
        "আপনি কি আপনার বিক্রয় কার্যক্রম পর্যবেক্ষণ করার জন্য প্রযুক্তি ব্যবহার করেন, যেমন CRM ইত্যাদি?",
      SBlock: "OPERACIÓN Y TECNOLOGÍA",
      SSubBlock: "Tecnología y optimización",
      SQuestion:
        "¿Utiliza tecnología para monitorear sus actividades de ventas, como CRM, etc.?",
    },
    {
      Block: "CUSTOMER",
      SubBlock: "Sales Process",
      Question: "Do you have a sales forecasting process?",
      BBlock: "ক্রেতা",
      BSubBlock: "বিক্রয় প্রক্রিয়া",
      BQuestion: "আপনার কি একটি বিক্রয় পূর্বাভাস প্রক্রিয়া আছে?",
      SBlock: "CLIENTE",
      SSubBlock: "Proceso de ventas",
      SQuestion: "¿Tiene un proceso de previsión de ventas?",
    },
    {
      Block: "CUSTOMER",
      SubBlock: "Sales Process",
      Question:
        "Do you have regular meetings with the sales team to communicate results, activities, and expectations?",
      BBlock: "ক্রেতা",
      BSubBlock: "বিক্রয় প্রক্রিয়া",
      BQuestion:
        "ফলাফল, ক্রিয়াকলাপ এবং প্রত্যাশাগুলি জানাতে আপনি কি বিক্রয় দলের সাথে নিয়মিত বৈঠক করেন?",
      SBlock: "CLIENTE",
      SSubBlock: "Proceso de ventas",
      SQuestion:
        "¿Tiene reuniones periódicas con el equipo de ventas para comunicar resultados, actividades y expectativas?",
    },
  
    {
      Block: "CUSTOMER",
      SubBlock: "Sales Process",
      Question: "Are you happy with your sales process and efforts?",
      BBlock: "ক্রেতা",
      BSubBlock: "বিক্রয় প্রক্রিয়া",
      BQuestion: "আপনি কি আপনার বিক্রয় প্রক্রিয়া এবং প্রচেষ্টা নিয়ে খুশি?",
      SBlock: "CLIENTE",
      SSubBlock: "Proceso de ventas",
      SQuestion: "¿Está satisfecho con su proceso y esfuerzos de ventas?",
    },
    {
      Block: "CUSTOMER",
      SubBlock: "Value Proposition",
      Question: "Are you happy with your product and service offerings?",
      BBlock: "ক্রেতা",
      BSubBlock: "মূল্য প্রস্তাব",
      BQuestion: "আপনি কি আপনার পণ্য এবং পরিষেবা অফার নিয়ে খুশি?",
      SBlock: "CLIENTE",
      SSubBlock: "Propuesta de valor",
      SQuestion: "¿Está satisfecho con su oferta de productos y servicios?",
    },
    {
      Block: "OPERATIONAL & TECHNOLOGY",
      SubBlock: "Technology & Streamline",
      Question:
        "Do you have an adequate accounting system to manage all aspects of accounting, project/job costing, and financial reporting, etc.?",
      BBlock: "অপারেশনাল ও টেকনোলজি",
      BSubBlock: "প্রযুক্তি এবং স্ট্রীমলাইন",
      BQuestion:
        "আপনার কি একটি পর্যাপ্ত অ্যাকাউন্টিং সিস্টেম আছে যা অ্যাকাউন্টিং, প্রকল্প/জব কস্ট, এবং আর্থিক রিপোর্টিং ইত্যাদির সব দিক পরিচালনা করে?",
      SBlock: "OPERACIÓN Y TECNOLOGÍA",
      SSubBlock: "Tecnología y optimización",
      SQuestion:
        " ¿Tiene un sistema de contabilidad adecuado para gestionar todos los aspectos de la contabilidad, el costeo del proyecto/trabajo y los informes financieros, etc.?",
    },
    {
      Block: "OPERATIONAL & TECHNOLOGY",
      SubBlock: "Technology & Streamline",
      Question: "Do you have adequate internal control in place for accounting?",
      BBlock: "অপারেশনাল ও টেকনোলজি",
      BSubBlock: "প্রযুক্তি এবং স্ট্রীমলাইন",
      BQuestion:
        "অ্যাকাউন্টিংয়ের জন্য আপনার কি পর্যাপ্ত অভ্যন্তরীণ নিয়ন্ত্রণ আছে?",
      SBlock: "OPERACIÓN Y TECNOLOGÍA",
      SSubBlock: "Tecnología y optimización",
      SQuestion: "¿Cuenta con un control interno adecuado para la contabilidad?",
    },
    {
      Block: "FINANCIAL CONTROL & PLANNING",
      SubBlock: "Financial Statements",
      Question: "Do you have a process to review your financial statements?",
      BBlock: "আর্থিক নিয়ন্ত্রণ ও পরিকল্পনা",
      BSubBlock: "আর্থিক বিবৃতি",
      BQuestion:
        "আপনার কি আপনার আর্থিক বিবরণী পর্যালোচনা করার একটি প্রক্রিয়া আছে?",
      SBlock: "CONTROL Y PLANIFICACIÓN FINANCIERA",
      SSubBlock: "Estados financieros",
      SQuestion: "¿Tiene un proceso para revisar sus estados financieros?",
    },
    {
      Block: "FINANCIAL CONTROL & PLANNING",
      SubBlock: "Financial Statements",
      Question:
        "Do you typically get your financial statements by the 15th of each month?",
      BBlock: "আর্থিক নিয়ন্ত্রণ ও পরিকল্পনা",
      BSubBlock: "আর্থিক বিবৃতি",
      BQuestion:
        "আপনি কি সাধারণত প্রতি মাসের ১৫ তারিখ নাগাদ আপনার আর্থিক বিবরণী পান?",
      SBlock: "CONTROL Y PLANIFICACIÓN FINANCIERA",
      SSubBlock: "Estados financieros",
      SQuestion:
        " ¿Normalmente recibe sus estados financieros antes del día 15 de cada mes?",
    },
    {
      Block: "FINANCIAL CONTROL & PLANNING",
      SubBlock: "Budgeting & Forecasting",
      Question:
        "Do you have an annual budgeting process that includes a capital budget?",
      BBlock: "আর্থিক নিয়ন্ত্রণ ও পরিকল্পনা",
      BSubBlock: "বাজেটিং এবং পূর্বাভাস",
      BQuestion:
        "আপনার কি একটি বার্ষিক বাজেটিং প্রক্রিয়া আছে যার মধ্যে একটি মূলধন বাজেট অন্তর্ভুক্ত আছে?",
      SBlock: "CONTROL Y PLANIFICACIÓN FINANCIERA",
      SSubBlock: "Presupuesto y previsión",
      SQuestion:
        " ¿Tiene un proceso presupuestario anual que incluya un presupuesto de capital?",
    },
    {
      Block: "FINANCIAL CONTROL & PLANNING",
      SubBlock: "Budgeting & Forecasting",
      Question: "Do you have a rolling 12-month forecasting process?",
      BBlock: "আর্থিক নিয়ন্ত্রণ ও পরিকল্পনা",
      BSubBlock: "বাজেটিং এবং পূর্বাভাস",
      BQuestion: "আপনার কি একটি রোলিং ১২-মাসের পূর্বাভাস প্রক্রিয়া আছে?",
      SBlock: "CONTROL Y PLANIFICACIÓN FINANCIERA",
      SSubBlock: "Presupuesto y previsión",
      SQuestion: "¿Tiene un proceso de pronóstico continuo de 12 meses?",
    },
    {
      Block: "FINANCIAL CONTROL & PLANNING",
      SubBlock: "Financial Statements",
      Question:
        "Do you get a monthly variance analysis of your P&L, financial ratios for balance sheet and cash flow statements, etc.?",
      BBlock: "আর্থিক নিয়ন্ত্রণ ও পরিকল্পনা",
      BSubBlock: "আর্থিক বিবৃতি",
      BQuestion:
        "আপনি কি আপনার P&L, ব্যালেন্স শিট এবং ক্যাশ ফ্লো বিবরণীর জন্য মাসিক ভিন্নতা বিশ্লেষণ পান?",
      SBlock: "CONTROL Y PLANIFICACIÓN FINANCIERA",
      SSubBlock: "Estados financieros",
      SQuestion:
        " ¿Recibe un análisis de variación mensual de sus pérdidas y ganancias, índices financieros para el balance y los estados de flujo de efectivo, etc.?",
    },
    {
      Block: "FINANCIAL CONTROL & PLANNING",
      SubBlock: "Financial Statements",
      Question: "Do you get daily or weekly bank reconciliation?",
      BBlock: "আর্থিক নিয়ন্ত্রণ ও পরিকল্পনা",
      BSubBlock: "আর্থিক বিবৃতি",
      BQuestion: "আপনি কি দৈনিক বা সাপ্তাহিক ব্যাংক পুনর্মিলন পান?",
      SBlock: "CONTROL Y PLANIFICACIÓN FINANCIERA",
      SSubBlock: "Estados financieros",
      SQuestion: "¿Reciben conciliación bancaria diaria o semanal?",
    },
    {
      Block: "CASH FLOW",
      SubBlock: "Results",
      Question: "Are you happy with your revenue?",
      BBlock: "নগদ প্রবাহ",
      BSubBlock: "ফলাফল",
      BQuestion: "আপনি কি আপনার আয় নিয়ে খুশি?",
      SBlock: "FLUJO DE FONDOS",
      SSubBlock: "Resultados",
      SQuestion: "¿Está satisfecho con sus ingresos?",
    },
    {
      Block: "CASH FLOW",
      SubBlock: "Results",
      Question: "Are you satisfied with your profit number?",
      BBlock: "নগদ প্রবাহ",
      BSubBlock: "ফলাফল",
      BQuestion: "আপনি কি আপনার মুনাফার সংখ্যা নিয়ে সন্তুষ্ট?",
      SBlock: "FLUJO DE FONDOS",
      SSubBlock: "Resultados",
      SQuestion: "¿Está satisfecho con su cifra de beneficios?",
    },
    {
      Block: "CASH FLOW",
      SubBlock: "Results",
      Question: "Does your company generate positive cash flow?",
      BBlock: "নগদ প্রবাহ",
      BSubBlock: "ফলাফল",
      BQuestion: "আপনার কোম্পানি কি ইতিবাচক নগদ প্রবাহ উৎপন্ন করে?",
      SBlock: "FLUJO DE FONDOS",
      SSubBlock: "Resultados",
      SQuestion: " ¿Su empresa genera flujo de caja positivo?",
    },
    {
      Block: "CASH FLOW",
      SubBlock: "Access to Fund",
      Question: "Do you have access to cash for the near term (1-2 years)?",
      BBlock: "নগদ প্রবাহ",
      BSubBlock: "নগদ অর্থের অ্যাক্সেস",
      BQuestion: "আপনার কি নিকট ভবিষ্যতে (১-২ বছরের) নগদ অর্থের অ্যাক্সেস আছে?",
      SBlock: "FLUJO DE FONDOS",
      SSubBlock: "Acceso al fondo",
      SQuestion: " ¿Tiene acceso a efectivo a corto plazo (1-2 años)? ",
    },
    {
      Block: "CASH FLOW",
      SubBlock: "Access to Fund",
      Question: "Do you have 3-6 months of working capital?",
      BBlock: "নগদ প্রবাহ",
      BSubBlock: "নগদ অর্থের অ্যাক্সেস",
      BQuestion: "আপনার কি ৩-৬ মাসের ওয়ার্কিং ক্যাপিটাল  আছে?",
      SBlock: "FLUJO DE FONDOS",
      SSubBlock: "Acceso al fondo",
      SQuestion: " ¿Tiene entre 3 y 6 meses de capital de trabajo? ",
    },
    {
      Block: "CASH FLOW",
      SubBlock: "Access to Fund",
      Question: "Do you have lines of credit or credit facilities available?",
      BBlock: "নগদ প্রবাহ",
      BSubBlock: "নগদ অর্থের অ্যাক্সেস",
      BQuestion: "আপনার কি কোন লাইন অফ ক্রেডিট বা ক্রেডিট সুবিধা আছে?",
      SBlock: "FLUJO DE FONDOS",
      SSubBlock: "Acceso al fondo",
      SQuestion:
        "¿Tiene alguna línea de crédito o facilidades de crédito disponibles?",
    },
    {
      Block: "BONUS Q",
      SubBlock: "Internal/External - Resource",
      Question: "Do you have an internal accounting team?",
      BBlock: "বোনাস প্রশ্ন",
      BSubBlock: "ইন্টারনাল/এক্সটারনাল - রিসোর্স",
      BQuestion: "আপনার কি একটি অভ্যন্তরীণ অ্যাকাউন্টিং টিম আছে?",
      SBlock: "BONIFICACIÓN Q",
      SSubBlock: "Interno/Externo - Recurso",
      SQuestion: "¿Tiene un equipo de contabilidad interno?",
    },
    {
      Block: "FINANCIAL CONTROL & PLANNING",
      SubBlock: "Financial Statements",
      Question: "Are you happy with your accounting process?",
      BBlock: "আর্থিক নিয়ন্ত্রণ ও পরিকল্পনা",
      BSubBlock: "আর্থিক বিবৃতি",
      BQuestion: "আপনি কি আপনার অ্যাকাউন্টিং প্রক্রিয়া নিয়ে খুশি?",
      SBlock: "CONTROL Y PLANIFICACIÓN FINANCIERA",
      SSubBlock: "Estados financieros",
      SQuestion: "¿Está satisfecho con su proceso contable?",
    },
    {
      Block: "OPERATIONAL & TECHNOLOGY",
      SubBlock: "Technology & Streamline",
      Question: "Do you have an ERP (Enterprise Resource Planning) system?",
      BBlock: "অপারেশনাল ও টেকনোলজি",
      BSubBlock: "প্রযুক্তি এবং স্ট্রীমলাইন",
      BQuestion:
        "আপনার কি একটি ERP (এন্টারপ্রাইজ রিসোর্স প্ল্যানিং) সিস্টেম আছে?",
      SBlock: "OPERACIÓN Y TECNOLOGÍA",
      SSubBlock: "Tecnología y optimización",
      SQuestion:
        "¿Tiene un sistema ERP (Planificación de Recursos Empresariales)?",
    },
    {
      Block: "OPERATIONAL & TECHNOLOGY",
      SubBlock: "Technology & Streamline",
      Question:
        "Do you have an IT system backup that includes a cyber security restoration process?",
      BBlock: "অপারেশনাল ও টেকনোলজি",
      BSubBlock: "প্রযুক্তি এবং স্ট্রীমলাইন",
      BQuestion:
        "আপনার কি একটি আইটি সিস্টেম ব্যাকআপ আছে যার মধ্যে একটি সাইবার নিরাপত্তা পুনরুদ্ধার প্রক্রিয়া অন্তর্ভুক্ত আছে?",
      SBlock: "OPERACIÓN Y TECNOLOGÍA",
      SSubBlock: "Tecnología y optimización",
      SQuestion:
        "¿Tiene un respaldo del sistema de TI que incluya un proceso de restauración de la seguridad cibernética?",
    },
    {
      Block: "FINANCIAL CONTROL & PLANNING",
      SubBlock: "Budgeting & Forecasting",
      Question: "Do you have business insurance in place and review once a year?",
      BBlock: "আর্থিক নিয়ন্ত্রণ ও পরিকল্পনা",
      BSubBlock: "বাজেটিং এবং পূর্বাভাস",
      BQuestion: "আপনার কি ব্যবসায়িক বীমা আছে এবং বছরে একবার পর্যালোচনা করেন?",
      SBlock: "CONTROL Y PLANIFICACIÓN FINANCIERA",
      SSubBlock: "Presupuesto y previsión",
      SQuestion:
        " ¿Tiene un seguro comercial vigente y lo revisa una vez al año?",
    },
    {
      Block: "BONUS Q",
      SubBlock: "Internal/External - Resource",
      Question: "Do you have an internal IT team?",
      BBlock: "বোনাস প্রশ্ন",
      BSubBlock: "ইন্টারনাল/এক্সটারনাল - রিসোর্স",
      BQuestion: "আপনার কি একটি অভ্যন্তরীণ আইটি টিম আছে?",
      SBlock: "BONIFICACIÓN Q",
      SSubBlock: "Interno/Externo - Recurso",
      SQuestion: " ¿Tiene un equipo de TI interno? ",
    },
    {
      Block: "OPERATIONAL & TECHNOLOGY",
      SubBlock: "Technology & Streamline",
      Question: "Are you happy with your IT process?",
      BBlock: "অপারেশনাল ও টেকনোলজি",
      BSubBlock: "প্রযুক্তি এবং স্ট্রীমলাইন",
      BQuestion: "আপনি কি আপনার আইটি প্রক্রিয়া নিয়ে খুশি?",
      SBlock: "OPERACIÓN Y TECNOLOGÍA",
      SSubBlock: "Tecnología y optimización",
      SQuestion: "¿Está satisfecho con su proceso de TI?",
    },
    {
      Block: "MARKETING",
      SubBlock: "Target Market",
      Question:
        "Is your target market clear, and are your sales and marketing efforts focused on it?",
  
      BBlock: "মার্কেটিং",
      BSubBlock: "টার্গেট মার্কেট",
      BQuestion:
        "আপনার লক্ষ্যবাজার কি স্পষ্ট, এবং আপনার বিক্রয় এবং বিপণন প্রচেষ্টা কি এটির উপর ফোকাস করে?",
      SBlock: "MARKETING",
      SSubBlock: "Mercado objetivo",
      SQuestion:
        " ¿Está claro su mercado objetivo y sus esfuerzos de ventas y marketing se centran en él? ",
    },
    {
      Block: "MARKETING",
      SubBlock: "Target Market",
      Question: "Do you have a clearly defined marketing plan?",
      BBlock: "মার্কেটিং",
      BSubBlock: "টার্গেট মার্কেট",
      BQuestion: "আপনার কি একটি স্পষ্টভাবে সংজ্ঞায়িত বিপণন পরিকল্পনা আছে?",
      SBlock: "MARKETING",
      SSubBlock: "Mercado objetivo",
      SQuestion: "¿Tiene un plan de marketing claramente definido?",
    },
    {
      Block: "MARKETING",
      SubBlock: "Branding",
      Question: "Do you have separate marketing and branding efforts?",
      BBlock: "মার্কেটিং",
      BSubBlock: "ব্র্যান্ডিং",
      BQuestion: "আপনার কি পৃথক মার্কেটিং এবং ব্র্যান্ডিং প্রচেষ্টা আছে?",
      SBlock: "MARKETING",
      SSubBlock: "Branding",
      SQuestion: "¿Tiene esfuerzos separados de marketing y marca?",
    },
    {
      Block: "MARKETING",
      SubBlock: "Target Market",
      Question: "Do you have your differentiators clearly stated?",
      BBlock: "মার্কেটিং",
      BSubBlock: "টার্গেট মার্কেট",
      BQuestion: "আপনি কি আপনার পার্থক্যকারীদের স্পষ্টভাবে উল্লেখ করেছেন?",
      SBlock: "MARKETING",
      SSubBlock: "Mercado objetivo",
      SQuestion: "¿Tiene sus diferenciadores claramente establecidos?",
    },
    {
      Block: "CUSTOMER",
      SubBlock: "Value Proposition",
      Question:
        "Do your sales and marketing efforts communicate your unique value proposition?",
      BBlock: "ক্রেতা",
      BSubBlock: "মূল্য প্রস্তাব",
      BQuestion:
        "আপনার বিক্রয় এবং বিপণন প্রচেষ্টা কি আপনার অনন্য মূল্য প্রস্তাবের  সাথে  যোগাযোগ করে?",
      SBlock: "CLIENTE",
      SSubBlock: "Propuesta de valor",
      SQuestion:
        "¿Sus esfuerzos de ventas y marketing comunican su propuesta de valor única?",
    },
    {
      Block: "MARKETING",
      SubBlock: "Branding",
      Question: "Do you believe your marketing efforts are working?",
      BBlock: "মার্কেটিং",
      BSubBlock: "ব্র্যান্ডিং",
      BQuestion: "আপনি কি বিশ্বাস করেন আপনার বিপণন প্রচেষ্টা কাজ করছে?",
      SBlock: "MARKETING",
      SSubBlock: "Branding",
      SQuestion: "¿Cree que sus esfuerzos de marketing están funcionando?",
    },
    {
      Block: "BONUS Q",
      SubBlock: "Internal/External - Resource",
      Question: "Do you have an internal marketing team?",
      BBlock: "বোনাস প্রশ্ন",
      BSubBlock: "ইন্টারনাল/এক্সটারনাল - রিসোর্স",
      BQuestion: "আপনার কি একটি অভ্যন্তরীণ বিপণন দল আছে?",
      SBlock: "BONIFICACIÓN Q",
      SSubBlock: "Interno/Externo - Recurso",
      SQuestion: "¿Tiene un equipo de marketing interno?",
    },
    {
      Block: "CUSTOMER",
      SubBlock: "Value Proposition",
      Question: "Does your offerings align with your customer's needs?",
      BBlock: "ক্রেতা",
      BSubBlock: "মূল্য প্রস্তাব",
      BQuestion: "আপনার অফারিং কি আপনার গ্রাহকের প্রয়োজনের সাথে সামঞ্জস্যপূর্ণ?",
      SBlock: "CLIENTE",
      SSubBlock: "Propuesta de valor",
      SQuestion: "¿Sus ofertas se alinean con las necesidades de sus clientes?",
    },
    {
      Block: "CUSTOMER",
      SubBlock: "Value Proposition",
      Question:
        "Are you focusing your conversations on helping your customers make good buying decisions?",
      BBlock: "ক্রেতা",
      BSubBlock: "মূল্য প্রস্তাব",
      BQuestion:
        "আপনি কি আপনার গ্রাহকদের ভাল কেনার সিদ্ধান্ত নিতে সাহায্য করার জন্য আপনার কথোপকথন ফোকাস করছেন?",
      SBlock: "CLIENTE",
      SSubBlock: "Propuesta de valor",
      SQuestion:
        "¿Está centrando su conversación en ayudar a su cliente a tomar buenas decisiones de compra?",
    },
    {
      Block: "MARKETING",
      SubBlock: "Target Market",
      Question:
        "Are you getting feedback from your customers regarding the effectiveness of your marketing efforts?",
      BBlock: "মার্কেটিং",
      BSubBlock: "টার্গেট মার্কেট",
      BQuestion:
        "আপনি কি আপনার বিপণন প্রচেষ্টার কার্যকারিতা সম্পর্কে আপনার গ্রাহকের কাছ থেকে প্রতিক্রিয়া পাচ্ছেন?",
      SBlock: "MARKETING",
      SSubBlock: "Mercado objetivo",
      SQuestion:
        "¿Está recibiendo comentarios de sus clientes sobre la eficacia de sus esfuerzos de marketing?",
    },
    {
      Block: "MARKETING",
      SubBlock: "Competition",
      Question: "Do you know your competition?",
      BBlock: "মার্কেটিং",
      BSubBlock: "প্রতিযোগিতা",
      BQuestion: "আপনি কি আপনার প্রতিযোগিতা জানেন?",
      SBlock: "MARKETING",
      SSubBlock: "Competencia",
      SQuestion: "¿Conoces a tu competencia?",
    },
    {
      Block: "MARKETING",
      SubBlock: "Competition",
      Question: "Do you know what your competition does better than you?",
      BBlock: "মার্কেটিং",
      BSubBlock: "প্রতিযোগিতা",
      BQuestion: "আপনি কি জানেন আপনার প্রতিযোগিতা আপনার চেয়ে ভাল কি করে?",
      SBlock: "MARKETING",
      SSubBlock: "Competencia",
      SQuestion: "¿Sabes qué hace tu competencia mejor que tú?",
    },
    {
      Block: "MARKETING",
      SubBlock: "Competition",
      Question: "Do you know what your competition's weaknesses are?",
      BBlock: "মার্কেটিং",
      BSubBlock: "প্রতিযোগিতা",
      BQuestion: "আপনি কি জানেন আপনার প্রতিযোগিতার দুর্বলতা কি কি?",
      SBlock: "MARKETING",
      SSubBlock: "Competencia",
      SQuestion: "¿Sabes cuáles son las debilidades de tu competencia?",
    },
    {
      Block: "MARKETING",
      SubBlock: "Competition",
      Question:
        "Do you research your competition’s website, social media platforms, press releases, etc.?",
      BBlock: "মার্কেটিং",
      BSubBlock: "প্রতিযোগিতা",
      BQuestion:
        "আপনি কি আপনার প্রতিযোগিতার ওয়েবসাইট, সোশ্যাল মিডিয়া প্ল্যাটফর্ম, প্রেস রিলিজ ইত্যাদি গবেষণা করেন?",
      SBlock: "MARKETING",
      SSubBlock: "Competencia",
      SQuestion:
        "¿Investiga el sitio web de su competencia, la plataforma de redes sociales, los comunicados de prensa, etc.?",
    },
    {
      Block: "MARKETING",
      SubBlock: "Competition",
      Question: "Do you know what you do better than your competition?",
      BBlock: "মার্কেটিং",
      BSubBlock: "প্রতিযোগিতা",
      BQuestion: "আপনি কি জানেন আপনি আপনার প্রতিযোগিতার চেয়ে ভাল কি করেন?",
      SBlock: "MARKETING",
      SSubBlock: "Competencia",
      SQuestion: "¿Sabes qué haces mejor que tu competencia?",
    },
    {
      Block: "MARKETING",
      SubBlock: "Branding",
      Question: "Are you satisfied with your marketing and branding process?",
      BBlock: "মার্কেটিং",
      BSubBlock: "ব্র্যান্ডিং",
      BQuestion:
        "আপনি কি আপনার মার্কেটিং এবং ব্র্যান্ডিং প্রক্রিয়ার নিয়ে সন্তুষ্ট?",
      SBlock: "MARKETING",
      SSubBlock: "Branding",
      SQuestion: "¿Está satisfecho con su proceso de marketing y branding?",
    },
    {
      Block: "PURPOSE",
      SubBlock: "Mission",
      Question:
        "Do you know why your organization exists? (FYI, It's not about the money)",
      BBlock: "উদ্দেশ্য",
      BSubBlock: "মিশন",
      BQuestion:
        "আপনি কি জানেন কেন আপনার প্রতিষ্ঠানটি আছে? (আপনার তথ্যের জন্য: টাকার জন্য নয়)",
      SBlock: "OBJETIVO",
      SSubBlock: "Misión",
      SQuestion:
        "¿Sabe por qué existe su organización? (Para su información, no se trata del dinero)",
    },
    {
      Block: "PURPOSE",
      SubBlock: "Mission",
      Question: "Do you know what your business’ purpose, cause, or passions?",
      BBlock: "উদ্দেশ্য",
      BSubBlock: "মিশন",
      BQuestion: "আপনি কি জানেন আপনার ব্যবসার উদ্দেশ্য, কারণ, বা আগ্রহ কী?",
      SBlock: "OBJETIVO",
      SSubBlock: "Misión",
      SQuestion:
        "¿Sabe cuál es el propósito, la causa o la pasión de su negocio?",
    },
    {
      Block: "PURPOSE",
      SubBlock: "Mission",
      Question: "Do you know what business you are in?",
      BBlock: "উদ্দেশ্য",
      BSubBlock: "মিশন",
      BQuestion: "আপনি কি জানেন আপনি কোন ব্যবসায় আছেন?",
      SBlock: "OBJETIVO",
      SSubBlock: "Misión",
      SQuestion: "¿Sabe en qué negocio está?",
    },
    {
      Block: "PURPOSE",
      SubBlock: "Mission",
      Question: "Do you have a clear understanding of what you do?",
      BBlock: "উদ্দেশ্য",
      BSubBlock: "মিশন",
      BQuestion: "আপনি কি স্পষ্টভাবে বুঝতে পারেন আপনি কী করেন?",
      SBlock: "OBJETIVO",
      SSubBlock: "Misión",
      SQuestion: "¿Tiene una comprensión clara de lo que hace?",
    },
    {
      Block: "PURPOSE",
      SubBlock: "Mission",
      Question: "Do you have a clear understanding of whom you serve?",
      BBlock: "উদ্দেশ্য",
      BSubBlock: "মিশন",
      BQuestion: "আপনি কি স্পষ্টভাবে জানেন আপনি কাদের জন্য কাজ করছেন?",
      SBlock: "OBJETIVO",
      SSubBlock: "Misión",
      SQuestion: "¿Tiene una comprensión clara de a quién sirve?",
    },
    {
      Block: "PURPOSE",
      SubBlock: "Mission",
      Question: "Do you have a clear understanding of how you serve them?",
      BBlock: "উদ্দেশ্য",
      BSubBlock: "মিশন",
      BQuestion: "আপনি কি স্পষ্টভাবে বুঝতে পারেন আপনি কীভাবে তাদের সেবা দিচ্ছেন?",
      SBlock: "OBJETIVO",
      SSubBlock: "Misión",
      SQuestion: "¿Tiene una comprensión clara de cómo les sirve?",
    },
    {
      Block: "PURPOSE",
      SubBlock: "Mission",
      Question:
        "Do you have your Mission written and communicated to all your employees?",
      BBlock: "উদ্দেশ্য",
      BSubBlock: "মিশন",
      BQuestion:
        "আপনার মিশন কি লেখা আছে এবং আপনার সকল কর্মচারীর কাছে তা জানানো হয়েছে?",
      SBlock: "OBJETIVO",
      SSubBlock: "Misión",
      SQuestion:
        "¿Tiene su Misión por escrito y la ha comunicado a todos sus empleados",
    },
    {
      Block: "OPERATIONAL & TECHNOLOGY",
      SubBlock: "Document Core Process",
      Question:
        "Do you have a proven process for doing business with your customers?",
      BBlock: "অপারেশনাল ও টেকনোলজি",
      BSubBlock: "ডকুমেন্ট কোর প্রসেস",
      BQuestion:
        "আপনার কি আপনার গ্রাহকদের সাথে ব্যবসা করার জন্য একটি প্রমাণিত প্রক্রিয়া আছে?",
      SBlock: "OPERACIÓN Y TECNOLOGÍA",
      SSubBlock: "Proceso central del documento",
      SQuestion:
        "¿Tiene un proceso comprobado para hacer negocios con sus clientes?",
    },
    {
      Block: "OPERATIONAL & TECHNOLOGY",
      SubBlock: "Document Core Process",
      Question: "Do you have the core process simplified and documented?",
      BBlock: "অপারেশনাল ও টেকনোলজি",
      BSubBlock: "ডকুমেন্ট কোর প্রসেস",
      BQuestion: "আপনার কি কোর প্রক্রিয়া সরলীকৃত এবং নথিভুক্ত আছে?",
      SBlock: "OPERACIÓN Y TECNOLOGÍA",
      SSubBlock: "Proceso central del documento",
      SQuestion: "¿Tiene el proceso central simplificado y documentado?",
    },
    {
      Block: "PEOPLE",
      SubBlock: "Role, Responsibilities & Accountabilities",
      Question:
        "Does everyone on your team understand the system and follow the process? (The team includes sales, marketing, operations, finance, HR, IT, admin, etc.)",
      BBlock: "কর্মচারি",
      BSubBlock: "ভূমিকা, দায়িত্ব এবং জবাবদিহিতা",
      BQuestion:
        "আপনার দলের সবাই কি সিস্টেমটি বুঝেন এবং প্রক্রিয়াটি অনুসরণ করে? (দলটিতে বিক্রয়, বিপণন, অপারেশন, অর্থ, এইচআর, আইটি, প্রশাসন ইত্যাদি অন্তর্ভুক্ত)",
      SBlock: "GENTE",
      SSubBlock: "Rol, Responsabilidades y Responsabilidades",
      SQuestion:
        " ¿Todos los miembros de su equipo comprenden el sistema y siguen el proceso? El equipo incluye ventas, marketing, operaciones, finanzas, recursos humanos, TI, administración, etc. ",
    },
    {
      Block: "OPERATIONAL & TECHNOLOGY",
      SubBlock: "Document Core Process",
      Question:
        "Do you have quality control procedures to minimize the risk of poor-quality output?",
      BBlock: "অপারেশনাল ও টেকনোলজি",
      BSubBlock: "ডকুমেন্ট কোর প্রসেস",
      BQuestion:
        "আপনার কি মান নিয়ন্ত্রণ পদ্ধতি আছে যা নিম্নমানের আউটপুটের ঝুঁকি কমিয়ে আনে?",
      SBlock: "OPERACIÓN Y TECNOLOGÍA",
      SSubBlock: "Proceso central del documento",
      SQuestion:
        "¿Tiene un procedimiento de control de calidad para minimizar el riesgo de resultados de mala calidad?",
    },
    {
      Block: "OPERATIONAL & TECHNOLOGY",
      SubBlock: "Education & Training",
      Question:
        "Do you have recurring education and training programs for all team members to ensure the proven processes are followed?",
      BBlock: "অপারেশনাল ও টেকনোলজি",
      BSubBlock: "শিক্ষা ও প্রশিক্ষণ",
      BQuestion:
        "আপনার কি সব টিম সদস্যদের জন্য পুনরাবৃত্ত শিক্ষা এবং প্রশিক্ষণ প্রোগ্রাম আছে যা নিশ্চিত করে যে প্রমাণিত প্রক্রিয়া অনুসরণ করা হয়?",
      SBlock: "OPERACIÓN Y TECNOLOGÍA",
      SSubBlock: "Educación y formación",
      SQuestion:
        "¿Tiene un programa recurrente de educación y capacitación para todos los miembros del equipo para garantizar que se siga el proceso probado? ",
    },
    {
      Block: "OPERATIONAL & TECHNOLOGY",
      SubBlock: "Technology & Streamline",
      Question:
        "Do you have a continuous process improvement mindset in the organization?",
      BBlock: "অপারেশনাল ও টেকনোলজি",
      BSubBlock: "প্রযুক্তি এবং স্ট্রীমলাইন",
      BQuestion:
        "আপনার প্রতিষ্ঠানে এ কি একটি ক্রমাগত প্রক্রিয়া উন্নতির মানসিকতা আছে?",
      SBlock: "OPERACIÓN Y TECNOLOGÍA",
      SSubBlock: "Tecnología y optimización",
      SQuestion:
        "¿Tiene una mentalidad de mejora continua de procesos en la organización?",
    },
    {
      Block: "OPERATIONAL & TECHNOLOGY",
      SubBlock: "Technology & Streamline",
      Question:
        "Do you have a process to align the operations team with the sales team?",
      BBlock: "অপারেশনাল ও টেকনোলজি",
      BSubBlock: "প্রযুক্তি এবং স্ট্রীমলাইন",
      BQuestion:
        "আপনার কি বিক্রয় দলের সাথে অপারেশন টিমকে সারিবদ্ধ করার একটি প্রক্রিয়া আছে?",
      SBlock: "OPERACIÓN Y TECNOLOGÍA",
      SSubBlock: "Tecnología y optimización",
      SQuestion:
        "¿Tiene un proceso para alinear el equipo de operaciones con el equipo de ventas?",
    },
    {
      Block: "PEOPLE",
      SubBlock: "Right People, Right Seat",
      Question: "Do you have the right people in your organization?",
      BBlock: "কর্মচারি",
      BSubBlock: "সঠিক কর্মচারি, সঠিক আসন",
      BQuestion: "আপনার প্রতিষ্ঠানে সঠিক লোকেরা কি আছে?",
      SBlock: "GENTE",
      SSubBlock: "Personas adecuadas, asiento adecuado",
      SQuestion: "¿Tienes a las personas adecuadas en tu organización?",
    },
    {
      Block: "PEOPLE",
      SubBlock: "Right People, Right Seat",
      Question: "Do you have the right people in the right seats?",
      BBlock: "কর্মচারি",
      BSubBlock: "সঠিক কর্মচারি, সঠিক আসন",
      BQuestion: "সঠিক লোকেরা কি সঠিক আসনে  আছে?",
      SBlock: "GENTE",
      SSubBlock: "Personas adecuadas, asiento adecuado",
      SQuestion: "¿Tienes a las personas adecuadas en el lugar adecuado?",
    },
    {
      Block: "PEOPLE",
      SubBlock: "Org Structure & HR Process",
      Question:
        "Do you have an easy-to-maintain organizational chart to understand your human resources?",
      BBlock: "কর্মচারি",
      BSubBlock: "সংস্থার কাঠামো এবং এইচআর প্রক্রিয়া",
      BQuestion:
        "আপনার মানব সম্পদ বোঝার জন্য আপনার কাছে সহজে রক্ষণাবেক্ষণ করা সাংগঠনিক চার্ট আছে?",
      SBlock: "GENTE",
      SSubBlock: "Estructura organizativa y proceso de recursos humanos",
      SQuestion:
        " ¿Tienes un organigrama fácil de mantener para entender tus recursos humanos? ",
    },
    {
      Block: "PEOPLE",
      SubBlock: "Role, Responsibilities & Accountabilities",
      Question:
        "Do you have the roles, responsibilities, and expectations clearly defined and updated?",
      BBlock: "কর্মচারি",
      BSubBlock: "ভূমিকা, দায়িত্ব এবং জবাবদিহিতা",
      BQuestion:
        "ভূমিকা, দায়িত্ব, এবং প্রত্যাশাগুলো কি স্পষ্টভাবে নির্ধারিত এবং আপডেট করা আছে?",
      SBlock: "GENTE",
      SSubBlock: "Rol, Responsabilidades y Responsabilidades",
      SQuestion:
        "¿Tienes los roles, responsabilidades y expectativas claramente definidos y actualizados?",
    },
    {
      Block: "PEOPLE",
      SubBlock: "Right People, Right Seat",
      Question:
        "Do you perform a skill gap analysis regularly? (At least once a year.)",
  
      BBlock: "কর্মচারি",
      BSubBlock: "সঠিক কর্মচারি, সঠিক আসন",
      BQuestion:
        "আপনি কি নিয়মিত দক্ষতার ব্যবধান বিশ্লেষণ করেন? (কমপক্ষে বছরে একবার)",
      SBlock: "GENTE",
      SSubBlock: "Personas adecuadas, asiento adecuado",
      SQuestion:
        "¿Realizas un análisis de brechas de habilidades de manera regular? Al menos una vez al año.",
    },
    {
      Block: "PEOPLE",
      SubBlock: "Right People, Right Seat",
      Question:
        "Do you have an effective leadership/management team that understands and recognizes the individual’s and the team’s strengths and weaknesses?",
      BBlock: "কর্মচারি",
      BSubBlock: "সঠিক কর্মচারি, সঠিক আসন",
      BQuestion:
        "আপনার কি এমন একটি কার্যকর নেতৃত্ব/পরিচালনা দল আছে যারা ব্যক্তি এবং দলের শক্তি ও দুর্বলতা বোঝে এবং স্বীকৃতি দেয়?",
      SBlock: "GENTE",
      SSubBlock: "Personas adecuadas, asiento adecuado",
      SQuestion:
        "¿Tienes un equipo de liderazgo/gestión efectivo que entienda y reconozca las fortalezas y debilidades individuales y del equipo?",
    },
    {
      Block: "PEOPLE",
      SubBlock: "Right People, Right Seat",
      Question:
        "Does your leadership team demonstrate openness, honesty, and a high level of trust?",
      BBlock: "কর্মচারি",
      BSubBlock: "সঠিক কর্মচারি, সঠিক আসন",
      BQuestion:
        "আপনার নেতৃত্ব দল কি খোলামেলা, সততা প্রদর্শন করে এবং উচ্চ স্তরের বিশ্বাস রাখে?",
      SBlock: "GENTE",
      SSubBlock: "Personas adecuadas, asiento adecuado",
      SQuestion:
        "¿Demuestra tu equipo de liderazgo apertura, honestidad y un alto nivel de confianza?",
    },
    {
      Block: "OPERATIONAL & TECHNOLOGY",
      SubBlock: "Document Core Process",
      Question: "Do you have a documented hiring and onboarding process?",
      BBlock: "অপারেশনাল ও টেকনোলজি",
      BSubBlock: "ডকুমেন্ট কোর প্রসেস",
      BQuestion: "আপনার কি নিয়োগ এবং অনবোর্ডিং প্রক্রিয়া লিখিত আছে?",
      SBlock: "OPERACIÓN Y TECNOLOGÍA",
      SSubBlock: "Proceso central del documento",
      SQuestion: "¿Tienes un proceso de contratación y adaptación documentado?",
    },
    {
      Block: "OPERATIONAL & TECHNOLOGY",
      SubBlock: "Document Core Process",
      Question:
        "Do you have a documented training process for all key positions?",
      BBlock: "অপারেশনাল ও টেকনোলজি",
      BSubBlock: "ডকুমেন্ট কোর প্রসেস",
      BQuestion:
        "আপনার কি সব গুরুত্বপূর্ণ পদগুলোর জন্য প্রশিক্ষণ প্রক্রিয়া লিখিত আছে?",
      SBlock: "OPERACIÓN Y TECNOLOGÍA",
      SSubBlock: "Proceso central del documento",
      SQuestion:
        "¿Tienes un proceso de capacitación documentado para todos los puestos clave?",
    },
    {
      Block: "PEOPLE",
      SubBlock: "Role, Responsibilities & Accountabilities",
      Question: "Do you have an employee handbook?",
      BBlock: "কর্মচারি",
      BSubBlock: "ভূমিকা, দায়িত্ব এবং জবাবদিহিতা",
      BQuestion: "আপনার কি কর্মচারী হ্যান্ডবুক আছে?",
      SBlock: "GENTE",
      SSubBlock: "Rol, Responsabilidades y Responsabilidades",
      SQuestion: "¿Tienes un manual del empleado?",
    },
    {
      Block: "PEOPLE",
      SubBlock: "Right People, Right Seat",
      Question: "Do you or your team actively learn and grow?",
      BBlock: "কর্মচারি",
      BSubBlock: "সঠিক কর্মচারি, সঠিক আসন",
      BQuestion: "আপনি বা আপনার দল কি সক্রিয়ভাবে শিখেন এবং উন্নতি করেন?",
      SBlock: "GENTE",
      SSubBlock: "Personas adecuadas, asiento adecuado",
      SQuestion: "¿Tú o tu equipo aprenden y crecen activamente?",
    },
    {
      Block: "OPERATIONAL & TECHNOLOGY",
      SubBlock: "Education & Training",
      Question: "Do you have a leadership training/coaching program?",
      BBlock: "অপারেশনাল ও টেকনোলজি",
      BSubBlock: "শিক্ষা ও প্রশিক্ষণ",
      BQuestion: "আপনার কি নেতৃত্ব প্রশিক্ষণ/পরামর্শ / কোচিং প্রোগ্রাম আছে?",
      SBlock: "OPERACIÓN Y TECNOLOGÍA",
      SSubBlock: "Educación y formación",
      SQuestion: "¿Tienes un programa de capacitación/coaching en liderazgo?",
    },
    {
      Block: "OPERATIONAL & TECHNOLOGY",
      SubBlock: "Education & Training",
      Question:
        "Do you have a trusted advisor to whom you can reach out for advice or as a sounding board?",
      BBlock: "অপারেশনাল ও টেকনোলজি",
      BSubBlock: "শিক্ষা ও প্রশিক্ষণ",
      BQuestion:
        "আপনার কি এমন একজন বিশ্বাসযোগ্য উপদেষ্টা আছে যার কাছে আপনি পরামর্শ বা মতামতের জন্য যেতে পারেন?",
      SBlock: "OPERACIÓN Y TECNOLOGÍA",
      SSubBlock: "Educación y formación",
      SQuestion:
        " ¿Tienes un asesor de confianza al que puedas acudir para obtener consejos o como un foro de discusión? ",
    },
    {
      Block: "PEOPLE",
      SubBlock: "Org Structure & HR Process",
      Question:
        "Do you have a compensation review, reward, and communication process?",
      BBlock: "কর্মচারি",
      BSubBlock: "সংস্থার কাঠামো এবং এইচআর প্রক্রিয়া",
      BQuestion:
        "আপনার কি পারিশ্রমিক ক্ষতিপূরণ পর্যালোচনা, পুরস্কার এবং যোগাযোগ প্রক্রিয়া আছে?",
      SBlock: "GENTE",
      SSubBlock: "Estructura organizativa y proceso de recursos humanos",
      SQuestion:
        " ¿Tienes un proceso de revisión de compensación, recompensa y comunicación? ",
    },
    {
      Block: "PEOPLE",
      SubBlock: "Org Structure & HR Process",
      Question: "Do you have a process for getting employee feedback?",
      BBlock: "কর্মচারি",
      BSubBlock: "সংস্থার কাঠামো এবং এইচআর প্রক্রিয়া",
      BQuestion: "কর্মচারীর মতামত সংগ্রহের জন্য কি আপনার কোনো প্রক্রিয়া আছে?",
      SBlock: "GENTE",
      SSubBlock: "Estructura organizativa y proceso de recursos humanos",
      SQuestion:
        "¿Tienes un proceso para obtener retroalimentación de los empleados?",
    },
    {
      Block: "PEOPLE",
      SubBlock: "Role, Responsibilities & Accountabilities",
      Question: "Are you able to delegate work to others?",
      BBlock: "কর্মচারি",
      BSubBlock: "ভূমিকা, দায়িত্ব এবং জবাবদিহিতা",
      BQuestion: "আপনি কি অন্যদেরকে কাজ প্রদান করতে সক্ষম?",
      SBlock: "GENTE",
      SSubBlock: "Rol, Responsabilidades y Responsabilidades",
      SQuestion: "¿Eres capaz de delegar trabajo a otros?",
    },
    {
      Block: "PEOPLE",
      SubBlock: "Role, Responsibilities & Accountabilities",
      Question: "Do you have a cross training process in place?",
      BBlock: "কর্মচারি",
      BSubBlock: "ভূমিকা, দায়িত্ব এবং জবাবদিহিতা",
      BQuestion: "আপনার কি ক্রস ট্রেনিং প্রক্রিয়া আছে?",
      SBlock: "GENTE",
      SSubBlock: "Rol, Responsabilidades y Responsabilidades",
      SQuestion: "¿Tienes un proceso de capacitación cruzada en marcha?",
    },
    {
      Block: "PEOPLE",
      SubBlock: "Org Structure & HR Process",
      Question: "Do you meet with your direct reports regularly?",
      BBlock: "কর্মচারি",
      BSubBlock: "সংস্থার কাঠামো এবং এইচআর প্রক্রিয়া",
      BQuestion: "আপনি কি আপনার সরাসরি রিপোর্টের সাথে নিয়মিত দেখা করেন?",
      SBlock: "GENTE",
      SSubBlock: "Estructura organizativa y proceso de recursos humanos",
      SQuestion: "¿Te reúnes regularmente con tus reportes directos?",
    },
    {
      Block: "PEOPLE",
      SubBlock: "Org Structure & HR Process",
      Question: "Do you take pride on your ability to retain employees?",
      BBlock: "কর্মচারি",
      BSubBlock: "সংস্থার কাঠামো এবং এইচআর প্রক্রিয়া",
      BQuestion: "আপনি কি কর্মচারীদের ধরে রাখার ক্ষমতার উপর গর্বিত?",
      SBlock: "GENTE",
      SSubBlock: "Estructura organizativa y proceso de recursos humanos",
      SQuestion: "¿Te sientes orgulloso de tu capacidad para retener empleados?",
    },
    {
      Block: "PEOPLE",
      SubBlock: "Org Structure & HR Process",
      Question: "Do you succeed at recruiting and onboarding new talent?",
      BBlock: "কর্মচারি",
      BSubBlock: "সংস্থার কাঠামো এবং এইচআর প্রক্রিয়া",
      BQuestion: "আপনি কি নতুন প্রতিভা নিয়োগ এবং অনবোর্ডিং এ সফল?",
      SBlock: "GENTE",
      SSubBlock: "Estructura organizativa y proceso de recursos humanos",
      SQuestion: "¿Tienes éxito en reclutar y adaptar nuevos talentos?",
    },
    {
      Block: "BONUS Q",
      SubBlock: "Internal/External - Resource",
      Question: "Do you have an internal human resources team?",
      BBlock: "বোনাস প্রশ্ন",
      BSubBlock: "ইন্টারনাল/এক্সটারনাল - রিসোর্স",
      BQuestion: "আপনার কি একটি অভ্যন্তরীণ মানব সম্পদ দল আছে?",
      SBlock: "BONIFICACIÓN Q",
      SSubBlock: "Interno/Externo - Recurso",
      SQuestion: "¿Tienes un equipo de recursos humanos interno?",
    },
    {
      Block: "PURPOSE",
      SubBlock: "5-Year Target",
      Question:
        "Do you have an organization chart reflecting your organization in the next five years?",
      BBlock: "উদ্দেশ্য",
      BSubBlock: "৫ বছরের লক্ষ্য",
      BQuestion:
        "আপনার কি আগামী পাঁচ বছরে আপনার প্রতিষ্ঠানের চিত্র তুলে ধরার জন্য একটি সংগঠন চার্ট আছে?",
      SBlock: "OBJETIVO",
      SSubBlock: "Objetivo de 5 años",
      SQuestion:
        "¿Tienes un organigrama que refleje tu organización en los próximos cinco años?",
    },
    {
      Block: "PURPOSE",
      SubBlock: "Mission",
      Question:
        "Do you have a specific and measurable target for your 10-year goals?",
      BBlock: "উদ্দেশ্য",
      BSubBlock: "মিশন",
      BQuestion:
        "আপনার কি ১০ বছরের জন্য একটি নির্দিষ্ট এবং পরিমাপযোগ্য লক্ষ্য আছে?",
      SBlock: "OBJETIVO",
      SSubBlock: "Misión",
      SQuestion: "¿Tiene un objetivo específico y mensurable para 10 años?",
    },
    {
      Block: "FINANCIAL CONTROL & PLANNING",
      SubBlock: "Budgeting & Forecasting",
      Question: "Do you have an annual strategic planning session?",
      BBlock: "আর্থিক নিয়ন্ত্রণ ও পরিকল্পনা",
      BSubBlock: "বাজেটিং এবং পূর্বাভাস",
      BQuestion: "আপনার কি একটি বার্ষিক কৌশলগত পরিকল্পনা সেশন আছে?",
      SBlock: "CONTROL Y PLANIFICACIÓN FINANCIERA",
      SSubBlock: "Presupuesto y previsión",
      SQuestion: " ¿Tiene una sesión de planificación estratégica anual?",
    },
    {
      Block: "PURPOSE",
      SubBlock: "5-Year Target",
      Question:
        "Have you performed a SWOT Analysis (Strengths, Weaknesses, Opportunities, and Threats) for critical areas of your business?",
      BBlock: "উদ্দেশ্য",
      BSubBlock: "৫ বছরের লক্ষ্য",
      BQuestion:
        "আপনি কি আপনার ব্যবসায়ের গুরুত্বপূর্ণ ক্ষেত্রগুলির জন্য একটি SWOT বিশ্লেষণ (শক্তি, দুর্বলতা, সুযোগ এবং হুমকি) করেছেন?",
      SBlock: "OBJETIVO",
      SSubBlock: "Objetivo de 5 años",
      SQuestion:
        "¿Ha realizado un Análisis FODA (Fortalezas, Debilidades, Oportunidades y Amenazas) para áreas críticas de su negocio?",
    },
    {
      Block: "PURPOSE",
      SubBlock: "Mission",
      Question:
        "Do you have up to date and relevant business strategies in place for the future?",
      BBlock: "উদ্দেশ্য",
      BSubBlock: "মিশন",
      BQuestion:
        "আপনার কি ভবিষ্যতের জন্য আপ টু ডেট এবং প্রাসঙ্গিক ব্যবসায়িক কৌশল আছে?",
      SBlock: "OBJETIVO",
      SSubBlock: "Misión",
      SQuestion:
        " ¿Tiene una estrategia comercial actualizada y relevante para el futuro?",
    },
    {
      Block: "PURPOSE",
      SubBlock: "5-Year Target",
      Question: "Do you have a revenue and profit target for 5-year goals?",
      BBlock: "উদ্দেশ্য",
      BSubBlock: "৫ বছরের লক্ষ্য",
      BQuestion:
        "আপনার কি ৫ বছরের লক্ষ্যের জন্য একটি রাজস্ব এবং মুনাফার লক্ষ্য আছে?",
      SBlock: "OBJETIVO",
      SSubBlock: "Objetivo de 5 años",
      SQuestion: "¿Tiene un objetivo de ingresos y ganancias para 5 años?",
    },
    {
      Block: "PURPOSE",
      SubBlock: "5-Year Target",
      Question:
        "Have you identified the measurables for your 5-year targets? (# of customers, # of projects, revenue per employees, # of units, etc.)",
      BBlock: "উদ্দেশ্য",
      BSubBlock: "৫ বছরের লক্ষ্য",
      BQuestion:
        "আপনি কি আপনার ৫বছরের লক্ষ্যের জন্য পরিমাপযোগ্যতা চিহ্নিত করেছেন? (গ্রাহকদের সংখ্যা, প্রকল্পের সংখ্যা, প্রতি কর্মচারী রাজস্ব, ইউনিটের সংখ্যা ইত্যাদি)",
      SBlock: "OBJETIVO",
      SSubBlock: "Objetivo de 5 años",
      SQuestion:
        "¿Ha identificado los mensurables para su objetivo de 5 años? (# de clientes, # de proyectos, ingresos/empleados, # de unidades, etc.)",
    },
    {
      Block: "PURPOSE",
      SubBlock: "5-Year Target",
      Question:
        "Have you outlined your organization's structure, such as employees, technology, product mix, locations, operations scalability, processes, and clients, to reach your organization's 5-year targets?",
      BBlock: "উদ্দেশ্য",
      BSubBlock: "৫ বছরের লক্ষ্য",
      BQuestion:
        "আপনি কি আপনার সংস্থার গঠন বর্ণনা করেছেন, যেমন কর্মচারী, প্রযুক্তি, পণ্য মিশ্রণ, অবস্থান, অপারেশন স্কেলেবিলিটি, প্রক্রিয়া এবং ক্লায়েন্ট, আপনার সংস্থার ৫ বছরের লক্ষ্য অর্জনের জন্য?",
      SBlock: "OBJETIVO",
      SSubBlock: "Objetivo de 5 años",
      SQuestion:
        " ¿Ha delineado la estructura de su organización, como empleados, tecnología, combinación de productos, ubicaciones, escalabilidad de operaciones, procesos y clientes, para alcanzar el objetivo de 5 años de su organización? ",
    },
    {
      Block: "FINANCIAL CONTROL & PLANNING",
      SubBlock: "Budgeting & Forecastin",
      Question:
        "Have you defined SMART goals for your 1-year plan (# of customers, # of projects, revenue per employees, # of units, etc.)? (SMART = Specific, Measurable, Attainable, Relevant, and Timebound)",
      BBlock: "আর্থিক নিয়ন্ত্রণ ও পরিকল্পনা",
      BSubBlock: "বাজেটিং এবং পূর্বাভাস",
      BQuestion:
        "আপনি কি আপনার ১ বছরের পরিকল্পনার জন্য স্মার্ট লক্ষ্য সংজ্ঞায়িত করেছেন? (গ্রাহকদের সংখ্যা, প্রকল্পের সংখ্যা, প্রতি কর্মচারী রাজস্ব, ইউনিটের সংখ্যা ইত্যাদি)? (SMART = নির্দিষ্ট, পরিমাপযোগ্য, অর্জনযোগ্য, প্রাসঙ্গিক এবং সময়বদ্ধ)",
      SBlock: "CONTROL Y PLANIFICACIÓN FINANCIERA",
      SSubBlock: "Presupuesto y previsión",
      SQuestion:
        " ¿Ha definido objetivos SMART para su plan de 1 año (n.° de clientes, n.° de proyectos, ingresos/empleados, n.° de unidades, etc.)? (SMART = Específico, Medible, Alcanzable, Relevante y con Limitación de Tiempo) ",
    },
    {
      Block: "FINANCIAL CONTROL & PLANNING",
      SubBlock: "Budgeting & Forecastin",
      Question: "Do you have the budget to support your 1-year plan?",
      BBlock: "আর্থিক নিয়ন্ত্রণ ও পরিকল্পনা",
      BSubBlock: "বাজেটিং এবং পূর্বাভাস",
      BQuestion: "আপনার কি আপনার ১ বছরের পরিকল্পনা সমর্থন করার জন্য বাজেট আছে?",
      SBlock: "CONTROL Y PLANIFICACIÓN FINANCIERA",
      SSubBlock: "Presupuesto y previsión",
      SQuestion: " ¿Tiene el presupuesto para respaldar su plan de 1 año? ",
    },
    {
      Block: "CASH FLOW",
      SubBlock: "Access to Fund",
      Question:
        "Do you have a backup or contingency plan to minimize loss and damage caused by an unforeseen adverse event?",
      BBlock: "নগদ প্রবাহ",
      BSubBlock: "নগদ অর্থের অ্যাক্সেস",
      BQuestion:
        "আপনার কি একটি ব্যাকআপ বা জরুরি পরিকল্পনা আছে যা একটি অপ্রত্যাশিত প্রতিকূল ঘটনা দ্বারা সৃষ্ট ক্ষতি এবং ক্ষতি কমিয়ে আনে?",
      SBlock: "FLUJO DE FONDOS",
      SSubBlock: "Acceso al fondo",
      SQuestion:
        "¿Tiene un plan de respaldo o de contingencia para minimizar las pérdidas y daños causados ​​por un evento adverso imprevisto?",
    },
    {
      Block: "PURPOSE",
      SubBlock: "Vision",
      Question: "Do you have a succession plan in place?",
      BBlock: "উদ্দেশ্য",
      BSubBlock: "ভিশন",
      BQuestion: "আপনার কি কোন উত্তরাধিকার পরিকল্পনা আছে?",
      SBlock: "OBJETIVO",
      SSubBlock: "Visión",
      SQuestion: " ¿Tiene un plan de sucesión en marcha? ",
    },
    {
      Block: "REPORTING & FEEDBACK",
      SubBlock: "Reports and KPIs",
      Question: "Do you know the key drivers of your business?",
      BBlock: "প্রতিবেদন  ও পর্যালোচনা",
      BSubBlock: "রিপোর্ট এবং কেপিআই",
      BQuestion: "আপনি কি আপনার ব্যবসার মূল চালিকা শক্তি জানেন?",
      SBlock: "INFORMES Y COMENTARIOS",
      SSubBlock: "Informes y KPI",
      SQuestion: "¿Conoce los impulsores clave de su negocio?",
    },
    {
      Block: "REPORTING & FEEDBACK",
      SubBlock: "Reports and KPIs",
      Question:
        "Do you have Key Performance Indicators (KPIs) identified for your critical processes?",
      BBlock: "প্রতিবেদন  ও পর্যালোচনা",
      BSubBlock: "রিপোর্ট এবং কেপিআই",
      BQuestion:
        "আপনার কি আপনার গুরুত্বপূর্ণ প্রক্রিয়াগুলির জন্য নির্দিষ্ট করা কী পারফরম্যান্স ইনডিকেটর (KPI) আছে?",
      SBlock: "INFORMES Y COMENTARIOS",
      SSubBlock: "Informes y KPI",
      SQuestion:
        "¿Tiene indicadores clave de rendimiento (KPI) identificados para su proceso crítico?",
    },
    {
      Block: "REPORTING & FEEDBACK",
      SubBlock: "Reports and KPIs",
      Question: "Do you have timely reporting on those KPIs?",
      BBlock: "প্রতিবেদন  ও পর্যালোচনা",
      BSubBlock: "রিপোর্ট এবং কেপিআই",
      BQuestion: "আপনার কি সেই কেপিআইগুলির উপর সময়মত রিপোর্ট করা হয়?",
      SBlock: "INFORMES Y COMENTARIOS",
      SSubBlock: "Informes y KPI",
      SQuestion: " ¿Tiene informes oportunos sobre esos KPI? ",
    },
    {
      Block: "REPORTING & FEEDBACK",
      SubBlock: "Reports and KPIs",
      Question:
        "Do you have a list of all reports and report distribution lists?",
      BBlock: "প্রতিবেদন  ও পর্যালোচনা",
      BSubBlock: "রিপোর্ট এবং কেপিআই",
      BQuestion: "আপনার কি সব রিপোর্ট এবং রিপোর্ট বিতরণ তালিকা আছে?",
      SBlock: "INFORMES Y COMENTARIOS",
      SSubBlock: "Informes y KPI",
      SQuestion:
        "  ¿Tiene una lista de todos los informes y listas de distribución de informes? ",
    },
    {
      Block: "REPORTING & FEEDBACK",
      SubBlock: "Reports and KPIs",
      Question: "Do you periodically review the usefulness of these reports?",
      BBlock: "প্রতিবেদন  ও পর্যালোচনা",
      BSubBlock: "রিপোর্ট এবং কেপিআই",
      BQuestion: "আপনি কি পর্যায়ক্রমে এই রিপোর্টগুলির উপযোগিতা পর্যালোচনা করেন?",
      SBlock: "INFORMES Y COMENTARIOS",
      SSubBlock: "Informes y KPI",
      SQuestion: " ¿Revisáis periódicamente la utilidad de estos informes? ",
    },
    {
      Block: "REPORTING & FEEDBACK",
      SubBlock: "Meetings",
      Question: "Do you have a list of all recurring meetings?",
      BBlock: "প্রতিবেদন  ও পর্যালোচনা",
      BSubBlock: "মিটিং",
      BQuestion: "আপনার কি সব পুনরাবৃত্ত মিটিংয়ের তালিকা আছে?",
      SBlock: "INFORMES Y COMENTARIOS",
      SSubBlock: "Reuniones",
      SQuestion: "¿Tiene una lista de todas las reuniones recurrentes?",
    },
    {
      Block: "REPORTING & FEEDBACK",
      SubBlock: "Meetings",
      Question: "Do you follow the best practices for meetings?",
      BBlock: "প্রতিবেদন  ও পর্যালোচনা",
      BSubBlock: "মিটিং",
      BQuestion: "আপনি কি মিটিংয়ের জন্য সেরা অনুশীলনগুলি অনুসরণ করেন?",
      SBlock: "INFORMES Y COMENTARIOS",
      SSubBlock: "Reuniones",
      SQuestion: "¿Sigues las mejores prácticas para las reuniones?",
    },
    {
      Block: "REPORTING & FEEDBACK",
      SubBlock: "Meetings",
      Question:
        "Do you have meetings to review business performance and direction?",
      BBlock: "প্রতিবেদন  ও পর্যালোচনা",
      BSubBlock: "মিটিং",
      BQuestion:
        "আপনার কি ব্যবসায়িক পারফরম্যান্স এবং দিকনির্দেশ পর্যালোচনা করার জন্য মিটিংয়ের আছে?",
      SBlock: "INFORMES Y COMENTARIOS",
      SSubBlock: "Reuniones",
      SQuestion:
        "¿Tiene una reunión para revisar el desempeño y la dirección del negocio?",
    },
    {
      Block: "REPORTING & FEEDBACK",
      SubBlock: "Meetings",
      Question: "Do you get feedback for each meeting?",
      BBlock: "প্রতিবেদন  ও পর্যালোচনা",
      BSubBlock: "মিটিং",
      BQuestion: "আপনি কি প্রতিটি মিটিংয়ের জন্য প্রতিক্রিয়া পান?",
      SBlock: "INFORMES Y COMENTARIOS",
      SSubBlock: "Reuniones",
      SQuestion: "¿Recibe comentarios sobre cada reunión?",
    },
    {
      Block: "REPORTING & FEEDBACK",
      SubBlock: "Action Items",
      Question: "Do you track action items or to-do lists during the meetings?",
      BBlock: "প্রতিবেদন  ও পর্যালোচনা",
      BSubBlock: "অ্যাকশন আইটেম",
      BQuestion:
        "আপনি কি মিটিংগুলোতে অ্যাকশন আইটেম বা টু-ডু তালিকা ট্র্যাক করেন?",
      SBlock: "INFORMES Y COMENTARIOS",
      SSubBlock: "Elementos de acción",
      SQuestion:
        "¿Hace un seguimiento de los elementos de acción o de las listas de tareas pendientes durante las reuniones?",
    },
    {
      Block: "REPORTING & FEEDBACK",
      SubBlock: "Action Items",
      Question:
        "Do your team members know what to do with the action items and have a clear understanding of them?",
      BBlock: "প্রতিবেদন  ও পর্যালোচনা",
      BSubBlock: "অ্যাকশন আইটেম",
      BQuestion:
        "আপনার টিম সদস্যরা কি অ্যাকশন আইটেমগুলি দিয়ে কী করতে হবে তা জানেন এবং  সেগুলি সম্পর্কে পরিষ্কার ধারণা আছে",
      SBlock: "INFORMES Y COMENTARIOS",
      SSubBlock: "Elementos de acción",
      SQuestion:
        "¿Los miembros de su equipo tienen claro y saben qué hacer con los elementos de acción?",
    },
    {
      Block: "REPORTING & FEEDBACK",
      SubBlock: "Action Items",
      Question:
        "Do you hold people accountable based on the meeting action items?",
      BBlock: "প্রতিবেদন  ও পর্যালোচনা",
      BSubBlock: "অ্যাকশন আইটেম",
      BQuestion:
        "আপনি কি মিটিংয়ের অ্যাকশন আইটেমগুলির ভিত্তিতে মানুষকে জবাবদিহি করেন?",
      SBlock: "INFORMES Y COMENTARIOS",
      SSubBlock: "Elementos de acción",
      SQuestion:
        " ¿Responsabiliza a las personas en función de los puntos de acción de la reunión? ",
    },
    {
      Block: "REPORTING & FEEDBACK",
      SubBlock: "Action Items",
      Question:
        "Do your meeting action items or to-do list follow the SMART goals? (SMART = Specific, Measurable, Attainable, Relevant, and Timebound)",
      BBlock: "প্রতিবেদন  ও পর্যালোচনা",
      BSubBlock: "অ্যাকশন আইটেম",
      BQuestion:
        "আপনার মিটিংয়ের অ্যাকশন আইটেম বা টু-ডু তালিকা কি SMART লক্ষ্য অনুসরণ করে? (SMART = নির্দিষ্ট, পরিমাপযোগ্য, অর্জনযোগ্য, প্রাসঙ্গিক, এবং সময়বদ্ধ)",
      SBlock: "INFORMES Y COMENTARIOS",
      SSubBlock: "Elementos de acción",
      SQuestion:
        "¿Los elementos de acción de su reunión o su lista de tareas pendientes siguen los objetivos SMART? (SMART = Específico, Medible, Alcanzable, Relevante y con Limitación de Tiempo",
    },
    {
      Block: "REPORTING & FEEDBACK",
      SubBlock: "Action Items",
      Question:
        "Do you have a process to send follow-ups for the meeting action items?",
      BBlock: "প্রতিবেদন  ও পর্যালোচনা",
      BSubBlock: "অ্যাকশন আইটেম",
      BQuestion:
        "আপনার কি মিটিংয়ের অ্যাকশন আইটেমগুলির জন্য ফলো-আপগুলি পাঠানোর একটি প্রক্রিয়া আছে?",
      SBlock: "INFORMES Y COMENTARIOS",
      SSubBlock: "Elementos de acción",
      SQuestion:
        "¿Tiene un proceso para enviar seguimientos de los puntos de acción de la reunión?",
    },
    {
      Block: "REPORTING & FEEDBACK",
      SubBlock: "Meetings",
      Question: "Do you solve business issues during the meetings?",
      BBlock: "প্রতিবেদন  ও পর্যালোচনা",
      BSubBlock: "মিটিং",
      BQuestion: "আপনি কি মিটিংয়েরগুলোতে ব্যবসায়িক সমস্যা সমাধান করেন?",
      SBlock: "INFORMES Y COMENTARIOS",
      SSubBlock: "Reuniones",
      SQuestion: " ¿Resuelven cuestiones comerciales durante la reunión? ",
    },
    {
      Block: "REPORTING & FEEDBACK",
      SubBlock: "Reports and KPIs",
      Question: "Are you happy with your reporting process?",
      BBlock: "প্রতিবেদন  ও পর্যালোচনা",
      BSubBlock: "রিপোর্ট এবং কেপিআই",
      BQuestion: "আপনি কি আপনার রিপোর্টিং প্রক্রিয়া নিয়ে খুশি?",
      SBlock: "INFORMES Y COMENTARIOS",
      SSubBlock: "Informes y KPI",
      SQuestion: " ¿Está satisfecho con su proceso de presentación de informes? ",
    },
    {
      Block: "REPORTING & FEEDBACK",
      SubBlock: "Meetings",
      Question: "Are you happy with the current meeting process?",
      BBlock: "উদ্দেশ্য",
      BSubBlock: "মূল মূল্যবোধ",
      BQuestion: "আপনি কি বর্তমান মিটিংয়ের প্রক্রিয়া নিয়ে খুশি?",
      SBlock: "INFORMES Y COMENTARIOS",
      SSubBlock: "Reuniones",
      SQuestion: " ¿Está satisfecho con el proceso de reunión actual? ",
    },
    {
      Block: "PURPOSE",
      SubBlock: "Core Value",
      Question:
        "Do you know what your core values are? They should be vital and timeless.",
      BBlock: "উদ্দেশ্য",
      BSubBlock: "মূল মূল্যবোধ",
      BQuestion:
        "আপনি কি জানেন আপনার মূল মূল্যবোধগুলো কী? সেগুলো গুরুত্বপূর্ণ এবং চিরকালীন হওয়া উচিত।",
      SBlock: "OBJETIVO",
      SSubBlock: "Valor fundamental",
      SQuestion:
        " ¿Sabes cuáles son tus valores fundamentales? Deben ser vitales y atemporales. ",
    },
    {
      Block: "PURPOSE",
      SubBlock: "Core Value",
      Question: "Do you know what values are unique to your organization?",
      BBlock: "উদ্দেশ্য",
      BSubBlock: "মূল মূল্যবোধ",
      BQuestion:
        "আপনি কি জানেন কোন মূল্যবোধগুলো আপনার প্রতিষ্ঠানকে বিশেষ করে তোলে?",
      SBlock: "OBJETIVO",
      SSubBlock: "Valor fundamental",
      SQuestion: "¿Sabe qué valores son exclusivos de su organización?",
    },
    {
      Block: "PURPOSE",
      SubBlock: "Core Value",
      Question:
        "Are these values something you can expect your most devoted team members to live by, even when it involves some sacrifice?",
      BBlock: "উদ্দেশ্য",
      BSubBlock: "মূল মূল্যবোধ",
      BQuestion:
        "আপনি কি বিশ্বাস করেন আপনার সবচেয়ে নিবেদিত কর্মীরা এই মূল্যবোধগুলো মেনে চলতে পারে, এমনকি কিছু ত্যাগ স্বীকার করতে হলেও?",
      SBlock: "OBJETIVO",
      SSubBlock: "Valor fundamental",
      SQuestion:
        " ¿Es este valor algo que usted puede esperar que cumplan sus compañeros de trabajo más devotos, incluso cuando implica algún sacrificio? ",
    },
    {
      Block: "PURPOSE",
      SubBlock: "Core Value",
      Question:
        "Do you have your core values written and communicated to all your employees?",
      BBlock: "উদ্দেশ্য",
      BSubBlock: "মূল মূল্যবোধ",
      BQuestion:
        "আপনার মূল মূল্যবোধ কি লেখা আছে এবং আপনার সকল কর্মচারীর কাছে তা যোগাযোগ করেছেন?",
      SBlock: "OBJETIVO",
      SSubBlock: "Valor fundamental",
      SQuestion:
        " ¿Tiene su valor fundamental escrito y comunicado a todos sus empleados? ",
    },
    {
      Block: "PURPOSE",
      SubBlock: "Core Value",
      Question:
        "Do you hire, fire, review, reward, and recognize people based on these core values?",
      BBlock: "উদ্দেশ্য",
      BSubBlock: "মূল মূল্যবোধ",
      BQuestion:
        "আপনি কি আপনার মূল মূল্যবোধের ভিত্তিতে লোক নিয়োগ, বরখাস্ত, পর্যালোচনা, পুরস্কৃত  এবং স্বীকৃতি দেন?",
      SBlock: "OBJETIVO",
      SSubBlock: "Valor fundamental",
      SQuestion:
        " ¿Contrata, despide, revisa, recompensa y reconoce a las personas en función de los valores fundamentales? ",
    },
    {
      Block: "PURPOSE",
      SubBlock: "Vision",
      Question: "Do you have a clear Vision?",
      BBlock: "উদ্দেশ্য",
      BSubBlock: "ভিশন",
      BQuestion: "আপনার কি একটি পরিষ্কার ভিশন/লক্ষ্য আছে?",
      SBlock: "OBJETIVO",
      SSubBlock: "Visión",
      SQuestion: " ¿Tiene una Visión clara? ",
    },
    {
      Block: "PURPOSE",
      SubBlock: "Vision",
      Question: "Do you know what problems you are solving for the greater good?",
      BBlock: "উদ্দেশ্য",
      BSubBlock: "ভিশন",
      BQuestion:
        "আপনি কি জানেন যে আপনি বৃহত্তর ভালোর জন্য কোন সমস্যার সমাধান করছেন?",
      SBlock: "OBJETIVO",
      SSubBlock: "Visión",
      SQuestion: " ¿Sabes qué problema estás resolviendo por un bien mayor? ",
    },
    {
      Block: "PURPOSE",
      SubBlock: "Vision",
      Question:
        "Do you have The Vision written and communicated to all your employees?",
      BBlock: "উদ্দেশ্য",
      BSubBlock: "ভিশন",
      BQuestion:
        "আপনার লক্ষ্য কি লিখিত আকারে আছে এবং এটি কি আপনার সকল কর্মচারীদের শেয়ার করা আছে?",
      SBlock: "OBJETIVO",
      SSubBlock: "Visión",
      SQuestion:
        " ¿Tiene la Visión por escrito y la ha comunicado a todos sus empleados? ",
    },
    {
      Block: "PURPOSE",
      SubBlock: "Vision",
      Question:
        "Do you get the same answer about your Vision from all your employees?",
      BBlock: "উদ্দেশ্য",
      BSubBlock: "ভিশন",
      BQuestion:
        "আপনি কি আপনার সমস্ত কর্মচারীদের কাছ থেকে আপনার লক্ষ্য(ভিশন) সম্পর্কে একই উত্তর পান?",
      SBlock: "OBJETIVO",
      SSubBlock: "Visión",
      SQuestion:
        "¿Recibe la misma respuesta sobre su Visión de parte de todos sus empleados?",
    },
    {
      Block: "PURPOSE",
      SubBlock: "10-Yr Moonshot",
      Question:
        "In 10 years do you know what your organization will look like, if you achieve all your strategic goals?",
      BBlock: "উদ্দেশ্য",
      BSubBlock: "১০ বছরের মুনশট",
      BQuestion:
        "১০ বছর পরে, যদি আপনি আপনার সব কৌশলগত লক্ষ্য অর্জন করেন, তাহলে আপনার প্রতিষ্ঠানটি কেমন দেখাবে কি তা আপনি জানেন?",
      SBlock: "OBJETIVO",
      SSubBlock: "10 años Disparo  la luna",
      SQuestion:
        " ¿Sabe cómo se verá su organización en 10 años si logra todas sus estrategias? ",
    },
    {
      Block: "PURPOSE",
      SubBlock: "10-Yr Moonshot",
      Question:
        "Does your organization get excited, energized, and passionate about the 10 year goals?",
      BBlock: "উদ্দেশ্য",
      BSubBlock: "১০ বছরের মুনশট",
      BQuestion:
        "আপনার প্রতিষ্ঠান কি ১০ বছরের লক্ষ্য নিয়ে উত্তেজিত, উদ্দীপ্ত এবং উৎসাহী হয়?",
      SBlock: "OBJETIVO",
      SSubBlock: "10 años Disparo  la luna",
      SQuestion:
        " ¿Se entusiasma, energiza y apasiona su organización con el objetivo a 10 años? ",
    },
    {
      Block: "PURPOSE",
      SubBlock: "10-Yr Moonshot",
      Question:
        "Do you have a specific and measurable target for your 10 year goals that are larger than life?",
      BBlock: "উদ্দেশ্য",
      BSubBlock: "১০ বছরের মুনশট",
      BQuestion:
        "আপনার কি ১০ বছরের জন্য একটি নির্দিষ্ট এবং পরিমাপযোগ্য লক্ষ্য আছে?",
      SBlock: "OBJETIVO",
      SSubBlock: "10 años Disparo  la luna",
      SQuestion:
        " ¿Tiene un objetivo específico y medible para 10 años que sea más grande que la vida misma? ",
    },
    {
      Block: "PURPOSE",
      SubBlock: "Vision",
      Question: "Do you see your Vision when you close your eyes?",
      BBlock: "উদ্দেশ্য",
      BSubBlock: "ভিশন",
      BQuestion:
        "আপনি যখন আপনার চোখ বন্ধ করেন, তখন কি আপনার ভিশন/লক্ষ্যটি দেখতে পান?",
      SBlock: "OBJETIVO",
      SSubBlock: "Visión",
      SQuestion: "¿Visualiza su Visión cuando cierra los ojos?",
    },
  ];
  
  export default spanishData;
  