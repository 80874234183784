import { useState } from "react";
import React from 'react'
import Typography from "@mui/material/Typography";
export default function Card({q,a,b,c,d,desc,ky,data,setdata}) {
  const updateDataAtIndex = (index, newValue) => {
    const updatedData = data; // Create a copy of the array
    updatedData[index][1] = newValue;  // Update the value at the specific index
    setdata(updatedData);          // Set the updated array as the new state
};
  const [selected, setselected] = useState("")
  return (
    <div className="card" >
    <div>
      <Typography variant="largeB" color="neutral.b800">
       {q}
      </Typography>
    </div>
    <div>
      <Typography variant="small" color="neutral.b200">
{desc}
      </Typography>
    </div>

    <div className="bar"></div>

    <div className="options">
      <div className={"opt "+(selected==="a"?"selected":"")} onClick={()=>{
        updateDataAtIndex(ky,a)
        setselected("a")

      }}>
        <Typography variant="small" color="neutral.b800">
         {a}
        </Typography>
      </div>
      <div className={"opt "+(selected==="b"?"selected":"")} onClick={()=>{
     updateDataAtIndex(ky,b)
        setselected("b")
      }}>
        <Typography variant="small" color="neutral.b800">
        {b}
        </Typography>
      </div>
      <div className={"opt "+(selected==="c"?"selected":"")} onClick={()=>{
        setselected("c")
        updateDataAtIndex(ky,c)
      }}>
        <Typography variant="small" color="neutral.b800">
         {c}
        </Typography>
      </div>
      <div className={"opt "+(selected==="d"?"selected":"")} onClick={()=>{
        setselected("d")
        updateDataAtIndex(ky,d)
      }}>
        <Typography variant="small" color="neutral.b800">
       {d}
        </Typography>
      </div>
    </div>
  </div>
  )
}
