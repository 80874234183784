import React, { useState } from "react";
import { Button } from "@carbon/react";
import Typography from "@mui/material/Typography";
import banglaData from "./banglaData";
import spanishData from "./spanishData";
export default function Qcard({ data, totalq, stotalq, i, isBangla }) {
  const [answer, setanswer] = useState(data[1]);
  let tra = localStorage.getItem("lang");
  async function handleAnswer(ans) {
    let dsa = totalq;
    dsa[i][1] = ans;
    stotalq(dsa);
    setanswer(ans);
  }
  function findElementByQuestion(question) {
    let x = [];
    let tr = localStorage.getItem("lang");
    console.log(tr)
    if (tr == "bangla") {
      x = banglaData.find((item) => item.Question === question);
      return x.BQuestion;
    }
    if (tr == "spanish") {
      x = spanishData.find((item) => item.Question === question);
      return x.SQuestion;
    }

   
  }

  return (
    <div className={answer == null ? "assMentQcard Qcards" : "assMentQcard"}>
      <div className="ques">
        {isBangla ? (
          <Typography variant="large" color="neutral.b300">
            {i + 1 + ". "}
            {findElementByQuestion(data[0])}
          </Typography>
        ) : (
          <Typography variant="large" color="neutral.b300">
            {i + 1 + ". "}
            {data[0]}
          </Typography>
        )}
      </div>
      <div className="bdr"></div>
      <div className="qsbtn">
        <div className="qsbt">
          <Button
            kind="primary"
            size="md"
            className={"btnss" + (answer === "yes" ? " selected" : "")}
            onClick={() => handleAnswer("yes")}
          >
         {tra=="bangla"&&isBangla ?  "হ্যাঁ" : ""}   
         {tra=="spanish"&&isBangla ?  "Sí" : ""}   
         {isBangla ?  "" : "Yes"}
          </Button>
        </div>
        <div className="qsbt">
          <Button
            kind="primary"
            size="md"
            className={"btnss" + (answer === "no" ? " selected" : "")}
            onClick={() => handleAnswer("no")}
          >
            {tra=="bangla"&&isBangla ?  "না" : ""}
            {tra=="spanish"&&isBangla ?  "No" : ""}
            {isBangla ?  "" : "No"}
          </Button>
        </div>
        <div className="qsbt">
          <Button
            kind="primary"
            size="md"
            className={"btnss" + (answer === "unsure" ? " selected" : "")}
            onClick={() => handleAnswer("unsure")}
          >
            {tra=="bangla"&&isBangla ?  "অনিশ্চিত" : ""}
            {tra=="spanish"&&isBangla ?  "No estoy seguro" : ""}
            {isBangla ?  "" : "Unsure"}
          </Button>
        </div>
      </div>
    </div>
  );
}
