import React, { useContext, useEffect, useState } from "react";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import { TextInput, Select, SelectItem, Checkbox, Button } from "@carbon/react";
import { yupResolver } from "@hookform/resolvers/yup";
import ButtonGL from "../../Components/reUse/buttonGl";
import ButtonFB from "../../Components/reUse/buttonFb";
import { useForm } from "react-hook-form";
import { signUp_schema } from "../../Components/signup/signschema";
import apiL from "../../api/apiList";
import { useNavigate } from "react-router-dom";
import {UserContext} from '../../userContext'
import { Visibility, VisibilityOff } from "@mui/icons-material";
export default function SignUp() {
  const navigate = useNavigate();
  const {user, setUser} = useContext(UserContext)
  const id= localStorage.getItem("id");
  const [error, seterror] = useState("")
  const [success, setsuccess] = useState("")
  const [btnSub, setbtnSub] = useState(false)
  const [CountryList, setCountryList] = useState([]);
  const {
    register,
    handleSubmit,
    watch,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(signUp_schema),
  });
  const [showPassword, setShowPassword] = useState(false); // State to manage password visibility

  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };
  const onSubmit = async (data) => {
    setbtnSub(true)
    var myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");
    var datas = JSON.stringify({
      jsonrpc: "2.0",
      params: {
        email: data.email,
        login: data.email,
        password: data.password,
        name: data.name,
        is_marketing: data.marketing_email,
        country_id: data.country_id,
      },
    });
    var requestOptions = {
      method: "POST",
      headers: myHeaders,
      credentials: "include",
      body: datas,
      redirect: "follow",
    };

    fetch(apiL.signup, requestOptions)
      .then(async (response) => {
        let dta = await response.json();
        if (dta.result == "Success") {
         fetch(`${process.env.REACT_APP_SERVER_PANORAMA}/email/signup`, {
            method: "POST",
            headers: { "Content-Type": "application/json" },
            body: JSON.stringify({
              email: data.email,
              name: data.name,
              server: process.env.REACT_APP_SERVER
            }),
          });
          setsuccess("We’ve sent you an email. Please verify your account by signing in using the link.")
          seterror("")//added this line new -farhan
        } else {
          seterror(dta.result.message);
          setsuccess("") //added this line new -farhan
          setbtnSub(false)
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };
  // 
  // 
  // 
  async function getCountry() {
    var myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");
    var raw = JSON.stringify({});
    var requestOptions = {
      method: "POST",
      credentials: "include",
      headers: {
        "Content-Type": "application/json",
      },
      body: raw,
      redirect: "follow",
    };
    fetch(apiL.getCountry, requestOptions)
      .then(async (response) => {
        let dsa = await response.json();
        setCountryList(dsa.result);
      })
      .catch((error) => console.log("error", error));
  }
  async function getProfile(){


    var myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");
    var raw = JSON.stringify({});
    var requestOptions = {
      method: "POST",
      credentials: "include",
      headers: {
        "Content-Type": "application/json",
      },
      body: raw,
      redirect: "follow",
    };
    fetch(apiL.getProfile+id, requestOptions)
      .then(async (response) => {
        let dsa = await response.json();
        if (dsa.result.is_onboarded == true) {
          async function getProfile(id){
             var myHeaders = new Headers();
             myHeaders.append("Content-Type", "application/json");
             var raw = JSON.stringify({});
             var requestOptions = {
               method: "POST",
               credentials: "include",
               headers: {
                 "Content-Type": "application/json",
               },
               body: raw,
               redirect: "follow",
             };
             fetch(apiL.getProfile+id, requestOptions)
               .then(async (response) => {
                 let ds = await response.json();
                 let sk=ds.result?.personal_info?.image_type
          
                 if (sk==false){
                  localStorage.setItem("image",(ds.result.personal_info.photo))
                 }
                 else{
                  localStorage.setItem("image",(sk+ds.result.personal_info.photo))
                 }
    
               })
               .catch((error) => console.log("error", error));
           }
           getProfile(dsa.result.profile_id)
          navigate("/portal/index");
        } else {
          if(dsa.result.is_onboarded.invited_by ==false){
            navigate("/onboard/?phase=2");
          }
          else{
            navigate("/onboard/?phase=0");
          }

        }
      })
      .catch((error) => console.log("error", error));
  }
  useEffect(() => {
    getCountry();
    getProfile()
  }, []);

  return (
    <div style={{ minHeight: "100vh", minWidth: "100%" }}>
      <Grid container>
        <Grid item xl={5} lg={5} md={12} className="bg">
          <div className="pd">
            <div className="pdt">
            <img src="/Your paragraph text (700 x 1000 px).webp" />
            </div>
          </div>
        </Grid>
        <Grid item xl={7} lg={7} md={12}>
          <div className="cont">
            <img src="/logoFull.svg" alt="image" width={180} height={120} />
            <div className="form">
              <Typography variant="base" color="initial">
               Let&apos;s get started
              </Typography>
              <Typography variant="h4" color="neutral.b600">
                Sign up for Panorama
              </Typography>
              <Typography variant="base" color="initial" style={{marginTop:"10px",display:"block"}}>
              Already have an account? <span style={{color:'#0065FF',cursor:"pointer"}}onClick={()=>navigate("/signin")}>Sign in</span>.
                  </Typography>
            </div>
            <form onSubmit={handleSubmit(onSubmit)}>
              <div className="cont1">
              <div className="txtc">
                  <TextInput
                    className="txtF"
                    id="text-input-1"
                    type="text"
                    size="lg"
                    labelText="Full Name*"
                    placeholder="Full Name"
                    invalid={errors.name ? true : false}
                    invalidText={errors.name?.message}
                    {...register("name", { required: true })}
                  />
                </div>
                <div className="txtc">
                  <TextInput
                    className="txtF"
                    size="lg"
                    id="text-input-1"
                    type="text"
                    labelText="Email*"
                    placeholder="Email"
                    invalid={errors.email ? true : false}
                    invalidText={errors.email?.message}
                    {...register("email", { required: true })}
                  />
                </div>

                <div className="txtc">
                  <Select
                    id="select-1"
                    size="lg"
                    defaultValue="placeholder-item"
                    labelText="Country*"
                    invalid={errors.country_id ? true : false}
                    invalidText={errors.country_id?.message}
                    {...register("country_id", { required: true })}
                  >
                    <SelectItem value="" text="Country" />
                    {CountryList.map((e) => {
                      return <SelectItem value={e.id} text={e.name} />;
                    })}
                  </Select>
                </div>
                <div className="txtc" style={{ position: "relative" }}>
      <TextInput
        className="txtF"
        id="text-input-1"
        size="lg"
        type={showPassword ? "text" : "password"} // Toggle between text and password
        labelText="Password*"
        placeholder="Password"
        invalid={errors.password ? true : false}
        invalidText={errors.password?.message}
        {...register("password", { required: true })}
      />
      <Button
        onClick={togglePasswordVisibility}
        style={{
          position: "absolute",
          right: errors.password ? "-31px" : "-53px", 
          top: "44px", 
          transform: "translateY(-50%)",
          background: "none",
          border: "none",
          cursor: "pointer",
           outline: "none", // Prevent default blue border highlight
          boxShadow: "none",
           WebkitTapHighlightColor: "transparent"
        }}
      >
        {showPassword ? (
          <VisibilityOff style={{ color: "gray" }} /> // Icon color set to black
        ) : (
          <Visibility style={{ color: "gray" }} /> // Icon color set to black
        )}
      </Button>
    </div>
                <div className="txtcc">
                  <Checkbox
                    labelText={`I want to receive emails about Panorama, the latest features, updates, upcoming events and promotions.`}
                    id="checkbox-label-1"
                    defaultValue={true}
                    invalid={errors.marketing_email ? true : false}
                    invalidText={errors.marketing_email?.message}
                    {...register("marketing_email", { required: true })}
                  />
                </div>
                <div className="txtcc">
                  <Typography variant="small" color="initial">
                  By continuing, you agree to our {" "}
											<a href="https://panoramamas.com/privacy_policy">
												Privacy Policy
											</a>{" "}
											and{" "}
											<a href="https://panoramamas.com/terms&conditions">
												Terms & Conditions.
											</a>{" "}
											
										</Typography>
                </div>
                <div style={{ display: "flex", paddingTop: "12px" }}>
                  <div style={{ paddingRight: "8px" }}>
                    <Button type="submit" disabled={btnSub}>Create Account</Button>
                  </div>
                  {/* <div style={{ paddingRight: "8px" }}>
                    <ButtonGL />
                  </div>
                  <div style={{ paddingRight: "8px" }}>
                    <ButtonFB />
                  </div> */}
                </div>
                {(success || error) && (
  <div style={{ paddingTop: '20px' }}>
    <Typography variant="smallB" color={error ? "error" : "#22bb33"}>
      {error || success}
    </Typography>
  </div>
)}
                <div className="txtccc">
                </div>
              </div>
            </form>
          </div>
        </Grid>
      </Grid>
    </div>
  );
}
