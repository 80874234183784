import apiL from "../api/apiList";
async function getProfile() {
  let id = localStorage.getItem("id");
  var myHeaders = new Headers();
  myHeaders.append("Content-Type", "application/json");
  var raw = JSON.stringify({});
  var requestOptions = {
    method: "POST",
    credentials: "include",
    headers: {
      "Content-Type": "application/json",
    },
    body: raw,
    redirect: "follow",
  };
  try {
    const response = await fetch(apiL.getProfile + id, requestOptions);
    const data = await response.json();
    if (data.result.personal_info.user_code === id) {
      return data.result;
    }
  } catch (error) {
    console.log("error", error);
    throw error; // Propagate the error
  }
}

async function getPlan() {
  let id = localStorage.getItem("id");
  var myHeaders = new Headers();
  myHeaders.append("Content-Type", "application/json");

  var raw = JSON.stringify({});

  var requestOptions = {
    method: "POST",
    credentials: "include",
    headers: {
      "Content-Type": "application/json",
    },
    body: raw,

    redirect: "follow",
  };
  try {
    const response = await fetch(apiL.getPlan + id, requestOptions);
    const data = await response.json();
    return data.result.plan;
  } catch (error) {
    console.log("error", error);
    throw error; // Propagate the error
  }
}

function getActivePlan(activePlanCode, allPlans) {
  return allPlans.find((plan) => plan.id === activePlanCode);
}

function getParamActiveValue(params, paramName) {
  const param = params.find((param) => param.name === paramName);
  if (param) {
    return param.active ? "Access Granted" : "No Access Granted";
  } else {
    return "No Access Granted"; // Indicate that the parameter was not found
  }
}

function getTodayDate() {
  const today = new Date();
  const year = today.getFullYear();
  const month = String(today.getMonth() + 1).padStart(2, "0");
  const day = String(today.getDate()).padStart(2, "0");
  return `${year}-${month}-${day}`;
}

function checkExpiration(exp, today) {
  const expirationDate = new Date(exp);
  const currentDate = new Date(today);

  if (expirationDate < currentDate) {
    return "Expired";
  } else {
    return "Not Expired";
  }
}

export async function checkPlan() {
  try {
    const data = await getProfile();
    const plans = await getPlan();
    const activePlan = getActivePlan(data.plan_info.plan_id, plans);
    const attr = activePlan.attributes;
    const x = { ...activePlan, ...data.plan_info };
    return x;
  } catch (error) {
    throw error;
  }
}
export async function checkAccess() {
  const data = await getProfile();
  const plans = await getPlan();
  const activePlan = getActivePlan(data.plan_info.plan_id, plans);
  const attr = activePlan.attributes;

  return attr;
}
export async function checkValidity() {
  const data = await getProfile();
  const x = data.plan_info;
  const exp = x.expiration_date;
  const today = getTodayDate();
  const expirationStatus = checkExpiration(exp, today);
  return expirationStatus;
}
export async function SendEmailFromServerEnterprise(email,desc,name,phone) {
  const myHeaders = new Headers();
  myHeaders.append("Content-Type", "application/json");

  const raw = JSON.stringify({
    email: email,
    name: name,
    phone:phone,
    desc: desc,
  });

  const requestOptions = {
    method: "POST",
    headers: myHeaders,
    body: raw,
    redirect: "follow",
  };

  fetch(`${process.env.REACT_APP_SERVER_PANORAMA}/email/enterprise?id=47`, requestOptions)
    .then((response) => response.text())
    .then((result) => console.log(result))
    .catch((error) => console.error(error));
}
