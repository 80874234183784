import React, { useState, useEffect } from "react";
import Container from "@mui/material/Container";
import Typography from "@mui/material/Typography";
import Grid from "@mui/material/Grid";
import {  Link, Modal, Tag,Toggle } from "@carbon/react";
import { useNavigate } from "react-router-dom";
import { Menu, MenuItem, Button } from '@mui/material';
export default function AssesmentHeader({isBangla,setisBangla}) {
  const navigate = useNavigate();
  const [modal, setmodal] = useState(false);
  const selectedlang= localStorage.getItem("lang")
  const [anchorEl, setAnchorEl] = useState(null);
  const handleOnClick = () => {
    const videoUrl = 'https://youtu.be/hE6lKEdQjow?si=goJ_N_woiTW2qI_N&t=106';
    window.open(videoUrl, "_blank");
  };
  useEffect(() => {
    // Automatically set `isBangla` based on `selectedLang` value on component mount
    if (selectedlang === "bangla" ) {
      setisBangla(true);
    }else if (selectedlang === "spanish") {
      setisBangla(true);
    } 
    else {
      setisBangla(false);
    }
  }, [selectedlang, setisBangla]);
  const handleOnClick2 = () => {
    const videoUrl = 'https://youtu.be/p4a0RpEUn7o?si=8C3r1xn2HXg7csnU&t=113';
    window.open(videoUrl, "_blank");
  };
  const handleOnClick3 = () => {
    const videoUrl = 'https://youtu.be/nJNdMB_PReg?si=XHcfduZxcS25p76O&t=127';
    window.open(videoUrl, "_blank");
  };
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
    console.log("click")
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <>
       <Modal
        open={modal}
        className='userAddModal'
        modalHeading="Are you sure you want to return to the dashboard? "
        primaryButtonText="Yes"
        secondaryButtonText="No"
        onRequestClose={() => setmodal(false)}
        onRequestSubmit={() =>  navigate("/portal/index")}
        size="sm"
      >
           <p style={{ marginBottom: "1rem" }}>
        Keep in mind that leaving now will erase the progress you've made in this section.
        </p>
</Modal>
      <div className="assesmentHeader">
        <Container maxWidth="xl">
          <div style={{ display: "flex", justifyContent: "space-between",alignItems:"center" }}>
            <img src="/lgborder.svg" alt="" srcset="" />
            <div className="quick2" style={{ paddingTop: "8px" , paddingRight:'0px' }} >
            <Button onClick={handleClick} variant="contained" color="primary">
        QuickStart
      </Button>
      <Menu
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        onClose={handleClose}
      >
        <MenuItem onClick={handleOnClick}>
        <Typography variant="body1" style={{ width: '100%', textAlign: 'left' }}>
           English
          </Typography>
        </MenuItem>
       
        <MenuItem onClick={handleOnClick3}>
        <Typography variant="body1" style={{ width: '100%', textAlign: 'left' }}>
        Español
          </Typography>
        </MenuItem>
        <MenuItem onClick={handleOnClick2}>
        <Typography variant="body1" style={{ width: '100%', textAlign: 'left' }}>
            
বাংলা
          </Typography>
        </MenuItem>
      </Menu>
    </div>
    <div>
{selectedlang=="none"?"":<Toggle labelA="English" labelB={selectedlang=="bangla"?"Bangla":"Español"}  id="toggle-1" toggled={isBangla} onClick={()=>{
  if (isBangla==true){
    setisBangla(false)
  }
  else{
    setisBangla(true)
  }
}} />}
</div>
            <div
              className="icn usa"
              onClick={() => setmodal(true)}
              style={{ cursor: "pointer", paddingTop: "8px" }}
            >
              <div className="assesmentUser">
                <div>
                  <svg
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M5 12H19"
                      stroke="#0065FF"
                      stroke-width="2"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                    <path
                      d="M5 12L11 18"
                      stroke="#0065FF"
                      stroke-width="2"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                    <path
                      d="M5 12L11 6"
                      stroke="#0065FF"
                      stroke-width="2"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                  </svg>
                </div>

                <div style={{ paddingTop: "2px", paddingBottom: "4px" }}>
                  <Typography variant="small" color="neutral.b800">
                    Back to Dashboard
                  </Typography>
                </div>
              </div>
            </div>
          </div>
        </Container>
      </div>
    </>
  );
}