import Typography from "@mui/material/Typography";
import { Button, Modal } from "@carbon/react";
import Grid from "@mui/material/Grid";
import React, { useContext, useEffect, useState } from "react";
import apiL from "../../api/apiList";
import { UserContext } from "../../userContext";
import { useNavigate } from "react-router-dom";
import {
  Tabs,
  TabList,
  Tab,
  TabPanels,
  TabPanel,
  TextInput,
  Select,
  SelectItem,
  Checkbox,
  Tag,
  Table,
  TableHead,
  TableRow,
  TableHeader,
  TableBody,
  TableCell,
} from "@carbon/react";
import Layout from "../../Components/Portal/layout";
import { checkPlan } from "../../Components/planFunctions";
import LoadingScreen from "../../loading";
export default function Users({ checkPlan, checkValidity }) {
  const [loadingBar, setloadingBar] = useState(true);
  const [rows, setrows] = useState([]);
  const [Plan, setPlan] = useState(null);
  const headers = ["Email", "Name", "Action"];
  const navigate = useNavigate();
  const { user, setUser } = useContext(UserContext);
  const [emailInput, setemailInput] = useState("");
  const [nameInput, setnameInput] = useState("");
  const [user_remain, setuser_remain] = useState(0);
  const [modalAddUser, setmodalAddUser] = useState(false);
  const [errors, seterrors] = useState(null);
  const [logOutSes, setlogOutSes] = useState(false);
  const [modalP, setmodalP] = useState(false);
  const [modalDid, setmodalDid] = useState({});
  let onb = localStorage.getItem("onboard");
  function generatePassword() {
    const length = 8; // Random length between 8 and 32
    const upperCaseChars = "ABCDEFGHIJKLMNOPQRSTUVWXYZ";
    const lowerCaseChars = "abcdefghijklmnopqrstuvwxyz";
    const numberChars = "0123456789";
    const specialChars = "!@#&?";

    let allChars = upperCaseChars + lowerCaseChars + numberChars + specialChars;

    // Ensure the password contains at least one character from each required set
    let password = [
      upperCaseChars[Math.floor(Math.random() * upperCaseChars.length)],
      lowerCaseChars[Math.floor(Math.random() * lowerCaseChars.length)],
      numberChars[Math.floor(Math.random() * numberChars.length)],
      specialChars[Math.floor(Math.random() * specialChars.length)],
    ];

    // Fill the rest of the password length with random characters from all sets
    for (let i = password.length; i < length; i++) {
      password.push(allChars[Math.floor(Math.random() * allChars.length)]);
    }

    // Shuffle the password to ensure the characters are randomly distributed
    password = password.sort(() => Math.random() - 0.5).join("");

    return password;
  }
  async function init() {
    let x = await checkPlan();
    setPlan(x);
  }
  function objectToString(obj) {
    let result = "";
    for (const [key, value] of Object.entries(obj)) {
      result += `${key}: ${value}<br/>`; // Appending each key-value pair with a colon and a line break
    }
    return result;
  }
  const Logout = () => {
    var myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");

    var raw = JSON.stringify({});

    var requestOptions = {
      method: "POST",
      credentials: "include",
      headers: {
        "Content-Type": "application/json",
      },
      body: raw,

      redirect: "follow",
    };
    fetch(apiL.logout, requestOptions)
      .then(async (response) => {
        let dsa = await response.json();
        setlogOutSes(false);
        navigate("/signin");
        const keysToRemove = [
          "vd",
          "id",
          "pid",
          "uid",
          "image",
          "onboard",
          "name",
          "invited",
        ];

        // Iterate over each key and remove the item from localStorage
        keysToRemove.forEach((key) => {
          localStorage.removeItem(key);
        });
      })
      .catch((error) => console.log("error", error));
  };
  if (onb === "false") {
    Logout();
    navigate("/signin");
  }

  async function addUser(name, email) {
    var myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");
    let password = generatePassword();
    var raw = JSON.stringify({ email: email, password: password, name: name });

    var requestOptions = {
      method: "POST",
      credentials: "include",
      headers: {
        "Content-Type": "application/json",
      },
      body: raw,
      redirect: "follow",
    };

    fetch(apiL.addUser + user.id, requestOptions)
      .then(async (response) => {
        let dsa = await response.json();
        if (dsa.result.user_id) {
          const myHeaders = new Headers();
          myHeaders.append("Content-Type", "application/json");
          const raw = JSON.stringify({
            email: email,
            name: name,
            password: password,
          });

          const requestOptions = {
            method: "POST",
            headers: myHeaders,
            body: raw,
            redirect: "follow",
          };

          fetch(`${process.env.REACT_APP_SERVER_PANORAMA}/email/invite`, requestOptions)
            .then((response) => response.text())
            .then((result) => {
            })
            .catch((error) => console.error(error));

          getUserList();
          setmodalAddUser(false);
        } else if (dsa.error && dsa.error.message == "Odoo Session Expired") {
          navigate("/signin");
        } else {
          seterrors(dsa.result);
        }
      })
      .catch((error) => console.log("error", error));
  }
  async function getUserList() {
    var myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");

    var raw = JSON.stringify({});

    var requestOptions = {
      method: "POST",
      credentials: "include",
      headers: {
        "Content-Type": "application/json",
      },
      body: raw,

      redirect: "follow",
    };
    let id = localStorage.getItem("id");
    fetch(apiL.userList + user.uid, requestOptions)
      .then(async (response) => {
        let dsa = await response.json();
        if (dsa.result === "User not found") {
          setrows([]);
        } else if (dsa.error && dsa.error.message == "Odoo Session Expired") {
          navigate("/signin");
        } else {
          setrows(dsa?.result || []);
        }
        // setrows(dsa?.result||[]);
      })
      .catch((error) => console.log("error", error));
    fetch(apiL.remainUser + user.uid, requestOptions)
      .then(async (response) => {
        let dsa = await response.json();
        if (
          dsa.result &&
          dsa.result.message == "This user has no consultant."
        ) {
          //No user handle
        } else if (dsa.error && dsa.error.message == "Odoo Session Expired") {
          navigate("/signin");
        }
        setuser_remain(dsa.result.user_remain);
      })
      .catch((error) => console.log("error", error));
  }
  async function DeleteUser() {
    var myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");

    var raw = JSON.stringify({
      subject: "User Deactivation Request ",
      desc: objectToString(modalDid),
    });

    var requestOptions = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: raw,

      redirect: "follow",
    };
    fetch(`${process.env.REACT_APP_SERVER_PANORAMA}/email/contactus`, requestOptions)
      .then(async (response) => {
        setmodalP(false);
      })
      .catch((error) => console.log("error", error));
  }
  useEffect(() => {
    checkPlan();
    init();
    getUserList();
    setTimeout(() => {
      setloadingBar(false);
    }, 2000);
  }, []);
  return (
    <>
      <Layout logOutSes={logOutSes} setlogOutSes={setlogOutSes} />
      {loadingBar ? (
        <LoadingScreen />
      ) : (
        <>
          <Modal
  danger
  open={modalP}
  className="userAddModal"
  modalHeading="Deactivate User"
  primaryButtonText="OK"
  secondaryButtonText="Cancel"
  onRequestClose={() => {
    setmodalP(false);
  }}
  onRequestSubmit={() => {
    DeleteUser();
  }}
  size="xs"
>
  <p style={{ marginBottom: "1rem" }}>
    You are about to request deactivation of the user
  </p>
</Modal>

          <Modal
            open={logOutSes}
            className="userAddModal"
            modalHeading="You will be logged out"
            primaryButtonText="Logout"
            secondaryButtonText="Cancel"
            onRequestClose={() => setlogOutSes(false)}
            onRequestSubmit={() => Logout()}
            size="sm"
          >
            <p style={{ marginBottom: "1rem" }}>
              Are you sure you want to log out of your Panorama account?
            </p>
          </Modal>
          <div className="innerPortal bg-grey-plan">
            <Modal
              open={modalAddUser}
              className="userAddModal"
              modalHeading="Add User"
              primaryButtonText="Send"
              secondaryButtonText="Cancel"
              onRequestClose={() => setmodalAddUser(false)}
              onRequestSubmit={() => addUser(nameInput, emailInput)}
              size="sm"
            >
              <p style={{ marginBottom: "1rem" }}>
                We recommend adding their work or company email.
              </p>
              <TextInput
                data-modal-primary-focus
                id="text-input-1"
                labelText="Name"
                placeholder="John Doe"
                style={{ marginBottom: "1rem" }}
                value={nameInput}
                onChange={(e) => setnameInput(e.target.value)}
              />
              <TextInput
                data-modal-primary-focus
                id="text-input-1"
                labelText="Email"
                placeholder="yourname@domain.com"
                style={{ marginBottom: "1rem" }}
                value={emailInput}
                onChange={(e) => setemailInput(e.target.value)}
              />
              {errors == null ? (
                <div></div>
              ) : (
                <p style={{ marginBottom: "1rem", color: "red" }}>{errors}</p>
              )}
            </Modal>
            <div className="cardFullDashboard">
              <Grid container spacing={0}>
                <Grid sm={12} md={12} lg={6} xl={6}>
                  <div className="txtCardFullDash">
                    <div>
                      <Typography variant="h4B" color="neutral.b800">
                        Users
                      </Typography>
                    </div>
                    <div>
                      <Typography variant="small" color="neutral.b800">
                        Add members from your team to get comprehensive reports
                        of your company's management and organizational
                        alignment.
                      </Typography>
                    </div>
                    <Button
                      kind="primary"
                      size="lg"
                      onClick={() => setmodalAddUser(true)}
                      disabled={Plan === null}
                    >
                      Add User
                    </Button>
                  </div>
                </Grid>
                <Grid sm={12} md={12} lg={6} xl={6}>
                  <div className="plan-txt2">
                    <div>
                      <Typography variant="small" color="neutral.b80">
                        Your Package
                      </Typography>
                      <br />
                      <Typography variant="largeB" color="primary.b300">
                        {Plan == null ? "No active plan" : Plan.plan_name}
                      </Typography>
                    </div>
                    <div className="plancta">
                      <div className="planctaCard">
                        <Typography variant="small" color="neutral.b80">
                          Users Remaining
                        </Typography>
                        <br />
                        <Typography variant="largeB" color="neutral.b400">
                          {user_remain}
                        </Typography>
                      </div>
                      <div className="planctaCard">
                        <Typography variant="small" color="neutral.b80">
                          Users Added
                        </Typography>
                        <br />
                        <Typography variant="largeB" color="neutral.b400">
                          {rows.length + 1}
                        </Typography>
                      </div>
                      {/* <div className="planctaCard">
                  <Typography variant="small" color="neutral.b80">
                    Total Users
                  </Typography>
                  <br />
                  <Typography variant="largeB" color="neutral.b400">
                    5 Users
                  </Typography>
                </div> */}
                    </div>
                  </div>
                </Grid>
              </Grid>
            </div>
            {rows.length > 0 ? (
              <div style={{ paddingBottom: "80px" }}>
                <Table size="lg" useZebraStyles={false}>
                  <TableHead>
                    <TableRow>
                      {headers?.map((header) => (
                        <TableHeader id={header.key} key={header}>
                          {header}
                        </TableHeader>
                      ))}
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {rows?.length > 0
                      ? rows?.map((row) => (
                          <TableRow key={row.id}>
                            <TableCell key={row.login}>{row.login}</TableCell>
                            <TableCell key={row.name}>{row.name}</TableCell>
                            <TableCell>
                              <div style={{ display: "flex" }}>
                                {/* <div className="tablebtnspace">
                    <Button
                      kind="secondary"
                      size="sm"
                      className="btnss"
                      // onClick={() => handleNext()}
                    >
                      View Details
                    </Button>
                  </div> */}
                                <div className="tablebtnspace">
                                  <Button
                                    kind="primary"
                                    size="sm"
                                    className="btnss"
                                    onClick={() => {
                                      setmodalP(true);
                                      setmodalDid(row);
                                    }}
                                  >
                                    Deactivate
                                  </Button>
                                </div>
                              </div>
                            </TableCell>
                          </TableRow>
                        ))
                      : ""}
                  </TableBody>
                </Table>
              </div>
            ) : (
              ""
            )}
          </div>
        </>
      )}
    </>
  );
}
