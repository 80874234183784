import React from "react";
import Typography from "@mui/material/Typography";
import { useSearchParams } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import { useState } from "react";
import { useLocation } from "react-router-dom";

export default function CompareNav({ assesID, modal,das,setdas ,isBangla,setisBangla,setOpenmenu,compType,sessionID}) {
  const navigate = useNavigate();
  const { pathname } = useLocation();
  const pid = localStorage.getItem("pid");
  const invited = localStorage.getItem("invited");
  let vd = localStorage.getItem("vd");
  let [searchParams] = useSearchParams();
  let id = searchParams.get("code");
  return (
    <div className="serve">
      <div
        className={
          das=="BUSINESS"
            ? "ser serActive"
            : "ser"
        }
        onClick={()=>{setdas("BUSINESS")
        setOpenmenu(false)
        window.scrollTo({top: 0, left: 0, behavior: 'smooth'})}}
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
        }}
      >
        <div>
          <Typography variant="small" color="b800">
            Overview
          </Typography>
        </div>
      </div>
      <div
        className={
          das=="PURPOSE"
            ? "ser serActive"
            : "ser"
        }
        onClick={()=>{setdas("PURPOSE")
        setOpenmenu(false)
        window.scrollTo({top: 0, left: 0, behavior: 'smooth'})}}
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
        }}
      >
        <div>
          <Typography variant="small" color="b800">
            Purpose
          </Typography>
        </div>
      </div>
      <div
        className={
          das=="PEOPLE"
            ? "ser serActive"
            : "ser"
        }
        onClick={()=>{setdas("PEOPLE")
        setOpenmenu(false)
        window.scrollTo({top: 0, left: 0, behavior: 'smooth'})}}
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
        }}
      >
        <div>
          <Typography variant="small" color="b800">
            People
          </Typography>
        </div>
      </div>
      <div
        className={
          das=="CUSTOMER"
            ? "ser serActive"
            : "ser"
        }
        onClick={()=>{setdas("CUSTOMER")
        setOpenmenu(false)
        window.scrollTo({top: 0, left: 0, behavior: 'smooth'})}}
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
        }}
      >
        <div>
          <Typography variant="small" color="b800">
            Customer
          </Typography>
        </div>
      </div>
      <div
        className={
          das=="MARKETING"
            ? "ser serActive"
            : "ser"
        }
        onClick={()=>{setdas("MARKETING")
        setOpenmenu(false)
        window.scrollTo({top: 0, left: 0, behavior: 'smooth'})}}
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
        }}
      >
        <div>
          <Typography variant="small" color="b800">
            Marketing
          </Typography>
        </div>
      </div>
      <div
        className={
          das=="OPERATIONAL & TECHNOLOGY"
            ? "ser serActive"
            : "ser"
        }
        onClick={()=>{setdas("OPERATIONAL & TECHNOLOGY")
        setOpenmenu(false)
        window.scrollTo({top: 0, left: 0, behavior: 'smooth'})}}
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
        }}
      >
        <div>
          <Typography variant="small" color="b800">
            Operation
          </Typography>
        </div>
      </div>

      <div
        className={
          das=="REPORTING & FEEDBACK"
            ? "ser serActive"
            : "ser"
        }
        onClick={()=>{setdas("REPORTING & FEEDBACK")
        setOpenmenu(false)
        window.scrollTo({top: 0, left: 0, behavior: 'smooth'})}}
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
        }}
      >
        <div>
          <Typography variant="small" color="b800">
            Reports
          </Typography>
        </div>
      </div>

      <div
        className={
          das=="FINANCIAL CONTROL & PLANNING"
            ? "ser serActive"
            : "ser"
        }
        onClick={()=>{setdas("FINANCIAL CONTROL & PLANNING")
        setOpenmenu(false)   
         window.scrollTo({top: 0, left: 0, behavior: 'smooth'})}}
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
        }}
      >
        <div>
          <Typography variant="small" color="b800">
            Financials
          </Typography>
        </div>
      </div>
      <div
        className={
          das=="CASH FLOW"
            ? "ser serActive"
            : "ser"
        }
        onClick={()=>{setdas("CASH FLOW")
        setOpenmenu(false)
        window.scrollTo({top: 0, left: 0, behavior: 'smooth'})}
        }
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
        }}
      >
        <div>
          <Typography variant="small" color="b800">
            CashFlow
          </Typography>
        </div>
      </div>
 {compType.value =="User-Based-Comparison"?     <div
        className={
          das=="Question Analysis"
            ? "ser serActive"
            : "ser"
        }
        onClick={()=>{setdas("Question Analysis")
        setOpenmenu(false)
        window.scrollTo({top: 0, left: 0, behavior: 'smooth'})
        }

        }
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
        }}
      >
        <div>
          <Typography variant="small" color="b800">
          Question Analysis
          </Typography>
        </div>
      </div>:""}
      {compType.value =="User-Based-Comparison"?     <div
        className={
          das=="Team"
            ? "ser serActive"
            : "ser"
        }
        onClick={()=>{setdas("Team")
        setOpenmenu(false)
        window.scrollTo({top: 0, left: 0, behavior: 'smooth'})
        }

        }
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
        }}
      >
        <div>
          <Typography variant="small" color="b800">
          Team Comparison
          </Typography>
        </div>
      </div>:""}
      <div
          className="ser"
          onClick={() =>
            
            navigate("/portal/index")
          
          }
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
          }}
        >
          <div>
            <Typography variant="small" color="b800">
            Back to Dashboard
            </Typography>
          </div>
          <svg
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M14 8V6C14 5.46957 13.7893 4.96086 13.4142 4.58579C13.0391 4.21071 12.5304 4 12 4H5C4.46957 4 3.96086 4.21071 3.58579 4.58579C3.21071 4.96086 3 5.46957 3 6V18C3 18.5304 3.21071 19.0391 3.58579 19.4142C3.96086 19.7893 4.46957 20 5 20H12C12.5304 20 13.0391 19.7893 13.4142 19.4142C13.7893 19.0391 14 18.5304 14 18V16"
              stroke="#0065FF"
              stroke-width="2"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
            <path
              d="M9 12H21L18 9"
              stroke="#0065FF"
              stroke-width="2"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
            <path
              d="M18 15L21 12"
              stroke="#0065FF"
              stroke-width="2"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
          </svg>
        </div>
    </div>
  );
}
