 const banglaData = [
    {
      Block: "CUSTOMER",
      SubBlock: "Who, Where & What are they?",
      Question: "Do you know who your customers are?",
      BBlock: "ক্রেতা",
      BSubBlock: "তারা কারা, কোথায় এবং কি কাজ করছে?",
      BQuestion: "আপনি কি জানেন আপনার গ্রাহক কারা?",
  
    },
    {
      Block: "CUSTOMER",
      SubBlock: "Who, Where & What are they?",
      Question: "Do you know where they are? (The geographic characteristics.)",
      BBlock: "ক্রেতা",
      BSubBlock: "তারা কারা, কোথায় এবং কি কাজ করছে?",
      BQuestion: "আপনি কি জানেন তারা কোথায়? (তাদের ভৌগলিক বৈশিষ্ট্য।)",
  
    },
    {
      Block: "CUSTOMER",
      SubBlock: "Who, Where & What are they?",
      Question:"Do you know what they are?  Their demographic characteristics (B2B = Job title, industry, size, types of business; B2C = age, sex, income, profession, etc.)",
      BBlock: "ক্রেতা",
      BSubBlock: "তারা কারা, কোথায় এবং কি কাজ করছে?",
      BQuestion: "আপনি কি জানেন তারা কি? তাদের জনসংখ্যাগত বৈশিষ্ট্য (B2B = কাজের শিরোনাম, শিল্প, আকার, ব্যবসার ধরন; B2C = বয়স, লিঙ্গ, আয়, পেশা, ইত্যাদি)",
  
    },
    {
      Block: "CUSTOMER",
      SubBlock: "Who, Where & What are they?",
      Question:
        "Do you know (Their psychographic characteristics:How do they think, what do they need, what do they appreciate, hobbies, likes/dislikes, etc.)",
        BBlock: "ক্রেতা",
      BSubBlock: "তারা কারা, কোথায় এবং কি কাজ করছে?",
      BQuestion: "আপনি কি তাদের সাইকোগ্রাফিক বৈশিষ্ট্য জানেন? (তারা কীভাবে চিন্তা করে, তাদের কী দরকার, তারা কী প্রশংসা করে, শখ, পছন্দ/অপছন্দ ইত্যাদি)",
  
    },
    {
      Block: "CUSTOMER",
      SubBlock: "Value Proposition",
      Question:
        "Do you know what you can do to help them realize potential they aren't yet thinking about?",
       BBlock: "ক্রেতা",
      BSubBlock: "মূল্য প্রস্তাব",
      BQuestion: "আপনি কি জানেন যে আপনি তাদের সম্ভাব্যতা উপলব্ধি করতে সাহায্য করতে পারেন যা তারা এখনও চিন্তা করছে না?",
  
    },
    {
      Block: "CUSTOMER",
      SubBlock: "Understanding Pain Points",
      Question: "Do you understand their pain points?",
   BBlock: "ক্রেতা",
      BSubBlock: "সমস্যা/পেইন পয়েন্ট বোঝা",
      BQuestion: "আপনি কি তাদের সমস্যা যা আপনার ব্যবসার সম্ভাব্য গ্রাহকদের 'ব্যথা' সৃষ্টি করছে, বুঝতে পারেন?",
  
    },
    {
      Block: "CUSTOMER",
      SubBlock: "Understanding Pain Points",
      Question: "Do you know what bothers them so much that they lose sleep?",
          BBlock: "ক্রেতা",
      BSubBlock: "সমস্যা/পেইন পয়েন্ট বোঝা",
      BQuestion: "আপনি কি জানেন কি তাদের এতটা বিরক্ত করে যে তারা ঘুম হারিয়ে ফেলে?",
  
    },
    {
      Block: "CUSTOMER",
      SubBlock: "Understanding Pain Points",
      Question:
        "Do you know what is most frustrating for your customer in your industry?",
      BBlock: "ক্রেতা",
      BSubBlock: "সমস্যা/পেইন পয়েন্ট বোঝা",
      BQuestion: "আপনি কি জানেন আপনার শিল্পে আপনার গ্রাহকের জন্য সবচেয়ে হতাশাজনক কি?",
  
    },
    {
      Block: "CUSTOMER",
      SubBlock: "Understanding Pain Points",
      Question:
        "Do you know what your customers are most worried about going wrong?",
  BBlock: "ক্রেতা",
       BSubBlock: "সমস্যা/পেইন পয়েন্ট বোঝা",
      BQuestion: "আপনি কি জানেন যে আপনার গ্রাহকরা ভুল হওয়ার বিষয়ে সবচেয়ে বেশি চিন্তিত?",
  
  
    },
    {
      Block: "CUSTOMER",
      SubBlock: "Understanding Pain Points",
      Question:
        "Do you conduct regular customer feedback regarding your product and services?",
      BBlock: "ক্রেতা",
      BSubBlock: "সমস্যা/পেইন পয়েন্ট বোঝা",
      BQuestion: "আপনি কি আপনার পণ্য এবং পরিষেবা সম্পর্কে নিয়মিত গ্রাহক প্রতিক্রিয়া পরিচালনা করেন?",
  
    },
    {
      Block: "MARKETING",
      SubBlock: "Branding",
      Question: "Do you have customer appreciation initiatives?",
      BBlock: "মার্কেটিং",
      BSubBlock: "ব্র্যান্ডিং",
      BQuestion: "আপনার কি গ্রাহকের প্রশংসা করার উদ্যোগ আছে?",
  
    },
    {
      Block: "MARKETING",
      SubBlock: "Branding",
      Question:
        "Do you know if your customer relates your name or logo with your value proposition?",
          BBlock: "মার্কেটিং",
      BSubBlock: "ব্র্যান্ডিং",
      BQuestion: "আপনি কি জানেন যে আপনার গ্রাহক আপনার মূল্য প্রস্তাবের সাথে আপনার নাম বা লোগো সম্পর্কিত কিনা?",
  
    },
    {
      Block: "MARKETING",
      SubBlock: "Branding",
      Question:
        "Do you participate in trade shows, association memberships, conferences, etc.?",
          BBlock: "মার্কেটিং",
      BSubBlock: "ব্র্যান্ডিং",
      BQuestion: "আপনি কি ট্রেড শো, অ্যাসোসিয়েশন মেম্বারশিপ, কনফারেন্স ইত্যাদিতে অংশগ্রহণ করেন?",
  
    },
    {
      Block: "MARKETING",
      SubBlock: "Ideal Customer",
      Question: "Do you know who your ideal customers are?",
          BBlock: "মার্কেটিং",
      BSubBlock: "আদর্শ গ্রাহক",
      BQuestion: "আপনি কি জানেন আপনার আদর্শ গ্রাহক কারা?",
  
    },
    {
      Block: "MARKETING",
      SubBlock: "Ideal Customer",
      Question: "Do you ask your customer for a referral or introduction?",
          BBlock: "মার্কেটিং",
      BSubBlock: "আদর্শ গ্রাহক",
      BQuestion: "আপনি কি আপনার গ্রাহককে একটি রেফারেল বা ভূমিকার জন্য জিজ্ঞাসা করেন?",
  
    },
    {
      Block: "MARKETING",
      SubBlock: "Ideal Customer",
      Question: "Does your customer usually send you a referral or introduction?",
          BBlock: "মার্কেটিং",
      BSubBlock: "আদর্শ গ্রাহক",
      BQuestion: "আপনার গ্রাহক সাধারণত আপনাকে একটি রেফারেল বা ভূমিকা পাঠায়?",
  
    },
    {
      Block: "MARKETING",
      SubBlock: "Ideal Customer",
      Question: "Does your customer help you learn and expand your knowledge?",
         BBlock: "মার্কেটিং",
      BSubBlock: "আদর্শ গ্রাহক",
      BQuestion: "আপনার গ্রাহক কি আপনাকে আপনার জ্ঞান শিখতে এবং প্রসারিত করতে সাহায্য করে?",
  
    },
    {
      Block: "MARKETING",
      SubBlock: "Ideal Customer",
      Question: "Do you have repeat customers?",
          BBlock: "মার্কেটিং",
      BSubBlock: "আদর্শ গ্রাহক",
      BQuestion: "আপনার কি পুনরাবৃত্তি গ্রাহক আছে?",
  
    },
    {
      Block: "CASH FLOW",
      SubBlock: "Results",
      Question: "Is your business growing?",
      
      BBlock: "নগদ প্রবাহ",
      BSubBlock: "ফলাফল",
      BQuestion: "আপনার ব্যবসা কি বাড়ছে?",
  
    },
    {
      Block: "CUSTOMER",
      SubBlock: "Who, Where & What are they?",
      Question: "Is your customer mix changing?",
       BBlock: "ক্রেতা",
      BSubBlock: "তারা কারা, কোথায় এবং কি কাজ করছে?",
      BQuestion: "আপনার গ্রাহক মিশ্রণ পরিবর্তন হচ্ছে?",
  
    },
    {
      Block: "CUSTOMER",
      SubBlock: "Value Proposition",
      Question:
        "Do you have a unique value proposition to solve your customers’ challenges?",
          BBlock: "ক্রেতা",
      BSubBlock: "মূল্য প্রস্তাব",
      BQuestion: "আপনার গ্রাহকদের চ্যালেঞ্জগুলি সমাধান করার জন্য আপনার কি একটি অনন্য মূল্য প্রস্তাব আছে?",
  
    },
    {
      Block: "CUSTOMER",
      SubBlock: "Value Proposition",
      Question: "Does your value proposition consist of 3 to 5 items?",
          BBlock: "ক্রেতা",
      BSubBlock: "মূল্য প্রস্তাব",
      BQuestion: "আপনার মূল্য প্রস্তাব ৩ থেকে ৫ আইটেম নিয়ে গঠিত?",
  
    },
    {
      Block: "CUSTOMER",
      SubBlock: "Sales Process",
      Question: "Do you have a focused and effective sales function/team?",
          BBlock: "ক্রেতা",
      BSubBlock: "বিক্রয় প্রক্রিয়া",
      BQuestion: "আপনার কি একটি ফোকাসড এবং কার্যকর বিক্রয় ফাংশন/টিম আছে?",
  
    },
    {
      Block: "CUSTOMER",
      SubBlock: "Sales Process",
      Question: "Do you have a clearly defined sales process?",
          BBlock: "ক্রেতা",
      BSubBlock: "বিক্রয় প্রক্রিয়া",
      BQuestion: "আপনার কি একটি পরিষ্কারভাবে সংজ্ঞায়িত বিক্রয় প্রক্রিয়া আছে?",
  
    },
    {
      Block: "OPERATIONAL & TECHNOLOGY",
      SubBlock: "Education & Training",
      Question:
        "Do you have ongoing training and development for your sales team?",
          BBlock: "অপারেশনাল ও টেকনোলজি",
      BSubBlock: "শিক্ষা ও প্রশিক্ষণ",
      BQuestion: "আপনার বিক্রয় দলের জন্য চলমান প্রশিক্ষণ এবং উন্নয়ন আছে?",
  
    },
    {
      Block: "CUSTOMER",
      SubBlock: "Sales Process",
      Question:
        "Do you have a sales strategy for increasing your lead activities?",
          BBlock: "ক্রেতা",
      BSubBlock: "বিক্রয় প্রক্রিয়া",
      BQuestion: "আপনার লিড কার্যক্রম বাড়ানোর জন্য আপনার কি কোনো বিক্রয় কৌশল আছে?",
  
    },
    {
      Block: "CUSTOMER",
      SubBlock: "Sales Process",
      Question: "Do you have a process to monitor your sales activities?",
          BBlock: "ক্রেতা",
      BSubBlock: "বিক্রয় প্রক্রিয়া",
      BQuestion: "আপনার বিক্রয় কার্যক্রম নিরীক্ষণ করার জন্য আপনার কি একটি প্রক্রিয়া আছে?",
  
    },
    {
      Block: "OPERATIONAL & TECHNOLOGY",
      SubBlock: "Technology & Streamline",
      Question:
        "Do you use technology such as CRM to monitor your sales activities?",
          BBlock: "অপারেশনাল ও টেকনোলজি",
      BSubBlock: "প্রযুক্তি এবং স্ট্রীমলাইন",
      BQuestion: "আপনি কি আপনার বিক্রয় কার্যক্রম নিরীক্ষণ করার জন্য প্রযুক্তি ব্যবহার করেন, যেমন CRM ইত্যাদি?",
  
    },
    {
      Block: "CUSTOMER",
      SubBlock: "Sales Process",
      Question: "Do you have a sales forecasting process?",
          BBlock: "ক্রেতা",
      BSubBlock: "বিক্রয় প্রক্রিয়া",
      BQuestion: "আপনার কি একটি বিক্রয় পূর্বাভাস প্রক্রিয়া আছে?",
  
    },
    {
      Block: "CUSTOMER",
      SubBlock: "Sales Process",
      Question:
        "Do you have regular meetings with the sales team to communicate results, activities, and expectations?",
          BBlock: "ক্রেতা",
      BSubBlock: "বিক্রয় প্রক্রিয়া",
      BQuestion: "ফলাফল, ক্রিয়াকলাপ এবং প্রত্যাশাগুলি জানাতে আপনি কি বিক্রয় দলের সাথে নিয়মিত বৈঠক করেন?",
  
    },
    
    {
      Block: "CUSTOMER",
      SubBlock: "Sales Process",
      Question: "Are you happy with your sales process and efforts?",
          BBlock: "ক্রেতা",
      BSubBlock: "বিক্রয় প্রক্রিয়া",
      BQuestion: "আপনি কি আপনার বিক্রয় প্রক্রিয়া এবং প্রচেষ্টা নিয়ে খুশি?",
  
    },
    {
      Block: "CUSTOMER",
      SubBlock: "Value Proposition",
      Question: "Are you happy with your product and service offerings?",
          BBlock: "ক্রেতা",
      BSubBlock: "মূল্য প্রস্তাব",
      BQuestion: "আপনি কি আপনার পণ্য এবং পরিষেবা অফার নিয়ে খুশি?",
  
    },
    {
      Block: "OPERATIONAL & TECHNOLOGY",
      SubBlock: "Technology & Streamline",
      Question:
        "Do you have an adequate accounting system to manage all aspects of accounting, project/job costing, and financial reporting, etc.?",
          BBlock: "অপারেশনাল ও টেকনোলজি",
      BSubBlock: "প্রযুক্তি এবং স্ট্রীমলাইন",
      BQuestion: "অ্যাকাউন্টিং, প্রকল্প/চাকরির খরচ এবং আর্থিক প্রতিবেদন ইত্যাদির সমস্ত দিক পরিচালনা করার জন্য আপনার কি পর্যাপ্ত অ্যাকাউন্টিং সিস্টেম আছে?",
  
    },
    {
      Block: "OPERATIONAL & TECHNOLOGY",
      SubBlock: "Technology & Streamline",
      Question: "Do you have adequate internal control in place for accounting?",
          BBlock: "অপারেশনাল ও টেকনোলজি",
      BSubBlock: "প্রযুক্তি এবং স্ট্রীমলাইন",
      BQuestion: "অ্যাকাউন্টিংয়ের জন্য আপনার কি পর্যাপ্ত অভ্যন্তরীণ নিয়ন্ত্রণ আছে?",
  
    },
    {
      Block: "FINANCIAL CONTROL & PLANNING",
      SubBlock: "Financial Statements",
      Question: "Do you have a process to review your financial statements?",
          BBlock: "আর্থিক নিয়ন্ত্রণ ও পরিকল্পনা",
      BSubBlock: "আর্থিক বিবৃতি",
      BQuestion: "আপনার আর্থিক বিবৃতি পর্যালোচনা করার জন্য আপনার কি একটি প্রক্রিয়া আছে?",
  
    },
    {
      Block: "FINANCIAL CONTROL & PLANNING",
      SubBlock: "Financial Statements",
      Question:
        "Do you typically get your financial statements by the 15th of each month?",
          BBlock: "আর্থিক নিয়ন্ত্রণ ও পরিকল্পনা",
      BSubBlock: "আর্থিক বিবৃতি",
      BQuestion: "আপনি কি সাধারণত প্রতি মাসের ১৫ তারিখের মধ্যে আপনার আর্থিক বিবৃতি পান?",
  
    },
    {
      Block: "FINANCIAL CONTROL & PLANNING",
      SubBlock: "Budgeting & Forecasting",
      Question:
        "Do you have an annual budgeting process that includes a capital budget?",
        BBlock: "আর্থিক নিয়ন্ত্রণ ও পরিকল্পনা",
      BSubBlock: "বাজেটিং এবং পূর্বাভাস",
      BQuestion: "আপনার কি একটি বার্ষিক বাজেট প্রক্রিয়া আছে যার মধ্যে একটি মূলধন বাজেট রয়েছে?",
  
    },
    {
      Block: "FINANCIAL CONTROL & PLANNING",
      SubBlock: "Budgeting & Forecasting",
      Question: "Do you have a rolling 12-month forecasting process?",
          BBlock: "আর্থিক নিয়ন্ত্রণ ও পরিকল্পনা",
      BSubBlock: "বাজেটিং এবং পূর্বাভাস",
      BQuestion: "আপনার কি ১২-মাসের পূর্বাভাস প্রক্রিয়া আছে?",
  
    },
    {
      Block: "FINANCIAL CONTROL & PLANNING",
      SubBlock: "Financial Statements",
      Question:
        "Do you get a monthly variance analysis of your P&L, financial ratios for balance sheet and cash flow statements, etc.?",
          BBlock: "আর্থিক নিয়ন্ত্রণ ও পরিকল্পনা",
      BSubBlock: "আর্থিক বিবৃতি",
      BQuestion: "আপনি কি আপনার P&L, ব্যালেন্স শীট এবং নগদ প্রবাহের বিবৃতি ইত্যাদির জন্য আর্থিক অনুপাতের মাসিক প্রকরণ বিশ্লেষণ পান?",
  
    },
    {
      Block: "FINANCIAL CONTROL & PLANNING",
      SubBlock: "Financial Statements",
      Question: "Do you get daily or weekly bank reconciliation?",
          BBlock: "আর্থিক নিয়ন্ত্রণ ও পরিকল্পনা",
      BSubBlock: "আর্থিক বিবৃতি",
      BQuestion: "আপনি কি দৈনিক বা সাপ্তাহিক ব্যাংক পুনর্মিলন পান?",
  
    },
    {
      Block: "CASH FLOW",
      SubBlock: "Results",
      Question: "Are you happy with your revenue?",
          BBlock: "নগদ প্রবাহ",
      BSubBlock: "ফলাফল",
      BQuestion: "আপনি কি আপনার আয় নিয়ে খুশি?",
  
    },
    {
      Block: "CASH FLOW",
      SubBlock: "Results",
      Question: "Are you satisfied with your profit number?",
          BBlock: "নগদ প্রবাহ",
      BSubBlock: "ফলাফল",
      BQuestion: "আপনি কি আপনার লাভ নম্বর নিয়ে সন্তুষ্ট?",
  
    },
    {
      Block: "CASH FLOW",
      SubBlock: "Results",
      Question: "Does your company generate positive cash flow?",
    BBlock: "নগদ প্রবাহ",
      BSubBlock: "ফলাফল",
      BQuestion: "আপনার কোম্পানি কি ইতিবাচক নগদ প্রবাহ উৎপন্ন করে?",
  
    },
    {
      Block: "CASH FLOW",
      SubBlock: "Access to Fund",
      Question: "Do you have access to cash for the near term (1-2 years)?",
          BBlock: "নগদ প্রবাহ",
      BSubBlock: "ফান্ড এর অ্যাক্সেস",
      BQuestion: "আপনার নিকটবর্তী মেয়াদের (১-২ বছর) জন্য নগদ অর্থের অ্যাক্সেস আছে?",
  
    },
    {
      Block: "CASH FLOW",
      SubBlock: "Access to Fund",
      Question: "Do you have 3-6 months of working capital?",
          BBlock: "নগদ প্রবাহ",
      BSubBlock: "ফান্ড এর অ্যাক্সেস",
      BQuestion: "আপনার কি ৩-৬ মাসের ওয়ার্কিং ক্যাপিটাল আছে?",
  
    },
    {
      Block: "CASH FLOW",
      SubBlock: "Access to Fund",
      Question: "Do you have lines of credit or credit facilities available?",
          BBlock: "নগদ প্রবাহ",
      BSubBlock: "ফান্ড এর অ্যাক্সেস",
      BQuestion: "আপনার কি কোন লাইন অফ ক্রেডিট বা ক্রেডিট সুবিধা পাওয়া যায়?",
  
    },
    {
      Block: "BONUS Q",
      SubBlock: "Internal/External - Resource",
      Question: "Do you have an internal accounting team?",
         BBlock: "বোনাস প্রশ্ন",
      BSubBlock: "অভ্যন্তরীণ/বাহ্যিক - সম্পদ",
      BQuestion: "আপনার একটি অভ্যন্তরীণ অ্যাকাউন্টিং দল আছে?",
  
    },
    {
      Block: "FINANCIAL CONTROL & PLANNING",
      SubBlock: "Financial Statements",
      Question: "Are you happy with your accounting process?",
          BBlock: "আর্থিক নিয়ন্ত্রণ ও পরিকল্পনা",
      BSubBlock: "আর্থিক বিবৃতি",
      BQuestion: "আপনি কি আপনার অ্যাকাউন্টিং প্রক্রিয়া নিয়ে খুশি?",
  
    },
    {
      Block: "OPERATIONAL & TECHNOLOGY",
      SubBlock: "Technology & Streamline",
      Question: "Do you have an ERP (Enterprise Resource Planning) system?",
          BBlock: "অপারেশনাল ও টেকনোলজি",
      BSubBlock: "প্রযুক্তি এবং স্ট্রীমলাইন",
      BQuestion: "আপনার কি একটি ERP (এন্টারপ্রাইজ রিসোর্স প্ল্যানিং) সিস্টেম আছে?",
  
    },
    {
      Block: "OPERATIONAL & TECHNOLOGY",
      SubBlock: "Technology & Streamline",
      Question:
        "Do you have an IT system backup that includes a cyber security restoration process?",
        BBlock: "অপারেশনাল ও টেকনোলজি",
      BSubBlock: "প্রযুক্তি এবং স্ট্রীমলাইন",
      BQuestion: "আপনার কি একটি আইটি সিস্টেম ব্যাকআপ আছে যা সাইবার নিরাপত্তা পুনরুদ্ধার প্রক্রিয়া অন্তর্ভুক্ত করে?",
  
    },
    {
      Block: "FINANCIAL CONTROL & PLANNING",
      SubBlock: "Budgeting & Forecasting",
      Question: "Do you have business insurance in place and review once a year?",
          BBlock: "আর্থিক নিয়ন্ত্রণ ও পরিকল্পনা",
      BSubBlock: "বাজেটিং এবং পূর্বাভাস",
      BQuestion: "আপনার কি ব্যবসায়িক বীমা আছে এবং বছরে একবার পর্যালোচনা করুন?",
  
    },
    {
      Block: "BONUS Q",
      SubBlock: "Internal/External - Resource",
      Question: "Do you have an internal IT team?",
         BBlock: "বোনাস প্রশ্ন",
      BSubBlock: "অভ্যন্তরীণ/বাহ্যিক - সম্পদ",
      BQuestion: "আপনার কি একটি অভ্যন্তরীণ আইটি দল আছে?",
  
    },
    {
      Block: "OPERATIONAL & TECHNOLOGY",
      SubBlock: "Technology & Streamline",
      Question: "Are you happy with your IT process?",
          BBlock: "অপারেশনাল ও টেকনোলজি",
      BSubBlock: "প্রযুক্তি এবং স্ট্রীমলাইন",
      BQuestion: "আপনি কি আপনার আইটি প্রক্রিয়া নিয়ে খুশি?",
  
    },
    {
      Block: "MARKETING",
      SubBlock: "Target Market",
      Question:
        "Is your target market clear, and are your sales and marketing efforts focused on it?",
      
      BBlock: "মার্কেটিং",
      BSubBlock: "লক্ষ্য বাজার",
      BQuestion: "আপনার লক্ষ্য বাজার কি পরিষ্কার, এবং আপনার বিক্রয় এবং বিপণন প্রচেষ্টা কি এটির উপর দৃষ্টি নিবদ্ধ করে?",
  
    },
    {
      Block: "MARKETING",
      SubBlock: "Target Market",
      Question: "Do you have a clearly defined marketing plan?",
          BBlock: "মার্কেটিং",
      BSubBlock: "লক্ষ্য বাজার",
      BQuestion: "আপনার কি একটা পরিষ্কারভাবে সংজ্ঞায়িত বিপণন পরিকল্পনা আছে?",
  
    },
    {
      Block: "MARKETING",
      SubBlock: "Branding",
      Question: "Do you have separate marketing and branding efforts?",
          BBlock: "মার্কেটিং",
      BSubBlock: "ব্র্যান্ডিং",
      BQuestion: "আপনার কি আলাদা মার্কেটিং এবং ব্র্যান্ডিং প্রচেষ্টা আছে?",
  
    },
    {
      Block: "MARKETING",
      SubBlock: "Target Market",
      Question: "Do you have your differentiators clearly stated?",
          BBlock: "মার্কেটিং",
      BSubBlock: "লক্ষ্য বাজার",
      BQuestion: "আপনি কি আপনার পার্থক্য কারীদের স্পষ্টভাবে উল্লেখ করেছেন?",
  
    },
    {
      Block: "CUSTOMER",
      SubBlock: "Value Proposition",
      Question:
        "Do your sales and marketing efforts communicate your unique value proposition?",
          BBlock: "ক্রেতা",
      BSubBlock: "মূল্য প্রস্তাব",
      BQuestion: "আপনার বিক্রয় এবং বিপণনের প্রচেষ্টা কি আপনার অনন্য মূল্য প্রস্তাবের সাথে যোগাযোগ করে?",
  
    },
    {
      Block: "MARKETING",
      SubBlock: "Branding",
      Question: "Do you believe your marketing efforts are working?",
          BBlock: "মার্কেটিং",
      BSubBlock: "ব্র্যান্ডিং",
      BQuestion: "আপনি কি বিশ্বাস করেন যে আপনার বিপণন প্রচেষ্টা কাজ করছে?",
  
    },
    {
      Block: "BONUS Q",
      SubBlock: "Internal/External - Resource",
      Question: "Do you have an internal marketing team?",
          BBlock: "বোনাস প্রশ্ন",
      BSubBlock: "অভ্যন্তরীণ/বাহ্যিক - সম্পদ",
      BQuestion: "আপনার একটি অভ্যন্তরীণ বিপণন দল আছে?",
  
    },
    {
      Block: "CUSTOMER",
      SubBlock: "Value Proposition",
      Question: "Does your offerings align with your customer's needs?",
       BBlock: "ক্রেতা",
      BSubBlock: "মূল্য প্রস্তাব",
      BQuestion: "আপনার অফার গুলো কি আপনার গ্রাহকদের চাহিদার সাথে সামঞ্জস্যপূর্ণ?",
  
    },
    {
      Block: "CUSTOMER",
      SubBlock: "Value Proposition",
      Question:
        "Are you focusing your conversations on helping your customers make good buying decisions?",
        BBlock: "ক্রেতা",
      BSubBlock: "মূল্য প্রস্তাব",
      BQuestion: "আপনি কি আপনার গ্রাহককে ভাল কেনার সিদ্ধান্ত নিতে সাহায্য করার উপর আপনার কথোপকথনকে ফোকাস করছেন?",
  
    },
    {
      Block: "MARKETING",
      SubBlock: "Target Market",
      Question:
        "Are you getting feedback from your customers regarding the effectiveness of your marketing efforts?",
          BBlock: "মার্কেটিং",
      BSubBlock: "লক্ষ্য বাজার",
      BQuestion: "আপনি কি আপনার বিপণন প্রচেষ্টার কার্যকারিতা সম্পর্কে আপনার গ্রাহকের কাছ থেকে প্রতিক্রিয়া পাচ্ছেন?",
  
    },
    {
      Block: "MARKETING",
      SubBlock: "Competition",
      Question: "Do you know your competition?",
          BBlock: "মার্কেটিং",
      BSubBlock: "প্রতিযোগিতা",
      BQuestion: "আপনি কি আপনার প্রতিযোগিতা জানেন?",
  
    },
    {
      Block: "MARKETING",
      SubBlock: "Competition",
      Question: "Do you know what your competition does better than you?",
          BBlock: "মার্কেটিং",
      BSubBlock: "প্রতিযোগিতা",
      BQuestion: "আপনি কি জানেন যে আপনার প্রতিযোগিতা আপনার থেকে ভালো করে কি করে?",
  
    },
    {
      Block: "MARKETING",
      SubBlock: "Competition",
      Question: "Do you know what your competition's weaknesses are?",
          BBlock: "মার্কেটিং",
      BSubBlock: "প্রতিযোগিতা",
      BQuestion: "আপনি কি জানেন আপনার প্রতিযোগিতার দুর্বলতাগুলো কি?",
  
    },
    {
      Block: "MARKETING",
      SubBlock: "Competition",
      Question:
        "Do you research your competition’s website, social media platforms, press releases, etc.?",
          BBlock: "মার্কেটিং",
      BSubBlock: "প্রতিযোগিতা",
      BQuestion: "আপনি কি আপনার প্রতিযোগিতার ওয়েবসাইট, সোশ্যাল মিডিয়া প্ল্যাটফর্ম, প্রেস রিলিজ নিয়ে গবেষণা করেন?",
  
    },
    {
      Block: "MARKETING",
      SubBlock: "Competition",
      Question: "Do you know what you do better than your competition?",
          BBlock: "মার্কেটিং",
      BSubBlock: "প্রতিযোগিতা",
      BQuestion: "আপনি কি জানেন আপনার প্রতিযোগিতার চেয়ে আপনি কি ভাল করেন?",
  
    },
    {
      Block: "MARKETING",
      SubBlock: "Branding",
      Question: "Are you satisfied with your marketing and branding process?",
          BBlock: "মার্কেটিং",
      BSubBlock: "ব্র্যান্ডিং",
      BQuestion: "আপনি কি আপনার মার্কেটিং এবং ব্র্যান্ডিং প্রক্রিয়ার সাথে সন্তুষ্ট?",
  
    },
    {
      Block: "PURPOSE",
      SubBlock: "Mission",
      Question:
        "Do you know why your organization exists? (FYI, It's not about the money)",
        BBlock: "উদ্দেশ্য",
      BSubBlock: "মিশন",
      BQuestion: "আপনি কি জানেন কেন আপনার প্রতিষ্ঠান বিদ্যমান? (FYI, এটা টাকা সম্পর্কে নয়)",
  
    },
    {
      Block: "PURPOSE",
      SubBlock: "Mission",
      Question: "Do you know what your business’ purpose, cause, or passions?",
          BBlock: "উদ্দেশ্য",
      BSubBlock: "মিশন",
      BQuestion: "আপনি কি জানেন আপনার ব্যবসার উদ্দেশ্য, কারণ বা আবেগ কি?",
  
    },
    {
      Block: "PURPOSE",
      SubBlock: "Mission",
      Question: "Do you know what business you are in?",
          BBlock: "উদ্দেশ্য",
      BSubBlock: "মিশন",
      BQuestion: "আপনি কি জানেন আপনি কি ব্যবসা করছেন?",
  
    },
    {
      Block: "PURPOSE",
      SubBlock: "Mission",
      Question: "Do you have a clear understanding of what you do?",
          BBlock: "উদ্দেশ্য",
      BSubBlock: "মিশন",
      BQuestion: "আপনি যা করেন তা কি আপনার স্পষ্ট ধারণা আছে?",
  
    },
    {
      Block: "PURPOSE",
      SubBlock: "Mission",
      Question: "Do you have a clear understanding of whom you serve?",
          BBlock: "উদ্দেশ্য",
      BSubBlock: "মিশন",
      BQuestion: "আপনি কাকে পরিবেশন করেন সে সম্পর্কে আপনার কি স্পষ্ট ধারণা আছে?",
  
    },
    {
      Block: "PURPOSE",
      SubBlock: "Mission",
      Question: "Do you have a clear understanding of how you serve them?",
          BBlock: "উদ্দেশ্য",
      BSubBlock: "মিশন",
      BQuestion: "আপনি তাদের পরিবেশন কিভাবে করবেন, তার একটি পরিষ্কার ধারণাশক্তি কি আছে?",
  
    },
    {
      Block: "PURPOSE",
      SubBlock: "Mission",
      Question:
        "Do you have your Mission written and communicated to all your employees?",
          BBlock: "উদ্দেশ্য",
      BSubBlock: "মিশন",
      BQuestion: "আপনি কি আপনার মিশন লিখে রেখেছেন এবং আপনার সমস্ত কর্মচারীদের সাথে যোগাযোগ করেছেন?",
  
    },
    {
      Block: "OPERATIONAL & TECHNOLOGY",
      SubBlock: "Document Core Process",
      Question:
        "Do you have a proven process for doing business with your customers?",
       BBlock: "অপারেশনাল ও টেকনোলজি",
      BSubBlock: "নথির মূল প্রক্রিয়া",
      BQuestion: "আপনার গ্রাহকদের সাথে ব্যবসা করার জন্য আপনার কি একটি প্রমাণিত প্রক্রিয়া আছে?",
  
    },
    {
      Block: "OPERATIONAL & TECHNOLOGY",
      SubBlock: "Document Core Process",
      Question: "Do you have the core process simplified and documented?",
          BBlock: "অপারেশনাল ও টেকনোলজি",
      BSubBlock: "নথির মূল প্রক্রিয়া",
      BQuestion: "আপনার কি মূল প্রক্রিয়াটি সরলীকৃত এবং নথিভুক্ত করা আছে?",
  
    },
    {
      Block: "PEOPLE",
      SubBlock: "Role, Responsibilities & Accountabilities",
      Question:
        "Does everyone on your team understand the system and follow the process? (The team includes sales, marketing, operations, finance, HR, IT, admin, etc.)",
          BBlock: "মানুষের",
      BSubBlock: "ভূমিকা, দায়িত্ব এবং জবাবদিহিতা",
      BQuestion: "আপনার দলের সবাই কি সিস্টেমটি বোঝে এবং প্রক্রিয়াটি অনুসরণ করে? দলটির মধ্যে রয়েছে বিক্রয়, বিপণন, অপারেশন, ফিনান্স, এইচআর, আইটি, অ্যাডমিন ইত্যাদি।",
  
    },
    {
      Block: "OPERATIONAL & TECHNOLOGY",
      SubBlock: "Document Core Process",
      Question:
        "Do you have quality control procedures to minimize the risk of poor-quality output?",
          BBlock: "অপারেশনাল ও টেকনোলজি",
      BSubBlock: "নথির মূল প্রক্রিয়া",
      BQuestion: "নিম্ন-মানের আউটপুটের ঝুঁকি কমানোর জন্য আপনার কি একটি মান নিয়ন্ত্রণ পদ্ধতি আছে?",
  
    },
    {
      Block: "OPERATIONAL & TECHNOLOGY",
      SubBlock: "Education & Training",
      Question:
        "Do you have recurring education and training programs for all team members to ensure the proven processes are followed?",
          BBlock: "অপারেশনাল ও টেকনোলজি",
      BSubBlock: "শিক্ষা ও প্রশিক্ষণ",
      BQuestion: "প্রমাণিত প্রক্রিয়া অনুসরণ করা হয়েছে তা নিশ্চিত করার জন্য আপনার কি সমস্ত দলের সদস্যদের জন্য পুনরাবৃত্তিমূলক শিক্ষা এবং প্রশিক্ষণ প্রোগ্রাম আছে?",
  
    },
    {
      Block: "OPERATIONAL & TECHNOLOGY",
      SubBlock: "Technology & Streamline",
      Question:
        "Do you have a continuous process improvement mindset in the organization?",
          BBlock: "অপারেশনাল ও টেকনোলজি",
      BSubBlock: "প্রযুক্তি এবং স্ট্রীমলাইন",
      BQuestion: "আপনার কি প্রতিষ্ঠানে ক্রমাগত প্রক্রিয়া উন্নতির মানসিকতা আছে?",
  
    },
    {
      Block: "OPERATIONAL & TECHNOLOGY",
      SubBlock: "Technology & Streamline",
      Question:
        "Do you have a process to align the operations team with the sales team?",
          BBlock: "অপারেশনাল ও টেকনোলজি",
      BSubBlock: "প্রযুক্তি এবং স্ট্রীমলাইন",
      BQuestion: "আপনার কি বিক্রয় দলের সাথে অপারেশন টিম সারিবদ্ধ করার একটি প্রক্রিয়া আছে?",
  
    },
    {
      Block: "PEOPLE",
      SubBlock: "Right People, Right Seat",
      Question: "Do you have the right people in your organization?",
          BBlock: "মানুষের",
      BSubBlock: "সঠিক মানুষ, সঠিক আসন",
      BQuestion: "আপনার প্রতিষ্ঠানে কি সঠিক লোক আছে?",
  
    },
    {
      Block: "PEOPLE",
      SubBlock: "Right People, Right Seat",
      Question: "Do you have the right people in the right seats?",
      BBlock: "মানুষের",
      BSubBlock: "সঠিক মানুষ, সঠিক আসন",
      BQuestion: "আপনার কি সঠিক আসনে সঠিক লোক আছে?",

    },
    {
      Block: "PEOPLE",
      SubBlock: "Org Structure & HR Process",
      Question:
        "Do you have an easy-to-maintain organizational chart to understand your human resources?",
        BBlock: "মানুষের",
        BSubBlock: "সংস্থার কাঠামো এবং এইচআর প্রক্রিয়া",
        BQuestion: "আপনার মানব সম্পদ বোঝার জন্য আপনার কাছে সহজে রক্ষণাবেক্ষণ করা সাংগঠনিক চার্ট আছে?",
  
    },
    {
      Block: "PEOPLE",
      SubBlock: "Role, Responsibilities & Accountabilities",
      Question:
        "Do you have the roles, responsibilities, and expectations clearly defined and updated?",
        BBlock: "মানুষের",
        BSubBlock: "ভূমিকা, দায়িত্ব এবং জবাবদিহিতা",
        BQuestion: "আপনার কি ভূমিকা, দায়িত্ব এবং প্রত্যাশাগুলি স্পষ্টভাবে সংজ্ঞায়িত এবং আপডেট করা আছে?",
      
    },
    {
      Block: "PEOPLE",
      SubBlock: "Right People, Right Seat",
      Question:
        "Do you perform a skill gap analysis regularly? (At least once a year.)",
      
      BBlock: "মানুষের",
      BSubBlock: "সঠিক মানুষ, সঠিক আসন",
      BQuestion: "আপনি কি নিয়মিত দক্ষতার ব্যবধান বিশ্লেষণ করেন? (কমপক্ষে বছরে একবার)",
    
  
    },
    {
      Block: "PEOPLE",
      SubBlock: "Right People, Right Seat",
      Question:
        "Do you have an effective leadership/management team that understands and recognizes the individual’s and the team’s strengths and weaknesses?",
          BBlock: "মানুষের",
      BSubBlock: "সঠিক মানুষ, সঠিক আসন",
      BQuestion: "আপনার কি একটি কার্যকর নেতৃত্ব/ব্যবস্থাপনা দল আছে যা ব্যক্তি এবং দলের শক্তি এবং দুর্বলতা বোঝে এবং স্বীকৃতি দেয়?",
  
    },
    {
      Block: "PEOPLE",
      SubBlock: "Right People, Right Seat",
      Question:
        "Does your leadership team demonstrate openness, honesty, and a high level of trust?",
          BBlock: "মানুষের",
      BSubBlock: "সঠিক মানুষ, সঠিক আসন",
      BQuestion: "আপনার নেতৃত্বের দল কি উন্মুক্ততা, সততা এবং উচ্চ স্তরের আস্থা প্রদর্শন করে?",
  
    },
    {
      Block: "OPERATIONAL & TECHNOLOGY",
      SubBlock: "Document Core Process",
      Question: "Do you have a documented hiring and onboarding process?",
          BBlock: "অপারেশনাল ও টেকনোলজি",
      BSubBlock: "নথির মূল প্রক্রিয়া",
      BQuestion: "আপনার কি একটি নথিভুক্ত নিয়োগ এবং অনবোর্ডিং প্রক্রিয়া আছে?",
  
    },
    {
      Block: "OPERATIONAL & TECHNOLOGY",
      SubBlock: "Document Core Process",
      Question:
        "Do you have a documented training process for all key positions?",
         BBlock: "অপারেশনাল ও টেকনোলজি",
     BSubBlock: "নথির মূল প্রক্রিয়া",
     BQuestion: "আপনার কি সমস্ত মূল পদের জন্য একটি নথিভুক্ত প্রশিক্ষণ প্রক্রিয়া আছে?",
  
    },
    {
      Block: "PEOPLE",
      SubBlock: "Role, Responsibilities & Accountabilities",
      Question: "Do you have an employee handbook?",
         BBlock: "মানুষের",
     BSubBlock: "ভূমিকা, দায়িত্ব এবং জবাবদিহিতা",
     BQuestion: "আপনার কি একটি কর্মচারী হ্যান্ডবুক আছে?",
  
    },
    {
      Block: "PEOPLE",
      SubBlock: "Right People, Right Seat",
      Question: "Do you or your team actively learn and grow?",
         BBlock: "মানুষের",
     BSubBlock: "সঠিক মানুষ, সঠিক আসন",
     BQuestion: "আপনি বা আপনার দল কি সক্রিয়ভাবে শিখতে এবং বৃদ্ধি পায়?",
  
    },
    {
      Block: "OPERATIONAL & TECHNOLOGY",
      SubBlock: "Education & Training",
      Question: "Do you have a leadership training/coaching program?",
         BBlock: "অপারেশনাল ও টেকনোলজি",
     BSubBlock: "শিক্ষা ও প্রশিক্ষণ",
     BQuestion: "আপনার কি নেতৃত্বের প্রশিক্ষণ/কোচিং প্রোগ্রাম আছে?",
  
    },
    {
      Block: "OPERATIONAL & TECHNOLOGY",
      SubBlock: "Education & Training",
      Question:
        "Do you have a trusted advisor to whom you can reach out for advice or as a sounding board?",
         BBlock: "অপারেশনাল ও টেকনোলজি",
     BSubBlock: "শিক্ষা ও প্রশিক্ষণ",
     BQuestion: "আপনার কি একজন বিশ্বস্ত উপদেষ্টা আছে যার কাছে আপনি পরামর্শের জন্য বা সাউন্ডিং বোর্ড হিসাবে যোগাযোগ করতে পারেন?",
  
    },
    {
      Block: "PEOPLE",
      SubBlock: "Org Structure & HR Process",
      Question:
        "Do you have a compensation review, reward, and communication process?",
         BBlock: "মানুষের",
     BSubBlock: "সংস্থার কাঠামো এবং এইচআর প্রক্রিয়া",
     BQuestion: "আপনার কি ক্ষতিপূরণ পর্যালোচনা, পুরস্কার এবং যোগাযোগ প্রক্রিয়া আছে?",
  
    },
    {
      Block: "PEOPLE",
      SubBlock: "Org Structure & HR Process",
      Question: "Do you have a process for getting employee feedback?",
         BBlock: "মানুষের",
     BSubBlock: "সংস্থার কাঠামো এবং এইচআর প্রক্রিয়া",
     BQuestion: "কর্মচারীদের মতামত পাওয়ার জন্য আপনার কি কোন প্রক্রিয়া আছে?",
  
    },
    {
      Block: "PEOPLE",
      SubBlock: "Role, Responsibilities & Accountabilities",
      Question: "Are you able to delegate work to others?",
         BBlock: "মানুষের",
     BSubBlock: "ভূমিকা, দায়িত্ব এবং জবাবদিহিতা",
     BQuestion: "আপনি কি অন্যদের কাছে কাজ অর্পণ করতে পারবেন?",
  
    },
    {
      Block: "PEOPLE",
      SubBlock: "Role, Responsibilities & Accountabilities",
      Question: "Do you have a cross training process in place?",
         BBlock: "মানুষের",
     BSubBlock: "ভূমিকা, দায়িত্ব এবং জবাবদিহিতা",
     BQuestion: "আপনার কি ক্রস ট্রেনিং প্রক্রিয়া আছে?",
  
    },
    {
      Block: "PEOPLE",
      SubBlock: "Org Structure & HR Process",
      Question: "Do you meet with your direct reports regularly?",
         BBlock: "মানুষের",
     BSubBlock: "সংস্থার কাঠামো এবং এইচআর প্রক্রিয়া",
     BQuestion: "আপনি কি নিয়মিত আপনার সরাসরি রিপোর্টের সাথে দেখা করেন?",
  
    },
    {
      Block: "PEOPLE",
      SubBlock: "Org Structure & HR Process",
      Question: "Do you take pride on your ability to retain employees?",
         BBlock: "মানুষের",
     BSubBlock: "সংস্থার কাঠামো এবং এইচআর প্রক্রিয়া",
     BQuestion: "আপনি কি কর্মচারীদের ধরে রাখার ক্ষমতা নিয়ে গর্ব করেন?",
  
    },
    {
      Block: "PEOPLE",
      SubBlock: "Org Structure & HR Process",
      Question: "Do you succeed at recruiting and onboarding new talent?",
         BBlock: "মানুষের",
     BSubBlock: "সংস্থার কাঠামো এবং এইচআর প্রক্রিয়া",
     BQuestion: "আপনি কি নতুন প্রতিভা নিয়োগ এবং অনবোর্ডিং এ সফল?",
  
    },
    {
      Block: "BONUS Q",
      SubBlock: "Internal/External - Resource",
      Question: "Do you have an internal human resources team?",
         BBlock: "বোনাস প্রশ্ন",
     BSubBlock: "অভ্যন্তরীণ\/বাহ্যিক - সম্পদ",
     BQuestion: "আপনার কি একটি অভ্যন্তরীণ মানব সম্পদ দল আছে?",
  
    },
    {
      Block: "PURPOSE",
      SubBlock: "5-Year Target",
      Question:
        "Do you have an organization chart reflecting your organization in the next five years?",
         BBlock: "উদ্দেশ্য",
     BSubBlock: "৫ বছরের লক্ষ্য",
     BQuestion: "আপনার কাছে কি একটি প্রতিষ্ঠানের চার্ট আছে যা আগামী পাঁচ বছরে আপনার প্রতিষ্ঠানকে প্রতিফলিত করে?",
  
    },
    {
      Block: "PURPOSE",
      SubBlock: "Mission",
      Question:
        "Do you have a specific and measurable target for your 10-year goals?",
         BBlock: "উদ্দেশ্য",
     BSubBlock: "মিশন",
     BQuestion: "আপনার কি ১০ বছরের জন্য একটি নির্দিষ্ট এবং পরিমাপযোগ্য লক্ষ্য আছে?",
  
    },
    {
      Block: "FINANCIAL CONTROL & PLANNING",
      SubBlock: "Budgeting & Forecasting",
      Question: "Do you have an annual strategic planning session?",
         BBlock: "আর্থিক নিয়ন্ত্রণ ও পরিকল্পনা",
     BSubBlock: "বাজেটিং এবং পূর্বাভাস",
     BQuestion: " আপনার কি একটি বার্ষিক কৌশলগত পরিকল্পনা অধিবেশন আছে?",
  
    },
    {
      Block: "PURPOSE",
      SubBlock: "5-Year Target",
      Question:
        "Have you performed a SWOT Analysis (Strengths, Weaknesses, Opportunities, and Threats) for critical areas of your business?",
         BBlock: "উদ্দেশ্য",
     BSubBlock: "৫ বছরের লক্ষ্য",
     BQuestion: "আপনি কি আপনার ব্যবসার গুরুত্বপূর্ণ ক্ষেত্রগুলির জন্য SWOT বিশ্লেষণ (শক্তি, দুর্বলতা, সুযোগ এবং হুমকি) করেছেন?",
  
    },
    {
      Block: "PURPOSE",
      SubBlock: "Mission",
      Question:
        "Do you have up to date and relevant business strategies in place for the future?",
         BBlock: "উদ্দেশ্য",
     BSubBlock: "মিশন",
     BQuestion: "ভবিষ্যতের জন্য আপনার কি আপ টু ডেট এবং প্রাসঙ্গিক ব্যবসায়িক কৌশল আছে?",
  
    },
    {
      Block: "PURPOSE",
      SubBlock: "5-Year Target",
      Question: "Do you have a revenue and profit target for 5-year goals?",
         BBlock: "উদ্দেশ্য",
     BSubBlock: "৫ বছরের লক্ষ্য",
     BQuestion: "আপনার কি ৫ বছরের জন্য একটি রাজস্ব এবং লাভের লক্ষ্য আছে?",
  
    },
    {
      Block: "PURPOSE",
      SubBlock: "5-Year Target",
      Question:
        "Have you identified the measurables for your 5-year targets? (# of customers, # of projects, revenue per employees, # of units, etc.)",
         BBlock: "উদ্দেশ্য",
     BSubBlock: "৫ বছরের লক্ষ্য",
     BQuestion: "আপনি কি আপনার ৫-বছরের লক্ষ্যমাত্রার জন্য পরিমাপযোগ্য জিনিসগুলি চিহ্নিত করেছেন? (# গ্রাহক, # প্রকল্প, রাজস্ব\/কর্মচারী, # ইউনিট, ইত্যাদি)",
  
    },
    {
      Block: "PURPOSE",
      SubBlock: "5-Year Target",
      Question:
        "Have you outlined your organization's structure, such as employees, technology, product mix, locations, operations scalability, processes, and clients, to reach your organization's 5-year targets?",
         BBlock: "উদ্দেশ্য",
     BSubBlock: "৫ বছরের লক্ষ্য",
     BQuestion: " আপনার প্রতিষ্ঠানের ৫-বছরের লক্ষ্যে পৌঁছানোর জন্য আপনি কি আপনার প্রতিষ্ঠানের কাঠামোর রূপরেখা দিয়েছেন, যেমন কর্মচারী, প্রযুক্তি, পণ্যের মিশ্রণ, অবস্থান, অপারেশন স্কেলেবিলিটি, প্রক্রিয়া এবং ক্লায়েন্ট?",
  
    },
    {
      Block: "FINANCIAL CONTROL & PLANNING",
      SubBlock: "Budgeting & Forecastin",
      Question:
        "Have you defined SMART goals for your 1-year plan (# of customers, # of projects, revenue per employees, # of units, etc.)? (SMART = Specific, Measurable, Attainable, Relevant, and Timebound)",
         BBlock: "আর্থিক নিয়ন্ত্রণ ও পরিকল্পনা",
     BSubBlock: "বাজেটিং এবং পূর্বাভাস",
     BQuestion: "আপনি কি আপনার ১-বছরের পরিকল্পনার জন্য SMART লক্ষ্য নির্ধারণ করেছেন (# গ্রাহক, # প্রকল্প, রাজস্ব/কর্মচারী, # ইউনিট ইত্যাদি)? (SMART = নির্দিষ্ট, পরিমাপযোগ্য, অর্জনযোগ্য, প্রাসঙ্গিক, এবং সময়সীমা)",
  
    },
    {
      Block: "FINANCIAL CONTROL & PLANNING",
      SubBlock: "Budgeting & Forecastin",
      Question: "Do you have the budget to support your 1-year plan?",
         BBlock: "আর্থিক নিয়ন্ত্রণ ও পরিকল্পনা",
     BSubBlock: "বাজেটিং এবং পূর্বাভাস",
     BQuestion: "আপনার ১ বছরের পরিকল্পনা সমর্থন করার জন্য আপনার কাছে কি বাজেট আছে?",
  
    },
    {
      Block: "CASH FLOW",
      SubBlock: "Access to Fund",
      Question:
        "Do you have a backup or contingency plan to minimize loss and damage caused by an unforeseen adverse event?",
         BBlock: "নগদ প্রবাহ",
     BSubBlock: "ফান্ড এর অ্যাক্সেস",
     BQuestion: "একটি অপ্রত্যাশিত প্রতিকূল ঘটনা দ্বারা সৃষ্ট ক্ষতি এবং ক্ষয়ক্ষতি কমানোর জন্য আপনার কি ব্যাকআপ বা আকস্মিক পরিকল্পনা আছে?",
  
    },
    {
      Block: "PURPOSE",
      SubBlock: "Vision",
      Question: "Do you have a succession plan in place?",
         BBlock: "উদ্দেশ্য",
     BSubBlock: "ভিশন",
     BQuestion: "আপনার কি উত্তরাধিকার পরিকল্পনা আছে?",
  
    },
    {
      Block: "REPORTING & FEEDBACK",
      SubBlock: "Reports and KPIs",
      Question: "Do you know the key drivers of your business?",
         BBlock: "প্রতিবেদন ও প্রতিক্রিয়া",
     BSubBlock: "রিপোর্ট এবং কেপিআই",
     BQuestion: "আপনি কি আপনার ব্যবসার মূল চালক জানেন?",
  
    },
    {
      Block: "REPORTING & FEEDBACK",
      SubBlock: "Reports and KPIs",
      Question:
        "Do you have Key Performance Indicators (KPIs) identified for your critical processes?",
         BBlock: "প্রতিবেদন ও প্রতিক্রিয়া",
     BSubBlock: "রিপোর্ট এবং কেপিআই",
     BQuestion: "আপনার গুরুত্বপূর্ণ প্রক্রিয়ার জন্য চিহ্নিত কী পারফরম্যান্স ইন্ডিকেটর (KPIs) আছে?",
  
    },
    {
      Block: "REPORTING & FEEDBACK",
      SubBlock: "Reports and KPIs",
      Question: "Do you have timely reporting on those KPIs?",
         BBlock: "প্রতিবেদন ও প্রতিক্রিয়া",
     BSubBlock: "রিপোর্ট এবং কেপিআই",
     BQuestion: "আপনার কি সেই কেপিআইগুলির উপর সময়মত রিপোর্ট করা আছে?",
  
    },
    {
      Block: "REPORTING & FEEDBACK",
      SubBlock: "Reports and KPIs",
      Question:
        "Do you have a list of all reports and report distribution lists?",
         BBlock: "প্রতিবেদন ও প্রতিক্রিয়া",
     BSubBlock: "রিপোর্ট এবং কেপিআই",
     BQuestion: "আপনার কাছে কি সমস্ত রিপোর্ট এবং রিপোর্ট বিতরণ তালিকার একটি তালিকা আছে?",
  
    },
    {
      Block: "REPORTING & FEEDBACK",
      SubBlock: "Reports and KPIs",
      Question: "Do you periodically review the usefulness of these reports?",
         BBlock: "প্রতিবেদন ও প্রতিক্রিয়া",
     BSubBlock: "রিপোর্ট এবং কেপিআই",
     BQuestion: "আপনি কি পর্যায়ক্রমে এই রিপোর্টগুলির উপযোগিতা পর্যালোচনা করেন?",
  
    },
    {
      Block: "REPORTING & FEEDBACK",
      SubBlock: "Meetings",
      Question: "Do you have a list of all recurring meetings?",
         BBlock: "প্রতিবেদন ও প্রতিক্রিয়া",
     BSubBlock: "মিটিং",
     BQuestion: "আপনার কাছে কি সব পুনরাবৃত্ত মিটিংয়ের তালিকা আছে?",
  
    },
    {
      Block: "REPORTING & FEEDBACK",
      SubBlock: "Meetings",
      Question: "Do you follow the best practices for meetings?",
         BBlock: "প্রতিবেদন ও প্রতিক্রিয়া",
     BSubBlock: "মিটিং",
     BQuestion: "আপনি কি মিটিংয়ের জন্য সেরা অনুশীলনগুলি অনুসরণ করেন?",
  
    },
    {
      Block: "REPORTING & FEEDBACK",
      SubBlock: "Meetings",
      Question:
        "Do you have meetings to review business performance and direction?",
         BBlock: "প্রতিবেদন ও প্রতিক্রিয়া",
     BSubBlock: "মিটিং",
     BQuestion: "ব্যবসায়িক কর্মক্ষমতা এবং দিকনির্দেশ পর্যালোচনা করার জন্য আপনার কি একটি মিটিং আছে?",
  
    },
    {
      Block: "REPORTING & FEEDBACK",
      SubBlock: "Meetings",
      Question: "Do you get feedback for each meeting?",
         BBlock: "প্রতিবেদন ও প্রতিক্রিয়া",
     BSubBlock: "মিটিং",
     BQuestion: "আপনি প্রতিটি সভার জন্য প্রতিক্রিয়া পান?",
  
    },
    {
      Block: "REPORTING & FEEDBACK",
      SubBlock: "Action Items",
      Question: "Do you track action items or to-do lists during the meetings?",
         BBlock: "প্রতিবেদন ও প্রতিক্রিয়া",
     BSubBlock: "করণীয় বিষয়সমূহ",
     BQuestion: "আপনি কি মিটিং চলাকালীন অ্যাকশন আইটেম বা করণীয় তালিকা ট্র্যাক করেন?",
  
    },
    {
      Block: "REPORTING & FEEDBACK",
      SubBlock: "Action Items",
      Question:
        "Do your team members know what to do with the action items and have a clear understanding of them?",
         BBlock: "প্রতিবেদন ও প্রতিক্রিয়া",
     BSubBlock: "করণীয় বিষয়সমূহ",
     BQuestion: "আপনার দলের সদস্যরা কি অ্যাকশন আইটেমগুলির সাথে কী করতে হবে তা জানেন এবং সেগুলি সম্পর্কে পরিষ্কার ধারণা আছে?",
  
    },
    {
      Block: "REPORTING & FEEDBACK",
      SubBlock: "Action Items",
      Question:
        "Do you hold people accountable based on the meeting action items?",
         BBlock: "প্রতিবেদন ও প্রতিক্রিয়া",
     BSubBlock: "করণীয় বিষয়সমূহ",
     BQuestion: "আপনি কি মিটিং অ্যাকশন আইটেমগুলির উপর ভিত্তি করে লোকেদের দায়বদ্ধ রাখেন?",
  
    },
    {
      Block: "REPORTING & FEEDBACK",
      SubBlock: "Action Items",
      Question:
        "Do your meeting action items or to-do list follow the SMART goals? (SMART = Specific, Measurable, Attainable, Relevant, and Timebound)",
         BBlock: "প্রতিবেদন ও প্রতিক্রিয়া",
     BSubBlock: "করণীয় বিষয়সমূহ",
     BQuestion: "আপনার মিটিং অ্যাকশন আইটেম বা করণীয় তালিকা কি স্মার্ট লক্ষ্যগুলি অনুসরণ করে? (SMART = নির্দিষ্ট, পরিমাপযোগ্য, অর্জনযোগ্য, প্রাসঙ্গিক, এবং সময়সীমা)",
  
    },
    {
      Block: "REPORTING & FEEDBACK",
      SubBlock: "Action Items",
      Question:
        "Do you have a process to send follow-ups for the meeting action items?",
         BBlock: "প্রতিবেদন ও প্রতিক্রিয়া",
     BSubBlock: "করণীয় বিষয়সমূহ",
     BQuestion: "মিটিং অ্যাকশন আইটেমগুলির জন্য ফলো-আপগুলি পাঠানোর জন্য আপনার কি একটি প্রক্রিয়া আছে?",
  
    },
    {
      Block: "REPORTING & FEEDBACK",
      SubBlock: "Meetings",
      Question: "Do you solve business issues during the meetings?",
         BBlock: "প্রতিবেদন ও প্রতিক্রিয়া",
     BSubBlock: "মিটিং",
     BQuestion: "আপনি কি মিটিংয়ের সময় ব্যবসায়িক সমস্যাগুলি সমাধান করেন?",
  
    },
    {
      Block: "REPORTING & FEEDBACK",
      SubBlock: "Reports and KPIs",
      Question: "Are you happy with your reporting process?",
         BBlock: "প্রতিবেদন ও প্রতিক্রিয়া",
     BSubBlock: "রিপোর্ট এবং কেপিআই",
     BQuestion: "আপনি কি আপনার রিপোর্টিং প্রক্রিয়া নিয়ে খুশি?",
  
    },
    {
      Block: "REPORTING & FEEDBACK",
      SubBlock: "Meetings",
      Question: "Are you happy with the current meeting process?",
         BBlock: "উদ্দেশ্য",
     BSubBlock: "মূল মূল্যবোধ",
     BQuestion: "আপনি কি বর্তমান মিটিং প্রক্রিয়া নিয়ে খুশি?",
  
    },
    {
      Block: "PURPOSE",
      SubBlock: "Core Value",
      Question:
        "Do you know what your core values are? They should be vital and timeless.",
         BBlock: "উদ্দেশ্য",
     BSubBlock: "মূল মূল্যবোধ",
     BQuestion: "আপনি কি জানেন আপনার মূল  মূল্যবোধ কি? এটা অত্যাবশ্যক এবং সময়হীন হওয়া উচিত.",
  
      
      
    },
    {
      Block: "PURPOSE",
      SubBlock: "Core Value",
      Question: "Do you know what values are unique to your organization?",
         BBlock: "উদ্দেশ্য",
     BSubBlock: "মূল মূল্যবোধ",
     BQuestion: "আপনি কি জানেন আপনার প্রতিষ্ঠানের জন্য কোন মূল্যবোধ অনন্য?",
  
    },
    {
      Block: "PURPOSE",
      SubBlock: "Core Value",
      Question:
        "Are these values something you can expect your most devoted team members to live by, even when it involves some sacrifice?",
         BBlock: "উদ্দেশ্য",
     BSubBlock: "মূল মূল্যবোধ",
     BQuestion: "এই মূল্যবোধ কি এমন কিছু যা আপনি আশা করতে পারেন যে আপনি আপনার সবচেয়ে নিবেদিত সহকর্মীদের দ্বারা বেঁচে থাকবে, এমনকি যখন এটি কিছু ত্যাগ স্বীকার করে?",
  
    },
    {
      Block: "PURPOSE",
      SubBlock: "Core Value",
      Question:
        "Do you have your core values written and communicated to all your employees?",
         BBlock: "উদ্দেশ্য",
     BSubBlock: "মূল মূল্যবোধ",
     BQuestion: "আপনি কি আপনার মূল মূল্যবোধ লিখে রেখেছেন এবং আপনার সমস্ত কর্মচারীদের সাথে যোগাযোগ করেছেন?",
  
    },
    {
      Block: "PURPOSE",
      SubBlock: "Core Value",
      Question:
        "Do you hire, fire, review, reward, and recognize people based on these core values?",
         BBlock: "উদ্দেশ্য",
     BSubBlock: "মূল মূল্যবোধ",
     BQuestion: "আপনি কি মূল মূল্যবোধের উপর ভিত্তি করে লোকেদের নিয়োগ, বরখাস্ত, পর্যালোচনা, পুরষ্কার এবং স্বীকৃতি দেন?",
  
    },
    {
      Block: "PURPOSE",
      SubBlock: "Vision",
      Question: "Do you have a clear Vision?",
         BBlock: "উদ্দেশ্য",
     BSubBlock: "ভিশন",
     BQuestion: "আপনার কি একটি পরিষ্কার বিজনেস ভিশন আছে?",
  
    },
    {
      Block: "PURPOSE",
      SubBlock: "Vision",
      Question: "Do you know what problems you are solving for the greater good?",
         BBlock: "উদ্দেশ্য",
     BSubBlock: "ভিশন",
     BQuestion: "আপনি কি জানেন যে আপনি বৃহত্তর ভালোর জন্য কোন সমস্যার সমাধান করছেন?",
  
    },
    {
      Block: "PURPOSE",
      SubBlock: "Vision",
      Question:
        "Do you have The Vision written and communicated to all your employees?",
         BBlock: "উদ্দেশ্য",
     BSubBlock: "ভিশন",
     BQuestion: "আপনার কাছে কি ভিশন লেখা আছে এবং আপনার সমস্ত কর্মচারীদের সাথে শেয়ার করা আছে?",
  
    },
    {
      Block: "PURPOSE",
      SubBlock: "Vision",
      Question:
        "Do you get the same answer about your Vision from all your employees?",
      BBlock: "উদ্দেশ্য",
     BSubBlock: "ভিশন",
     BQuestion: "আপনি কি আপনার সমস্ত কর্মচারীদের কাছ থেকে আপনার ভিশন ( দৃষ্টি )সম্পর্কে একই উত্তর পান?",
  
    },
    {
      Block: "PURPOSE",
      SubBlock: "10-Yr Moonshot",
      Question:
        "In 10 years do you know what your organization will look like, if you achieve all your strategic goals?",
         BBlock: "উদ্দেশ্য",
     BSubBlock: "১০ বছরের মুনশট",
     BQuestion: "১০ বছরে আপনি কি জানেন যে আপনার সমস্ত কৌশলগত লক্ষ্যগুলি অর্জন করলে আপনার সংস্থাটি কেমন হবে?",
  
    },
    {
      Block: "PURPOSE",
      SubBlock: "10-Yr Moonshot",
      Question:
        "Does your organization get excited, energized, and passionate about the 10 year goals?",
         BBlock: "উদ্দেশ্য",
     BSubBlock: "১০ বছরের মুনশট",
     BQuestion: "আপনার সংস্থা কি ১0 বছরের লক্ষ্য সম্পর্কে উদ্দীপিত, উৎসাহী এবং অনুরাগী হয়?",
  
    },
    {
      Block: "PURPOSE",
      SubBlock: "10-Yr Moonshot",
      Question:
        "Do you have a specific and measurable target for your 10 year goals that are larger than life?",
       BBlock: "উদ্দেশ্য",
     BSubBlock: "১০ বছরের মুনশট",
     BQuestion: "আপনার কি ১0 বছরের জন্য একটি নির্দিষ্ট এবং পরিমাপযোগ্য লক্ষ্য আছে যা জীবনের চেয়ে বড়? ",
  
    },
    {
      Block: "PURPOSE",
      SubBlock: "Vision",
      Question: "Do you see your Vision when you close your eyes?",
         BBlock: "উদ্দেশ্য",
     BSubBlock: "ভিশন",
     BQuestion: "চোখ বন্ধ করলে কি আপনি আপনার ভিশন দেখতে পান?",
  
    },
  ];
  
  export default banglaData