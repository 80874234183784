import React, { useState, useEffect } from "react";
import Typography from "@mui/material/Typography";
import AccessibilityIcon from "../reUse/AccessibilityIcon";
import HelpIcon from "../reUse/HelpIcon";
import { Tag, Tooltip ,Toggle} from "@carbon/react";
import { matchPath, useLocation } from "react-router-dom";
// import { Button, Link, Modal } from "@carbon/react";
import { useNavigate } from "react-router-dom";
import { Menu, MenuItem, Button } from '@mui/material';
export default function PortalHeader({ getData, isBangla, setisBangla,islanguage }) {
  const [open, setopen] = useState(true);
  const name = localStorage.getItem("name");
  const [modal, setmodal] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);
  const [image, setImage] = useState({ preview: "/john_doe.png", raw: "" });
  const { pathname } = useLocation();
  useEffect(() => {
    if (pathname.includes("/portal/report"))  {
      const savedLanguagePreference = localStorage.getItem("isBangla");
      if (savedLanguagePreference !== null) {
        setisBangla(JSON.parse(savedLanguagePreference));
      } else {
        setisBangla(false);
      }
    }
  }, [pathname, setisBangla]);
  useEffect(() => {
    return () => {
      if (pathname === "/portal/report") {
        localStorage.removeItem("isBangla");
        setisBangla(false)
      }
    };
  }, [pathname]);

  const videoUrls = {
    "/portal/users": "https://youtu.be/hE6lKEdQjow?si=OLWjANK2xUkWTIfy&t=245",
    "/portal/report": "https://youtu.be/hE6lKEdQjow?si=WT3afc_XtS06w9vZ&t=123",
    "/portal/compare/": "https://youtu.be/hE6lKEdQjow?si=26L5NC6nWFwPy1Pu&t=147",
    "/portal/chatbot/": "https://youtu.be/hE6lKEdQjow?si=N5MDViqrbUKeFUV_&t=229",
    "/portal/plans":"https://youtu.be/hE6lKEdQjow?si=QHcLjJvXCBI1VoLe&t=71"
  };
  const videoUrls2 = {
    "/portal/users": "https://www.youtube.com/watch?v=p4a0RpEUn7o&t=266s",
    "/portal/report": "https://youtu.be/p4a0RpEUn7o?si=j0QDQGWkCm7ZiU6E&t=130",
    "/portal/compare/": "https://youtu.be/p4a0RpEUn7o?si=9tltYyZmiafw3cVM&t=155",
    "/portal/chatbot/": "https://youtu.be/p4a0RpEUn7o?si=mV99efTqMGJvR2LX&t=246",
    "/portal/plans":"https://youtu.be/p4a0RpEUn7o?si=txbb4xw2r1f8RJUc&t=76"
  };
  const videoUrls3 = {
    "/portal/users": "https://youtu.be/nJNdMB_PReg?si=zenMSwqhYCED1rvZ&t=306",
    "/portal/report": "https://youtu.be/nJNdMB_PReg?si=Qw1a8itdLQ2UhLgI&t=155",
    "/portal/compare/": "https://youtu.be/nJNdMB_PReg?si=Tt2-zeVUdls0Gki3&t=179",
    "/portal/chatbot/": "https://youtu.be/nJNdMB_PReg?si=gJfXJwxvQ5SXbMdc&t=286",
    "/portal/plans":"https://youtu.be/nJNdMB_PReg?si=_wkXUNZKIZWSmHPg&t=90"
  };

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const defaultVideoUrl = "https://youtu.be/hE6lKEdQjow?si=HANkJnFCUA56Mku-&t=71";
  const defaultVideoUrl2= "https://youtu.be/p4a0RpEUn7o?si=txbb4xw2r1f8RJUc&t=76";
  const defaultVideoUrl3= "https://youtu.be/nJNdMB_PReg?si=_wkXUNZKIZWSmHPg&t=90";
  const getVideoUrl = () => {
    for (let route in videoUrls) {
      if (matchPath(route, pathname)) {
        return videoUrls[route];
      }
    }
    return defaultVideoUrl;
  };
  const getVideoUrl2 = () => {
    for (let route in videoUrls2) {
      if (matchPath(route, pathname)) {
        return videoUrls2[route];
      }
    }
    return defaultVideoUrl2;
  };
  const getVideoUrl3 = () => {
    for (let route in videoUrls3) {
      if (matchPath(route, pathname)) {
        return videoUrls3[route];
      }
    }
    return defaultVideoUrl3;
  };
  useEffect(() => {
    if (pathname.includes("/portal/report")) {
      localStorage.setItem("isBangla", JSON.stringify(isBangla));
    }
  }, [isBangla, pathname]);

  const handleOnClick = () => {
    const videoUrl = getVideoUrl();
    if (videoUrl) {
      window.open(videoUrl, "_blank");
    }
  };
  const handleOnClick2 = () => {
    const videoUrl2 = getVideoUrl2();
    if (videoUrl2) {
      window.open(videoUrl2 ,"_blank");
    }
  };
  const handleOnClick3 = () => {
    const videoUrl3 = getVideoUrl3();
    if (videoUrl3) {
      window.open(videoUrl3 ,"_blank");
    }
  };
  const handleOpenMenu = () => {
    getData(open);
    setopen(!open);
  };
  const navigate = useNavigate();
  useEffect(() => {
    let img = localStorage.getItem("image");
    setImage({ preview: img });
  }, []);
  return (
    <>
      <div className="portalHeader">
        <div className="textHeader">
          {pathname === "/portal/index" ? (
            <Typography variant="large" color="neutral.b800">
              Dashboard
            </Typography>
          ) : (
            ""
          )}
          {/* {pathname==="/portal/users"?        <Typography variant="large" color="neutral.b800">
          Users
        </Typography>:""} */}
          {pathname === "/portal/consultation" ? (
            <Typography variant="large" color="neutral.b800">
              Consultation
            </Typography>
          ) : (
            ""
          )}
          {pathname === "/portal/plans" ? (
            <Typography variant="large" color="neutral.b800">
              Plans & Billing
            </Typography>
          ) : (
            ""
          )}
          {pathname === "/portal/userInfo" ? (
            <Typography variant="large" color="neutral.b800">
              User Information
            </Typography>
          ) : (
            ""
          )}
          {pathname === "/portal/feedback" ? (
            <Typography variant="large" color="neutral.b800">
              Feedback
            </Typography>
          ) : (
            ""
          )}
          {pathname === "/portal/users" ? (
            <Typography variant="large" color="neutral.b800">
              Users
            </Typography>
          ) : (
            ""
          )}
          {pathname === "/portal/assessmentreports" ? (
            <Typography variant="large" color="neutral.b800">
              Reports
            </Typography>
          ) : (
            ""
          )}
          {pathname === "/portal/compare/" ? (
            <Typography variant="large" color="neutral.b800">
              Compare Reports
            </Typography>
          ) : (
            ""
          )}
          {pathname === "/portal/chatbot/" ? (
            <Typography variant="large" color="neutral.b800">
              Pan AI
            </Typography>
          ) : (
            ""
          )}
        </div>
        <div className="quick" style={{ paddingTop: "24px", paddingRight: "40px" }}>
      <Button onClick={handleClick} variant="contained" color="primary">
        QuickStart
      </Button>
      <Menu
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        onClose={handleClose}
      >
        <MenuItem onClick={handleOnClick}>
        <Typography variant="body1" style={{ width: '100%', textAlign: 'left' }}>
           English
          </Typography>
        </MenuItem>
   
        <MenuItem onClick={handleOnClick3}>
        <Typography variant="body1" style={{ width: '100%', textAlign: 'left' }}>
        Español
          </Typography>
   
        </MenuItem>
        <MenuItem onClick={handleOnClick2}>
        <Typography variant="body1" style={{ width: '100%', textAlign: 'left' }}>
        বাংলা
          </Typography>
        </MenuItem>
      </Menu>
    </div>
    
        {pathname.includes("/portal/report") ? (
         <div className="toggle-container3">
         {islanguage==="bangla"? <Toggle
           labelA="English"
           labelB="বাংলা"
           defaultToggled
           id="toggle-1"
           toggled={isBangla}
           onClick={() => setisBangla(!isBangla)}
         />:""}
           {islanguage==="spanish"?<Toggle
           labelA="English"
           labelB="Español"
           defaultToggled
           id="toggle-1"
           toggled={isBangla}
           onClick={() => setisBangla(!isBangla)}
         />:""}
       </div>
       
        
        ) : (
          ""
        )}
        {pathname.includes("/portal/index") ? (
          ""
        ) : (
          <div className="icn usa" style={{ cursor: "pointer" }}>
            <div
              className="assesmentUser"
              
              onClick={() => {
                localStorage.removeItem("isBangla");
                navigate("/portal/index"); 
              }}
            >
              <div>
                <svg
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M5 12H19"
                    stroke="#0065FF"
                    stroke-width="2"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                  <path
                    d="M5 12L11 18"
                    stroke="#0065FF"
                    stroke-width="2"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                  <path
                    d="M5 12L11 6"
                    stroke="#0065FF"
                    stroke-width="2"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                </svg>
              </div>
              <div style={{ paddingTop: "2px", paddingBottom: "4px" }}>
                <Typography variant="small" color="neutral.b800">
                  Back to Dashboard
                </Typography>
              </div>
            </div>
          </div>
        )}
        <div className="bars" onClick={handleOpenMenu}>
          <img src="/bars.svg" />
        </div>
      </div>
    </>
  );
}