import React, { useState } from "react";
import Typography from "@mui/material/Typography";
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import { TextInput, Select, SelectItem, Checkbox, Button } from "@carbon/react";
import Card from "./card";
import apiL from "../../api/apiList";
import { useNavigate } from "react-router-dom";
export default function QuestionOnboard({ setcount, profileData }) {
  const [error, seterror] = useState("");
  let i = -1;
  const [data, setdata] = useState({
    ques_one: ["What is your biggest challenge?"],
    ques_two: ["What is your market differentiator?"],
    ques_three: ["What is your market reach?"],
    ques_four: ["How do you interact with your customer/client?"],
    ques_five: ["Who are your customers?"],
    ques_six: ["What is your primary offering?"],
    ques_seven: ["Are you meeting your Business Financial Goal?"],
    ques_eight: ["What keeps you up at night?"],
    ques_nine: ["What is the demand for your product or services?"],
    ques_ten: ["What are your expectations from Panorama?"],
  });
  const navigate = useNavigate();
  let serial = [
    "_one",
    "_two",
    "_three",
    "_four",
    "_five",
    "_six",
    "_seven",
    "_eight",
    "_nine",
    "_ten",
  ];
  let qa = [
    {
      question: "What is your biggest challenge?",
      a: "Sales & Marketing",
      b: "Cashflow",
      c: "Employee Related",
      d: "Technology",
      desc: "Please select what is holding your business back from growing.",
    },
    {
      question: "What is your market differentiator?",
      a: "Better Quality",
      b: "Better Price",
      c: "Innovative Solution",
      d: "Unique Brand Experience",
      desc: "Please select the reason customers buy your product/services.",
    },
    {
      question: "What is your market reach?",
      a: "Local",
      b: "Regional",
      c: "National",
      d: "Global",
      desc: "Please select the geographic range of your customers",
    },
    {
      question: "How do you interact with your customer/client?",
      a: "Physical/Brick & Mortar",
      b: "Online/Ecommerce",
      c: "Both Physical & Online",
      d: "Other",
      desc: "Please select how customers interact with you.",
    },
    {
      question: "Who are your customers?",
      a: "B2B (Business To Business) ",
      b: "B2C (Business To Customer) ",
      c: "B2B & B2C",
      d: "B2G (Business To Government/NGO)",
      desc: "Please select the majority of your customer base.",
    },
    {
      question: "What is your primary offering?",
      a: "Product",
      b: "Services",
      c: "Product & Services",
      d: "Other",
      desc: "Please select what your business offers.",
    },
    {
      question: "Are you meeting your business’ financial goals?",
      a: "Net Loss",
      b: "Breakeven",
      c: "Profit upto 20%",
      d: "Profit over 20%",
      desc: "Please select how well you are performing financially.",
    },
    {
      question: "What keeps you up at night?",
      a: "Competitors",
      b: "Operations",
      c: "Financials",
      d: "Customers",
      desc: "Please select what you are worried about.",
    },
    {
      question: "What is the demand for your products or services?",
      a: "Growing",
      b: "Stagnant",
      c: "Declining",
      d: "Not Sure",
      desc: "Please select how your products or services are performing.",
    },
    {
      question: "What are your expectations from Panorama?",
      a: "Increased Revenue & Growth",
      b: "Better Understanding of the Business",
      c: "Better Execution Plan",
      d: "Better Cost Management",
      desc: "Please select how Panorama can assist you.",
    },
  ];

  async function handleSave() {
    let str = false;
    for (let index = 1; index < serial.length; index++) {
      const element = `ques${serial[index]}`;
   
      if (data[element].length !== 2) {
        seterror("Please answer all the questions.");
        str = true;
      } else {
        str = false;
        seterror("");
      }
    }
    if (str === false) {
      var myHeaders = new Headers();
      myHeaders.append("Content-Type", "application/json");
      var raw = JSON.stringify(data);
      var requestOptions = {
        method: "POST",
        credentials: "include",
        headers: {
          "Content-Type": "application/json",
        },
        body: raw,
        redirect: "follow",
      };
      let id = localStorage.getItem("id");
      fetch(apiL.onBoarding + id, requestOptions).then(async (response) => {
        let dsa = await response.json();
        if (
          dsa?.result?.success === "Questions and Answers Saved Successfully"
        ) {
          localStorage.setItem("onboard", true);
          navigate("/portal/index");
        }
      });
    }
  }
  return (
    <Container maxWidth="xl">
      <Grid className="responsive-form" container spacing={2}>
        <Grid item xs={12} sm={12} md={12} lg={6} xl={6}>
          <div className="onbUserProfileBtn">
            <div>
              <Typography variant="h4B" color="neutral.b800">
                Your business overview
              </Typography>
            </div>
            {/* <div>
            <Typography variant="small" color="neutral.b200">
              We mention the privacy policy and terms we adhere to here so the
              business owner feels safe to share their information with us,
            </Typography>
          </div> */}

            <div>
              <Typography variant="small" color="error.main">
                {error}
              </Typography>
            </div>
            <div className="laptopphoto">
              <img  src="/Change.webp" />
            </div>
          </div>
        </Grid>
        <Grid item xs={12} sm={12} md={12} lg={6} xl={6}>
          <div className="onBScroll">
            <div className="onbar">
              {qa.map((e) => {
                return (
                  <Card
                    q={e.question}
                    setdata={setdata}
                    a={e.a}
                    b={e.b}
                    c={e.c}
                    d={e.d}
                    desc={e.desc}
                    ky={"ques" + serial[i + 1]}
                    key={serial[i++]}
                    data={data}
                  />
                );
              })}
            </div>
          </div>
          <div style={{ display: "flex" }}>
            <div className="onbBtn">
              <Button kind="primary" size="lg" onClick={() => handleSave()}>
                Save and Submit
              </Button>
            </div>
            <div style={{ paddingTop: "28px", paddingLeft: "8px" }}>
              <p
                style={{
                  color: "#2461FF",
                  fontSize: "14px",
                  cursor: "pointer",
                }}
                onClick={() =>
                  window.open("https://forms.gle/HDzxxsNVhr7KrJZN8")
                }
              >
                Having a problem? Report an issue
              </p>
            </div>
          </div>
        </Grid>
      </Grid>
    </Container>
  );
}
