import React from "react";
import { Button } from "@carbon/react";
import Typography from "@mui/material/Typography";
import { useNavigate } from "react-router-dom";
export default function AssCardMobile({
  selected,
  setselected,
  data,
  handleNext,
  error,
}) {
  const navigate = useNavigate();
  const rdata = {
    Vision:
      "Your vision serves as the compass, guiding your business toward its long-term destination.",
    Mission:
      " Your mission articulates the purpose and key objectives that drive your business’s existence.",
    Values:
      "Your communicated values define the ethical principles shaping your company's culture and decision-making.",
    People:
      "Prioritizing people fosters team cohesion in open communication and motivated workforce, aligning them with the organization's goals.",
    Customer:
      "Customer commitment entails understanding and fulfilling their needs, nurturing satisfaction and loyalty.",
    Marketing:
      "Strategic marketing effectively communicates and promotes your business's value proposition to the target audience.",
    "Operations/Productions":
      "Effective operations and production management ensures the delivery of high-quality outputs, meeting customer demand efficiently.",
    "Reporting and Feedback":
      " Actively collecting and analyzing performance data facilitates continuous improvement within the organization.",
    "IT and Financial Control":
      "Attentiveness to IT and financial control safeguards the organization's digital infrastructure and maintains financial health.",
    Strategy:
      "Strategic planning charts are comprehensive path to achieve business objectives and maintain competitiveness in the market.",
  };
  //
  return (
    <div className="assesmentCard" style={{ width: "100%", zIndex: "1" }}>
      <div className="assesmentCardimg" style={{ width: "100%" }}>
        <img
          src="/assesmentCard.png"
          style={{ width: "100%", height: "280px" }}
        />
        <div className="asscardText" style={{ width: "330px" }}>
          {/* <div style={{ marginTop: "15px" }}>
            <Typography variant="small" color="neutral.b50">
              People
            </Typography>
          </div> */}
          <div>
            <Typography variant="h4B" color="neutral.b0">
              {data[parseInt(selected) - 1]?.name || "Loading"}
            </Typography>
          </div>
          <div>
           
              <Typography
                variant="small"
                color="neutral.b0"
                style={{ marginTop: "15px", display: "block" }}
              >
                {rdata[data[parseInt(selected) - 1]?.name] || "Loading"}
              </Typography>
          
              {/* <Typography
                variant="smallB"
                color="error.main"
                style={{ marginTop: "15px", display: "block",color:"white" }}
              >
                {error}
              </Typography>
            )} */}
          </div>
        </div>
      </div>
    </div>
  );
}
