import "./App.scss";
import { createContext, useEffect, useState, useMemo } from "react";
import SignUp from "./Pages/Auth/SignUp";
import { Routes, Route, useLocation } from "react-router-dom";
import OnBoardMain from "./Pages/OnBoard/index";
import PortalIndex from "./Pages/Dashboard";
import User_info from "./Pages/Dashboard/userInfo";
import Layout from "./Components/Portal/layout";
import PortalHeader from "./Components/Portal/header";
import Plans from "./Pages/Dashboard/plans";
import Consultation from "./Pages/Dashboard/consultation";
import Users from "./Pages/Dashboard/users";
import SignIn from "./Pages/Auth/SignIn";
import Assesment from "./Pages/Assesment/Assesment";
import { UserContext } from "./userContext";
import Report from "./Components/Portal/dashboard/Report";
import AssesmentForMobile from "./Pages/Assesment/AssesmentForMobile";
import Cashflow from "./Components/Portal/dashboard/ReportsAll/cashflow";
import Customer from "./Components/Portal/dashboard/ReportsAll/customer";
import Financials from "./Components/Portal/dashboard/ReportsAll/financials";
import Marketing from "./Components/Portal/dashboard/ReportsAll/marketing";
import Operation from "./Components/Portal/dashboard/ReportsAll/operation";
import People from "./Components/Portal/dashboard/ReportsAll/poeple";
import Purpose from "./Components/Portal/dashboard/ReportsAll/purpose";
import Reports from "./Components/Portal/dashboard/ReportsAll/reports";
import { SocketProvider } from './SocketContext';
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Tst from "./Pages/test";
import CompareMain from "./Pages/Dashboard/compareBlocks/CompareMain";
import CompareMainTest from "./Pages/ctest";
import CompareMaintestR from "./Pages/rtest";
import ReportsTab from "./Components/Portal/UserInfo/ReportsTab";
import Reset_Password from "./Pages/Auth/Reset_Password";
import FeedbackPage from "./Components/Portal/feedback";
import CompReport from "./Pages/Dashboard/compareBlocks/repComparison";
import Success from "./Pages/Success";
import Failed from "./Pages/Failed";
import Cancelled from "./Pages/Cancelled";
import ChatbotMenu from "./Pages/Dashboard/chatbot"
import {
  checkAccess,
  checkPlan,
  checkValidity,
} from "./Components/planFunctions";
import Expired from "./Pages/expired";
import ViewChat from "./Pages/Dashboard/viewChat";
import ConditionalRoutes from "./ClosedRoutes";
import ScrollToTop from "./Pages/Dashboard/scrolltoTop";
function App() {
  const location = useLocation();
  const [user, setUser] = useState({
    id: localStorage.getItem("id"),
    uid: localStorage.getItem("uid"),
    pid: localStorage.getItem("pid"),
  });
  useEffect(() => {
    const handleRouteChange = () => {
      if (location.pathname !== '/portal/report') {
        localStorage.removeItem('isBangla');
      }
    };

    handleRouteChange();
  }, [location.pathname]);
  const value = useMemo(() => ({ user, setUser }), [user, setUser]);

  const notify = (e) => toast(e);
  const [socket, setsocket] = useState(null)
  return (
    <UserContext.Provider value={value}>
 
      <div className="App">
        {/* Authentication Routes */}
        <>
          <ToastContainer
            position="bottom-right"
            autoClose={5000}
            hideProgressBar={false}
            newestOnTop={false}
            closeOnClick
            rtl={false}
            pauseOnFocusLoss
            draggable
            pauseOnHover
            theme="light"
          />
          <ScrollToTop/>
          <Routes>
            <Route path="/reset_password" element={<Reset_Password />} />
            <Route path="/signup" element={<SignUp />} />
            <Route path="/signin" element={<SignIn />} />
            <Route path="/test" element={<Tst />} />
            <Route path="/ctest" element={<CompareMainTest />} />
            <Route path="/testEmailDesign" element={<CompareMaintestR />} />
            <Route path="/" element={<SignIn />} />
          </Routes>
        </>

        {/* Dashboard Routes */}
        <>
          <ConditionalRoutes>
            <Routes>
            <Route path="/onBoard" element={<OnBoardMain />} />
              <Route
                path="/portal/chatbot/"
                element={
                  <ChatbotMenu
                    checkAccess={checkAccess}
                    checkPlan={checkPlan}
                    socket={socket}
                    setSocket={setsocket}
                  />
                }
              />
              <Route
                path="/portal/chatbot/:id"
                element={
                  <ViewChat
                    checkAccess={checkAccess}
                    checkPlan={checkPlan}
                    socket={socket}
                  />
                }
              />
              <Route
                path="/assesment"
                element={
                  <Assesment checkAccess={checkAccess} checkPlan={checkPlan} />
                }
              />
              <Route
                path="/assesmentformobile"
                element={
                  <AssesmentForMobile
                    checkAccess={checkAccess}
                    checkPlan={checkPlan}
                  />
                }
              />
              <Route
                path="/portal/index"
                element={
                  <PortalIndex
                    checkAccess={checkAccess}
                    checkPlan={checkPlan}
                  />
                }
              />
              <Route
                path="/portal/userInfo"
                element={
                  <User_info
                    notify={notify}
                    checkAccess={checkAccess}
                    checkPlan={checkPlan}
                  />
                }
              />
              <Route
                path="/portal/plans"
                element={
                  <Plans checkAccess={checkAccess} checkPlan={checkPlan} />
                }
              />
              {/* <Route path="/portal/compareReports/" element={<CompReport/>} /> */}
              <Route
                path="/portal/compare/"
                element={
                  <CompareMain
                    checkAccess={checkAccess}
                    checkPlan={checkPlan}
                  />
                }
              />

              <Route
                path="/portal/assessmentreports"
                element={
                  <ReportsTab checkAccess={checkAccess} checkPlan={checkPlan} />
                }
              />
              <Route
                path="/portal/feedback"
                element={
                  <FeedbackPage
                    checkAccess={checkAccess}
                    checkPlan={checkPlan}
                  />
                }
              />
              <Route
                path="/portal/consultation"
                element={
                  <Consultation
                    checkAccess={checkAccess}
                    checkPlan={checkPlan}
                  />
                }
              />
              <Route
                path="/portal/users"
                element={
                  <Users checkAccess={checkAccess} checkPlan={checkPlan} />
                }
              />
              <Route
                path="/portal/success"
                element={
                  <Success checkAccess={checkAccess} checkPlan={checkPlan} />
                }
              />
              <Route
                path="/portal/failed"
                element={
                  <Failed checkAccess={checkAccess} checkPlan={checkPlan} />
                }
              />
              <Route
                path="/portal/cancelled"
                element={
                  <Cancelled checkAccess={checkAccess} checkPlan={checkPlan} />
                }
              />
              {/* <Route path="/portal/compare/" element={<CompareMain />} /> */}
              <Route
                path="/portal/report/overview"
                element={
                  <Report checkAccess={checkAccess} checkPlan={checkPlan} />
                }
              />
              <Route
                path="/portal/report/cashflow"
                element={
                  <Cashflow checkAccess={checkAccess} checkPlan={checkPlan} />
                }
              />
              <Route
                path="/portal/report/customer"
                element={
                  <Customer checkAccess={checkAccess} checkPlan={checkPlan} />
                }
              />
              <Route
                path="/portal/report/financials"
                element={
                  <Financials checkAccess={checkAccess} checkPlan={checkPlan} />
                }
              />
              <Route
                path="/portal/report/marketing"
                element={
                  <Marketing checkAccess={checkAccess} checkPlan={checkPlan} />
                }
              />
              <Route
                path="/portal/report/operation"
                element={
                  <Operation checkAccess={checkAccess} checkPlan={checkPlan} />
                }
              />
              <Route
                path="/portal/report/people"
                element={
                  <People checkAccess={checkAccess} checkPlan={checkPlan} />
                }
              />
              <Route
                path="/portal/report/purpose"
                element={
                  <Purpose checkAccess={checkAccess} checkPlan={checkPlan} />
                }
              />
              <Route
                path="/portal/report/reports"
                element={
                  <Reports checkAccess={checkAccess} checkPlan={checkPlan} />
                }
              />
            </Routes>
          </ConditionalRoutes>
        </>
        {/* Dashboard Routes End Here */}
        {/* Assesment Routes  */}

        {/* Assesment Routes End Here */}
      </div>
    </UserContext.Provider>
  );
}

export default App;
