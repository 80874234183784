import React, { useState } from 'react'
import Typography from "@mui/material/Typography";
import * as XLSX from 'xlsx';
import {
  Tabs,
  TabList,
  Tab,
  TabPanels,
  TabPanel,
  TextInput,
  Select,
  SelectItem,
  Checkbox,
  Button,
  Tag,
  Table,
  TableHead,
  TableRow,
  TableHeader,
  TableBody,
  TableCell,
} from "@carbon/react";
export default function TableCompare({qdata}) {
    let headers= ["No.","Questions","Block",...qdata.TableIndex]
    const sortAssessments = (data) => {
      return data.sort((a, b) => {
        if (a.Block < b.Block) {
          return -1;
        }
        if (a.Block > b.Block) {
          return 1;
        }
        return 0;
      });
    };
    const filterBonusQ = (data) => {
      return data.filter((item) => item.Block !== 'BONUS Q');
    };
    const indexs=qdata.TableIndex
    const formattedData = qdata.Assessment.map(item => {
      const newItem = {
          Question: item.Question,
          Block: item.Block,
          Subblock: item.Subblock
      };
  
      item.Ans.forEach((answer, idx) => {
          newItem[indexs[idx]] = answer.toUpperCase();
      });
  
      return newItem;
  });
  const [hover, setHover] = useState(false);
    const filteredData = filterBonusQ(qdata.Assessment);
    let rows= sortAssessments(filteredData)
    let i =1
    const downloadExcel = (data) => {
      const worksheet = XLSX.utils.json_to_sheet(data);
      const workbook = XLSX.utils.book_new();
      XLSX.utils.book_append_sheet(workbook, worksheet, "Sheet1");
      XLSX.writeFile(workbook, "DataSheet.xlsx");
    };
  return (
    <div className="vbnm">
        <button
      style={{
        cursor: 'pointer',
        backgroundColor: hover ? '#E0E0E0' : '#F5F5F5', 
        color: 'black',
        border: '',
        fontSize: '14px',
      }}
      onMouseEnter={() => setHover(true)} 
      onMouseLeave={() => setHover(false)} 
      onClick={() => downloadExcel(formattedData)}
    >
      Download As Excel
    </button>
            <Table size="lg" useZebraStyles={false} >
      <TableHead>
        <TableRow>
          {headers.map((header) => (
            <TableHeader id={header} key={header}>
              {header}
            </TableHeader>
          ))}
        </TableRow>
      </TableHead>
      <TableBody>
        {rows.length ==0? <div style={{paddingTop:'25px',paddingLeft:'12px'}}><span style={{fontSize:'20px'}}></span></div>: rows.map((row) => (
          <TableRow key={row.Question}>
            <TableCell key={i++} style={{width:'8px'}}>{i}</TableCell>
            <TableCell key={row.Question} style={{height:'70px',width:"420px"}}>{row.Question}</TableCell>
            <TableCell key={row.Block} style={{height:'70px',width:"220px"}}>{row.Block}</TableCell>
            {row.Ans.map((e,s)=>{
             return  e=="yes"? <TableCell style={{backgroundColor:'#68CD69',color:'#fff',textAlign:"Center",borderLeft:'1px solid Black',borderRight:'1px solid Black'}} key={i+e+s}>{e.toUpperCase()}</TableCell>:e=="no"?<TableCell style={{backgroundColor:'#CB5D51',color:'#fff',textAlign:"Center",borderLeft:'1px solid Black',borderRight:'1px solid Black'}} key={i+e+s}>{e.toUpperCase()}</TableCell>:<TableCell style={{backgroundColor:'#EFB757',color:'#fff',textAlign:"Center",borderLeft:'1px solid Black',borderRight:'1px solid Black'}}  key={i+e+s}>{e.toUpperCase()}</TableCell>
            })}
          </TableRow>
        ))}
      </TableBody>
    </Table>
    </div>
  )
}
